const initialState = {
  enrollmentSidebarOpen: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_DISPLAY_FIELD':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
