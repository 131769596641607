import React from 'react';
import { useSelector } from 'react-redux';

function EnrollmentErrors ({
}) {
  const { errors } = useSelector(store => store.enrollment);

  return errors.length ? (
    <div className="d-flex row alert alert-warning align-items-center justify-content-center">
      {errors.map((err) => (
        <p key={err} className="d-flex col-12 fw-bold my-1">{err}</p>
      ))}
    </div>
  ) : null;
}

export default EnrollmentErrors;
