import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { YesNo } from '../../components/inputs';

import '../../styles.scss';

function Plan ({ pageTitle }) {
  const rowClasses = 'input-row';

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <FormRow>
          <YesNo
            label="Do you understand that this is a Medicare Advantage plan that has a contract with the Federal Government and IS NOT a Medicare Supplement plan?"
            name="planconftype"
          />
        </FormRow>
      </div>
    </div>
  );  
}

export default Plan;
