import React from 'react';
import { useSelector } from 'react-redux';

import '../../styles.scss';
import { fieldExistsInConfig } from '../../../../utils/enroll';

function FormRow ({
  children,
  className,
  id,
  style,
  hide,
  showIfFieldsExist = [],
  showIfFieldExists = '',
}) {
  const { form } = useSelector(store => store.enrollment);

  let show = !hide;
  if (showIfFieldsExist.length) {
    show = false;
    showIfFieldsExist.forEach(field => {
      if (fieldExistsInConfig(form, field)) show = true;
    });
  } else if (showIfFieldExists) {
    show = fieldExistsInConfig(form, showIfFieldExists);
  }

  return show ? (
    <div
      className={['input-row', className].join(' ')}
      id={id}
      style={style}
    >
      {children}
    </div>
  ) : null;
}

export default FormRow;
