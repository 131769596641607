import React, { useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HTMLReactParser from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// Components
import {
  Topbar,
  DisclaimerFooter,
  EnrollmentLoaderButton,
  LoadingOverlay,
  PlanCoverage,
}  from '../../../components';
import { LoaderButton } from '../../../common/components';
import BenefitDropdown from './BenefitDropdown';

// Utils
import { formatBenefits, formatDocuments } from '../../../utils/plan-utils/';
import { toCurrency } from '../../../common/utils/prettify';
import { getParam } from '../../../utils/qs';

// Design ticket: https://mymedicarebot.atlassian.net/browse/MYR-4767
import {getPlanCarrierDisplay} from "../../../utils/general";
import { getPlanByCPS } from '../../../actions/plan';
function PlanDetails() {
  const navigate = useNavigate();

  const { logo } = useSelector(store => store.theme);
  const { selectedPlanQuote } = useSelector(store => store.plan);
  const plan = useSelector(store => store.plan.snapshotPlans[selectedPlanQuote.quoteID]);
  const [coverage, setCoverage] = useState([]);
  // Remove these two after the link load-in is confirmed old/not in use
  const [benefits, setBenefits] = useState({});
  const [documents, setDocuments] = useState({});
  const displayName = getPlanCarrierDisplay({ plan });

  const [ errs, setErrs ] = useState();
  const [ loading, setLoading ] = useState();

  const getPlans = async () => {
    try {
      setLoading(true);
      // question: @santeyio - so this never occurs now?
      const cpsID = getParam('cps');
      const planYear = getParam('year')
      if (cpsID && planYear) {
        const p = await getPlanByCPS(cpsID, planYear);
        setBenefits(formatBenefits({ plan: p }));
        setDocuments(formatDocuments({ plan: p }));
      }
      setLoading(false);
    } catch (err) {
      const errMsg = (<span className="text-danger d-flex justify-content-center">There was a problem loading your plan.</span>);
      setErrs(errMsg);
      setLoading(false);
    }
  }

  useLayoutEffect(() => {
    setBenefits(formatBenefits({ plan }));
    setDocuments(formatDocuments({ plan }));
  }, []);
  
  // Render specifics
  const planTypes = {
    'PDP': 'Prescription Drug Plan',
    'MAPD': 'Medicare Advantage',
    'MA': 'Medicare Advantage',
  }

  useLayoutEffect(() => {
    if (!plan || !plan.plan) return;
    const benefits = [
      { label: 'Rx Coverage', value: plan.plan.HasRXCoverage },
      { label: 'Mail Order Pharmacy', value: plan.plan.ContainMailOrder },
    ];
    if (plan.plan.PlanYear === '2023') {
      benefits.push({ label: 'Insulin Savings Program', value: plan.plan.InsulinSavingsProgram });
    }
    const eBenefits = (plan.plan.ExtraBenefits && plan.planType !== 'PDP') ? plan.plan.ExtraBenefits.map(b => {
      return {
        label: b.BenefitName,
        value: b.BenefitValue === 'Yes',
      };
    }) : [];
    setCoverage([ ...benefits, ...eBenefits ]);
  }, [plan]);

  return (
    <>
      {loading && <LoadingOverlay />}
      <Topbar showAgent />
      <div className='mmb-container show-agent bg-gray-200'>
        <div className='bg-white w-100 d-flex align-items-center flex-column shadow-sm p-4'>
            {/* navigate back to quotes/plans */}
            <div className="d-flex flex-column w-100 justify-content-center align-items-center">
              <div
                className="d-flex justify-content-center text-link"
              >
                <span
                  className="h-link p-2 rounded"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate('/quotes');
                  }}
                >
                  <FontAwesomeIcon icon={solid('arrow-left')} className="me-2" />
                  <span className="fw-bold text-decoration-underline">Back to quotes</span>
                </span>
              </div>
              <img
                src={logo}
                style={{ height: '48px' }}
              />
              <div className="mt-2">{displayName}</div>
            </div>
            <div className="fs-4 fw-bold text-black text-decoration-underline text-center">
              {plan?.planName || '--'}
            </div>
            <div className="mt-1">{plan?.cpsID}</div>
            <div className="mt-1 text-primary fw-bold">
              Plan type: {planTypes[plan?.planType] || '--'}
            </div>
          <div className="container w-100" style={{ maxWidth: '500px' }}>
            <div
              className="justify-content-center row mt-3"
              style={{ whiteSpace: 'nowrap' }}
            >
              {!errs ? <EnrollmentLoaderButton plan={plan} quote={selectedPlanQuote} /> : null}
            </div>
          </div>
        </div>
        {/* Content below white header */}
        {/* Plan benefits/details */}
        <div className={`card bg-white shadow-sm mb-5 mt-4 p-5 fw-bold ${errs ? 'd-flex' : 'd-none'}`}>
          {errs}
        </div>
        <div className={`p-4 ${errs || loading ? 'd-none' : ''}`}>
          This plan may have additional benefits in {plan?.plan?.PlanYear} that are not currently displayed.
        </div>
        <div className={`d-flex card flex-column shadow-sm mb-5 ${errs || loading ? 'd-none' : ''} bg-none`}>
          <div className="d-flex flex-column align-items-left p-4">
            <div className="mb-2 d-flex">
              <span className="w-25">Monthly premium:</span>
              <span className="w-75 fw-bold ms-2">{toCurrency(plan?.monthlyPremium?.toString()) || '--'}</span>
            </div>
            <div className="mb-2 d-flex">
              <span className="w-25">Annual deductible:</span>
              <span className="w-75 d-flex flex-column fw-bold ms-2">
                {benefits && benefits['Deductibles']
                  ? (<div>
                      {HTMLReactParser(benefits['Deductibles'].text)}
                     </div>)
                  : <>Prescription Drug Deductible: {toCurrency(plan?.deductible?.toString())}</>
                }
              </span>
            </div>
            {benefits && benefits['Maximum Out-of-Pocket']
              ? (
                <div className="mb-2 d-flex">
                  <span className="w-25">Out-of-pocket maximum:</span>
                  <span className="w-75 d-flex flex-column fw-bold ms-2">
                    {(HTMLReactParser(benefits['Maximum Out-of-Pocket'].text))}
                  </span>
                </div>
              ) : null}
            {coverage.length
               ? (
                 <div className="d-flex">
                  <span className="w-25">Coverage:</span>
                   <div className="d-flex w-75 flex-wrap">
                     <PlanCoverage coverage={coverage} />
                   </div>
                 </div>
               ) : null
            }
          </div>
          {Object.keys(benefits).map((benefitCategory) => {            
            return (
              <BenefitDropdown
                label={benefitCategory}
                key={benefitCategory}
                benefits={benefits[benefitCategory]}
              />
            );
            })}
          <BenefitDropdown
            label="Plan Documents"
            benefits={documents}
          />
        </div>
        <div className="pt-3 pb-5 mb-0 mt-auto">
          <DisclaimerFooter />
        </div>
      </div>
    </>
  );
}

export default PlanDetails;
