import * as moment from 'moment';
import {
  yesNo,
  yesNoBlank,
  existsOrBlank,
} from './mapping-tools';
import { stripPhone, cleanDate } from './formatting';
import getAgentID from './getAgentID';

export default ({
  agent,
  plan,
  quote,
  fields: res,
  theme,
  confirmationNumber,
}) => {
  const { useCarrierPerms } = theme;

  let agentID;
  let agentNPN;
  if (useCarrierPerms) {
    const ids = getAgentID({ agent, quote });
    ({ agentID, agentNPN } = ids);
  }

  const premiumDeducted = yesNo(['rrb', 'ssi'].includes(res.paymeth));
  const premiumDirectPay = yesNo(!['rrb', 'ssi'].includes(res.paymeth)); // if paymeth not specified this is set to true
  const premiumWithhold = ['rrb', 'ssi'].includes(res.paymeth) ? res.paymeth.toUpperCase() : '';
  const submitTime = moment.utc().format('MM/DD/YYYY HH:mm:ss'); // spec says submit time should be UTC
  const planYear = plan.PlanYear;

  const isSEP = res.enrolltype === 'SEP';
  // format sep date as mmddyyyy
  const sepDate = res.sep_date ? `${res.sep_date.slice(5, 7)}${res.sep_date.slice(8, 10)}${res.sep_date.slice(0, 4)}` : '';
  const sepConcat = `${res.sep_code} ${sepDate}`.trim();

  const gender = (res.gender === 'male') ? 'M' : 'F';

  // according to spec should be 'NPI "+" PROVIDER_LOCATION_ID'
  // note that in the form 'primarycare_id' has been renamed with the label
  // 'Zing Health Provider Location ID' and 'pcp_num' has been re-labeled
  // 'Provider NPI'
  const pcpInfo = (res.pcp_num || res.primarycare_id) ? `${res.pcp_num}+${res.primarycare_id}` : '';

  // CSNP
  const csnpInfoArray = [];
  if (res.snp_chf) csnpInfoArray.push('chf');
  if (res.snp_cvd) csnpInfoArray.push('cvd');
  if (res.snp_diabetes) csnpInfoArray.push('diabetes');
  if (res.snp_lung) csnpInfoArray.push('cld');
  // according to spec, should be a comma separated list of csnp verification conditions
  const csnpInfo = csnpInfoArray.join(',');

  let race = '';
  let ethnicity = '';

  if (Array.isArray(res.race) && res.race.length) {
    race = res.race.join(',');
  }
  if (Array.isArray(res.ethnicity) && res.ethnicity.length) {
    ethnicity = res.ethnicity.join(',');
  }

  const formattedData = [
    { col: 'ConfirmationNumber', val: confirmationNumber },
    { col: 'SubmitDate', val: moment().format('MMDDYYYY') },
    { col: 'ContractID', val: plan.ExternalContractID },
    { col: 'PlanID', val: plan.ExternalPlanID },
    { col: 'SegmentID', val: plan.ExternalSegmentID },
    { col: 'ApplicantTitle', val: '' },
    { col: 'ApplicantFirstName', val: res.first_name },
    { col: 'ApplicantMiddleInitial', val: res.middle_name },
    { col: 'ApplicantLastName', val: res.last_name },
    { col: 'ApplicantBirthDate', val: moment(res.birthdate).format('MMDDYYYY') },
    { col: 'ApplicantGender', val: gender },
    { col: 'ApplicantAddress1', val: res.street1 },
    { col: 'ApplicantAddress2', val: res.street2 },
    { col: 'ApplicantAddress3', val: '' },
    { col: 'ApplicantCity', val: res.city },
    { col: 'ApplicantCounty', val: res.county_name },
    { col: 'ApplicantState', val: res.state.toUpperCase() },
    { col: 'ApplicantZip', val: res.zipcode },
    { col: 'ApplicantPhone', val: stripPhone(res.home_phone) },
    { col: 'ApplicantEmailAddress', val: res.email },
    { col: 'ApplicantMBI', val: res.medicare_id },
    { col: 'ApplicantSSN', val: '' }, // we do not collect ssn
    { col: 'MailingAddress1', val: res.samemail ? res.street1 : res.mailstreet1 },
    { col: 'MailingAddress2', val: res.samemail ? res.street2 : res.mailstreet2 },
    { col: 'MailingAddress3', val: '' },
    { col: 'MailingCity', val: res.samemail ? res.city : res.mailcity },
    { col: 'MailingState', val: res.samemail ? res.state : res.mailstate },
    { col: 'MailingZip', val: res.samemail ? res.zipcode : res.mailzip },
    { col: 'MedicarePartA', val: cleanDate(res.part_a_effective_date) },
    { col: 'MedicarePartB', val: cleanDate(res.part_b_effective_date) },
    { col: 'EmergencyContact', val: '' },
    { col: 'EmergencyPhone', val: '' },
    { col: 'EmergencyRelationship', val: '' },
    { col: 'PremiumDeducted', val: premiumDeducted },
    { col: 'PremiumSource', val: '' },
    { col: 'OtherCoverage', val: yesNoBlank(res.othercoverage, '') }, // applies to PDP, MAPD, SNP DE, PFFS-PD, CP-PD
    { col: 'OtherCoverageName', val: res.othercoverage ? res.othercoverage_name : '' },
    { col: 'OtherCoverageID', val: res.othercoverage ? res.othercoverage_num : '' },
    { col: 'LongTerm', val: '' }, // not used by zing
    { col: 'LongTermName', val: '' },
    { col: 'LongTermAddress', val: '' },
    { col: 'LongTermPhone', val: '' },
    { col: 'AuthorizedRepName', val: res.ispoa ? `${res.poa_fname} ${res.poa_lname}` : '' },
    { col: 'AuthorizedRepAddress', val: res.ispoa ? `${res.poa_addr_street1} ${res.poa_addr_street2 || ''}`.trim() : '' },
    { col: 'AuthorizedRepCity', val: res.ispoa ? res.poa_addr_city : '' },
    { col: 'AuthorizedRepState', val: res.ispoa ? res.poa_addr_state : '' },
    { col: 'AuthorizedRepZip', val: res.ispoa ? res.poa_addr_zip : '' },
    { col: 'AuthorizedRepPhone', val: res.ispoa ? res.poa_tel : '' },
    { col: 'AuthorizedRepRelationship', val: res.ispoa ? res.poa_rel : '' },
    { col: 'Language', val: existsOrBlank(res.preflang, '') },
    { col: 'ESRD', val: '' }, // Per CMS as of 12/31/2020 ESRD no longer applicable (End-Stage Renal Disease)
    { col: 'StateMedicaid', val: '' },
    { col: 'WorkStatus', val: yesNoBlank(res.willwork, '') },
    { col: 'PrimaryCarePhysician', val: existsOrBlank(res.pcp_name, '') },
    { col: 'OtherCoverageGroup', val: existsOrBlank(res.othercoverage_group, '') },
    { col: 'AgentID', val: agentID },
    { col: 'SubmitTime', val: submitTime },
    { col: 'PartDSubAppInd', val: existsOrBlank(csnpInfo, '') },
    { col: 'DeemedInd', val: '' },
    { col: 'SubsidyPercentage', val: '' },
    { col: 'DeemedReasonCode', val: '' },
    { col: 'LISCopayLevelID', val: '' },
    { col: 'DeemedCopayLevelID', val: '' },
    { col: 'PartDOptOutSwitch', val: '' },
    // formatted as `SEPCODE mmddyyyy` or `SEPCODE`
    { col: 'SEPReasonCode', val: isSEP ? sepConcat : '' },
    // Only used when indicating OTH as the SEP Reason Code in order to explain the reason the individual is eligible to enroll
    { col: 'SEPCMSReasonCode', val: '' },
    { col: 'PremiumDirectPay', val: premiumDirectPay },
    { col: 'EnrollmentPlanYear', val: planYear },
    { col: 'PremiumWithhold', val: premiumWithhold },
    { col: 'SpouseWorkStatus', val: yesNoBlank(res.willworkspouse, '') },
    { col: 'AccessibilityFormat', val: yesNoBlank(res.altwritten, '') },
    { col: 'EmailOptIn', val: yesNoBlank(res.okemail, '') },
    { col: 'Race', val: race },
    { col: 'Ethnicity', val: ethnicity },
    { col: 'PremiumDeductEFT', val: '' },
    { col: 'PremiumDeductCreditCard', val: '' },
    { col: 'PremiumDeductSS', val: '' },
    { col: 'PremiumDeductBill', val: '' },
    { col: 'AgentFirstName', val: agent.firstName },
    { col: 'AgentLastName', val: agent.lastName },
    { col: 'AgentNationalID', val: agentNPN },
    { col: 'AgentStateLicenseNumber', val: '' },
    { col: 'AgentIDHealthPlan', val: '' },
    { col: 'AgencyName', val: 'AmeriLife' }, // NOTE(santeyio): spec says this is required
    { col: 'AgencyID', val: '' },
    { col: 'SourceID', val: 'MyMedicareBot' }, // hard coded mmb id per MYR-5333
    { col: 'OEC_InsertedWhen', val: '' },
    { col: 'ProviderID', val: pcpInfo }, // according to spec it should be 'NPI "+" PROVIDER_LOCATION_ID
    { col: 'MedicalGroupID', val: '' },
    { col: 'Accountname', val: '' },
    { col: 'BankRoutingNum', val: '' },
    { col: 'BankAccountNum', val: '' },
    { col: 'AccountType', val: '' },
    { col: 'ApplicantEffectiveDate', val: '' },
    { col: 'CSNP_ProviderName', val: existsOrBlank(res.snp_physician_name, '') },
    { col: 'CSNP_ProviderPh#', val: existsOrBlank(res.snp_physician_tel, '') },
    { col: 'Materialsviaemail', val: yesNo(res.materialsbymail) },

    /* no eft for zing at this time (2023-05-23)
    { col: 'RoutingNumber', val: isEFT ? res.eft_routing : '' },
    { col: 'BankName', val: isEFT ? res.eft_bankname : '' },
    { col: 'AccountNumber', val: isEFT ? res.eft_actnum : '' },
    { col: 'Accounttype', val: isEFT ? eftActType : '' },
    { col: 'AccountholderName', val: isEFT ? res.eft_actname : '' },
    */
  ];

  return { formattedData, agentID };
};
