import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, light } from '@fortawesome/fontawesome-svg-core/import.macro';

function CheckButton(props) {
  const { value } = props;
  return (
    <span className="position-absolute">
      {/* outline of icon */}
      <FontAwesomeIcon icon={light('circle')} className="position-absolute text-secondary" style={{ left: '0', zIndex: '2' }} />
      {/* white background of icon */}
      <FontAwesomeIcon icon={solid('circle')} className="position-absolute text-white" style={{ left: '0' }} />
      {/* on/off blue icon */}
      <FontAwesomeIcon
        icon={solid('circle')}
        className="position-absolute text-primaryFocus"
        style={{
          transition: 'all var(--hoverTiming) var(--hoverAnimation)',
          fontSize: '10px',
          left: '3px',
          top: '3px',
          opacity: value === true ? '1' : '0',
        }}
        />
    </span>
  );
}

export default CheckButton;
