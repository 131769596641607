import React, { useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

// Components
import { EnrollmentLoaderButton, PlanCoverage, Spinner } from '../../components/';

// Utils
import { toCurrency } from '../../common/utils/prettify';
import { hasPricingData } from '../../utils/display';

// Design ticket: https://mymedicarebot.atlassian.net/browse/MYR-4422
import './QuoteCard.scss';
import {getPlanCarrierDisplay} from "../../utils/general";
function QuoteCard({ quote, snapshotPlan }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { shopper } = useSelector(store => store);
  const { planName, planType, planYear, carrier, extraBenefits, premium, cpsID } = quote;
  const displayName = getPlanCarrierDisplay({ quote });
  const { currentPlanCompare } = useSelector(store => store.theme);

  const [coverage, setCoverage] = useState([]);

  // useLayoutEffect means we populate our Coverage comp before first render,
  // so there's no flash of an empty list
  useLayoutEffect(() => {
    if (!snapshotPlan) return;
    const benefits = [
      { label: 'Rx Coverage', value: snapshotPlan.plan.HasRXCoverage },
      { label: 'Mail Order Pharmacy', value: snapshotPlan.plan.ContainMailOrder },
    ];
    if ((shopper.effectiveDate || '').includes('2023')) {
      benefits.push({ label: 'Insulin Savings Program', value: snapshotPlan.plan.InsulinSavingsProgram });
    }
    const eBenefits = (extraBenefits && planType !== 'PDP') ? extraBenefits.map(b => {
      return {
        label: b.BenefitName,
        value: b.BenefitValue === 'Yes',
      };
    }) : [];
    setCoverage([ ...benefits, ...eBenefits ]);
  }, [snapshotPlan]);

  return (
    <div className='qc-container d-flex align-items-center mb-5'>
      {/* Quote Card */}
      <div
        className="d-flex flex-column bg-white p-4 shadow-sm m-1"
        style={{
          maxWidth: 'min(85vw, 500px)',
          minWidth: 'min(85vw, 500px)',
        }}
      >
        <div className="d-flex">
          {/* Plan title (carrier, name) */}
          <span
            className={`d-flex flex-column p-2 w-100 ${coverage.length ? 'h-link' : ''}`}
            onClick={() => {
              /* Load of (snapshot)PlanDetails w/ a quoteID - EnrollmentLoaderButton also uses this quote */
              if (coverage.length) {
                dispatch({ type: 'SET_PLAN_FIELD', payload: { selectedPlanQuote: quote } });
                navigate('/plan');
              }
            }}
          >
            {displayName}
            <span className="fs-4 mt-0 mb-0 fw-bold text-blue text-decoration-underline">{planName}</span>
            <span className="fs-6 mt-0">{cpsID}</span>
            <span className="text-muted fs-6 mt-2">View details</span>
          </span>

          {
            true
              ? null
              : (
                <span className="ms-auto me-0 ps-3" style={{ cursor: 'wait' }}>
                  <FontAwesomeIcon
                    className="fs-3 plan-save-button"
                    icon={light('heart')}
                    />
                </span>
              )
          }
        </div>
        <span className="text-muted mt-3">Coverage includes:</span>
        <div className={`mt-1 w-100 d-flex flex-wrap`} style={{ whiteSpace: 'pre' }}>
          {coverage.length
             ? (
               <PlanCoverage coverage={coverage} />
             ) : (
               <div
                 className="w-100 d-flex justify-content-center"
                 style={{ minHeight: planType === 'PDP' ? '64px' : '96px' }}
               >
                 <Spinner className="mt-auto mb-auto" />
               </div>
             )
          }
        </div>
        {false && hasPricingData({ carrier, planYear }) && (
          <div className="d-flex mt-4">
            Your estimated out of pocket costs:
            <span className="ms-auto" style={{ marginRight: '25%' }}>
              {toCurrency(premium, { undefinedToZero: true })}
            </span>
          </div>
        )}
          
        <div className="d-flex mt-4">
          Monthly premium:
          <span className="ms-auto" style={{ marginRight: '12px' }}>
            {toCurrency(premium, { undefinedToZero: true })}
          </span>
        </div>
        <div className="d-flex mt-1 fw-bold fs-3">
          Total due:
          <span className="ms-auto" style={{ marginRight: '12px' }}>
            {toCurrency(premium, { undefinedToZero: true })}
          </span>
        </div>
      </div>
      {/* Content below/outside Quote Card */}
      <div className="d-flex flex-column mb-auto w-100 justify-content-center">
        <EnrollmentLoaderButton className="qc-btn" quote={quote} />
        {
          currentPlanCompare && shopper.currentPlan
            ? (
              <button
                className="btn btn-success qc-btn fw-bold mt-3"
                tabIndex={0}
                onClick={() => {
                  dispatch({ type: 'SET_PLAN_FIELD', payload: { selectedPlanQuote: quote } });
                  navigate('/compare');
                }}
              >
                Compare with current plan
              </button>
            ) : null
        }
      </div>
    </div>
  );
}

export default QuoteCard;
