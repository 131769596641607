import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// Components
import TopbarAgent from './TopbarAgent';

// * https://mymedicarebot.atlassian.net/browse/MYR-4722
import './Topbar.scss';
function Topbar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { shopper, agent } = useSelector(store => store);
  const { created, tokenLastUpdate } = useSelector(store => store.session);
  const { logo } = useSelector(store => store.theme);

  // Dropdown states & references
  const [ shopperPanel, toggleShopperPanel ] = useState(false);
  const [ spRef, setSpRef ] = useState();
  const [ navPanel, toggleNavPanel ] = useState(false);
  const [ npRef, setNpRef ] = useState();

  // Visual state
  const [ isAuth, setIsAuth ] = useState(false);
  const {
    showAgent,
    hideRight,
  } = props;

  // note(@joeysapp): Because the Topbar is a component itself in each view (not App)
  //                  this component mounts/dismount on every navigation.. meaning
  //                  this event listener is being bound/unbound all the time.
  //                  (but we need this to listen to clicks outside of the Topbar element..)
  //                  Think about a way to improve this, it's necessary but I don't like
  //                  the event listener being added / removed all the time
  const handleClick = (event) => {
    if (!shopperPanel && !navPanel) return;
    if (!spRef?.contains(event.target) && !npRef?.contains(event.target)) {
      toggleNavPanel(false);
      toggleShopperPanel(false);
    }
  }

  const handleLogout = (event) => {
    const token = localStorage.getItem('SessionToken');
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/token-auth/expire/`,
      headers: { Authorization: `Token ${token}` },
      data: { token },
    }).finally(() => {
      localStorage.removeItem('SessionToken');
      dispatch({ type: 'SET_TOKEN_LAST_UPDATE', payload: null });
      setIsAuth(false);

      // Navigate timeout so token can clear in time
      setTimeout(() => {
        // clear store
        dispatch({ type: 'CLEAR_SHOPPER' });
        dispatch({ type: 'CLEAR_AGENT' });
        dispatch({ type: 'CLEAR_PLANS' });
        navigate('/login');
      }, 0);
    });
  }

  useEffect(() => {
    const token = localStorage.getItem('SessionToken');
    if (token && created) { setIsAuth(true); }

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    }
  });

  return (
    <div className='mmb-navbar' style={{ zIndex: '100' }}>
      <div
        className='d-flex shadow-sm bg-white border-bottom border-gray-500'
        style={{ height: 'var(--navbarHeight)' }}
      >
        <div className="d-flex h-100 w-100">
          <img className="ms-2 p-2 h-100 w-auto" src={logo || "/img/YourHealthPlan.png"} />
          {/* Header icons & dropdowns */}
          {!hideRight
            ? (
              <div className="me-3 ms-auto h-100 d-flex align-items-center">
                {isAuth
                  ? (
                    <>
                      {/* Design ticket: https://mymedicarebot.atlassian.net/browse/MYR-4722 */}
                      {/* Page navigation dropdown */}
                      <div
                        className={`navbar-icon fs-1 ${navPanel ? 'open' : ''}`}
                        ref={setNpRef}
                        onClick={(e) => {
                          if (navPanel && npRef && npRef.contains(e.target)) {
                            // if  panel is open and user clicks inside it, do not close
                            return;
                          }
                          toggleNavPanel(!navPanel);
                          toggleShopperPanel(false);
                        }}
                        >
                        <FontAwesomeIcon icon={light('heart')} />
                        <div className={`navbar-dropdown p-2 fs-5 shadow-sm border border-gray-500 ${navPanel ? 'open' : ''}`}>
                          <span className='h-link disabled p-2 ms-2 me-2 mt-2 mb-1 d-none' >
                            Recommend a plan
                          </span>
                          <span className='h-link disabled p-2 ms-2 me-2 mb-1 d-none'>
                            Plans in my area
                          </span>
                          <span className='h-link disabled p-2 ms-2 me-2 mb-1 d-none'>
                            Saved plans
                          </span>
                          <span
                            className='h-link p-2 m-2'
                            onClick={() => navigate('/quotes')}
                            >
                            Quotes from my agent
                          </span>
                          <span className='h-link disabled p-2 ms-2 me-2 mb-2 d-none'>
                            Online applications
                          </span>
                        </div>
                      </div>
                      {/* Logout dropdown */}
                      <div
                        className={`navbar-icon fs-1 ${shopperPanel ? 'open' : ''}`}
                        ref={setSpRef}
                        onClick={(e) => {
                          if (shopperPanel && spRef && spRef.contains(e.target)) {
                            // if shopper panel is open and user clicks inside it, do not close
                            return;
                          }
                          toggleShopperPanel(!shopperPanel);
                          toggleNavPanel(false);
                        }}
                        >
                        <FontAwesomeIcon icon={light('user-circle')} />
                        <div className={`navbar-dropdown p-2 ${shopperPanel ? 'open' : ''} p-2 fs-5 shadow-sm border border-gray-500`}>
                          <div className="p-3 pb-0 fs-6 text-muted">Signed in as</div>
                          <div className="ps-3 pe-3 text-dark">{shopper.firstName} {shopper.lastName}</div>
                          <div className="ps-3 pe-3 text-dark">{shopper.email}</div>
                          <div
                            className="h-link p-2 m-2 text-decoration-underline"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleLogout();
                            }}
                            >
                            Sign out
                          </div>
                        </div>
                      </div>
                    </>
                  )
                      : (
                        <div
                          className="p-2 h-link"
                          style={{ cursor: 'pointer' }}
                          onClick={() => navigate('/login')}
                          >
                          Sign in
                        </div>
                      )
                }
              </div>
            )
            // props.hideRight - do not show anything on right side of topbar
            : null
          }
        </div>
      </div>
      {showAgent ? <TopbarAgent /> : null}
    </div>
  );
}

export default Topbar;
