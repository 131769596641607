import React from 'react';

// components
import { YesNo } from '../../components/inputs';
import { FormRow } from '../../components/layout';

function MailOrder() {
  return (
    <div>
      <FormRow>
        <YesNo
          label="Are you interested in having prescriptions mailed to you through our Home Delivery program?"
          name="mailorder"
        />
      </FormRow>
    </div>
  );  
}

export default MailOrder;
