import React from 'react';
import { useSelector } from 'react-redux';
import constructData from './data';
import CARRIER_ID_MAPPING from './carrierIDMapping';

import CarrierAndYearsTable from './CarrierAndYearsTable';

function Features() {
  const {
    theme,
    agent: {
      carrierPermissions,
    },
  } = useSelector(store => store);
  const {
    themeType, // consumer, agent, manage, enrollment, formulary
    enrollDisabledPlans = [],
    pricingDisclaimers = {},
  } = theme;
  const data = constructData(theme).sort((a, b) => ((a.name > b.name) ? 1 : -1));

  if (!themeType) return ('Please set a themeType in this site\'s theme');

  function generateConfigDisplay(value) {
    if (!value) return 'false';
    if (value === true) return 'true';
    return value;
  }

  return (
    <div className="container">
      <div className="row">
        <h3 className="text-center mb-4 mt-1">
          A list of possible theme features and their status (enabled/disabled)
        </h3>
      </div>
      <div className="row">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Config</th>
              <th>Current Value</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {data.map((config) => (
              <tr key={config.name}>
                <td>{config.name}</td>
                <td className={config.value ? 'table-success' : 'table-danger'}>
                  {generateConfigDisplay(config.value)}
                </td>
                <td>{config.desc}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="row">
        {/* enrollCarriers */}
        {['consumer', 'agent', 'enrollment'].includes(themeType) && (
          <CarrierAndYearsTable
            theme={theme}
            name="enrollCarriers"
            desc="These are the carrier + year combinations that are enabled for enrollment.
            If you do not see a carrier or it does not have any years listed then it is
            not enabled."
          />
        )}

        {/* carriersWithPricing */}
        {['consumer'].includes(themeType) && (
          <CarrierAndYearsTable
            theme={theme}
            name="carriersWithPricing"
            desc="These are the carrier + year combinations that have pricing data.
            If you do not see a carrier or it does not have any years listed then it
            is not enabled."
          />
        )}

        {/* hraCarriers */}
        {['agent', 'enrollment'].includes(themeType) && (
          <CarrierAndYearsTable
            theme={theme}
            name="hraCarriers"
            desc="These are the carrier + year combinations that HRA is enabled for.
            If you do not see a carrier or it does not have any years listed then it
            is not enabled."
          />
        )}
      </div>

      {/* carrierPermissions */}
      {['agent', 'consumer'].includes(themeType) && carrierPermissions && (
        <div className="row">
          <h4 className="fw-bold text-center">carrierPermissions</h4>
          <p className="text-muted">
            If you are logged in as an agent, these are the carrier permissions associated with
            your agent account. Note that if the permissions are updated you will need to logout
            and log back in for the updates to take effect and show up here.
          </p>
          <div className="col-md-6">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Carrier ID</th>
                  <th>Carrier</th>
                  <th>Plan Year</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                {carrierPermissions
                  .sort((a, b) => ((a.carrier_id > b.carrier_id) ? 1 : -1))
                  .map(perm => (
                    <tr>
                      <td>{perm.carrier_id}</td>
                      <td>{CARRIER_ID_MAPPING[perm.carrier_id]}</td>
                      <td>{perm.plan_year}</td>
                      <td className={perm.active ? 'table-success' : 'table-danger'}>
                        {perm.active ? 'yes' : 'no'}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* pricingDisclaimers */}
      {['agent'].includes(themeType) && (
        <div className="row">
          <h4 className="fw-bold text-center">pricingDisclaimers</h4>
          <p className="text-muted">
            This is a list of text for pricing disclaimers. If a year is not specified in
            this configuration then the alert will not show up for an effective date of that year.
          </p>
          <div className="col-md-12">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Effective Date Year</th>
                  <th>Disclaimer Text</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(pricingDisclaimers).map((year) => (
                  <tr>
                    <td>{year}</td>
                    <td>{pricingDisclaimers[year]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* enrollDisabledPlans */}
      {['agent'].includes(themeType) && (
        <div className="row">
          <h4 className="fw-bold text-center">enrollDisabledPlans</h4>
          <p className="text-muted">
            This is a list of CPS ids that are currently disabled from enrollment. Any plans
            in this list will not be allowed to begin an enrollment.
          </p>
          <div className="col-md-4">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>CPS IDs</th>
                </tr>
              </thead>
              <tbody>
                {enrollDisabledPlans.map((id) => (
                  <tr>
                    <td>{id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default Features;
