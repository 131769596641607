import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  fieldExistsInConfig,
  getOptions,
  handleChange,
  getFieldKey
} from '../../../../utils/enroll';

import FormLabel from './FormLabel';

function Options({ opts, onChange, valuesArray, submittedFlag, name }) {
  return (
    <>
      {opts.map(option => (
        <div key={option.value} className="form-check my-3">
          <input
            type="checkbox"
            className="form-check-input"
            defaultChecked={option.defaultChecked}
            checked={valuesArray.includes(option.value)}
            id={option.value}
            onChange={(e) => onChange(e, option)}
            disabled={option.disabled || submittedFlag}
          />
          <FormLabel className="form-check-label" name={option.value} label={option.label} />
        </div>
      ))}
    </>
  );  
}

function CategorizedOptions({ opts, cats, onChange, valuesArray, submittedFlag, name }) {
  return (
    <>
      {cats.map(category => {
        return (
          <>
            <div key={category} className="my-3">
              {category}:
            </div>
            {opts.filter(option => option.category === category).map(option => (
              <div key={option.value} className="form-check my-3 mx-5">
                <input
                  type="checkbox"
                  className="form-check-input"
                  defaultChecked={option.defaultChecked}
                  checked={valuesArray.includes(option.value)}
                  id={option.value}
                  onChange={(e) => onChange(e, option)}
                  disabled={option.disabled || submittedFlag}
                />
                <FormLabel className="form-check-label" name={option.value} label={option.label} />
              </div>
            ))}
          </>
        )})}
      {opts.filter(option => !option.category).map(option => (
        <div key={option.value} className="form-check my-3">
          <input
            type="checkbox"
            className="form-check-input"
            defaultChecked={option.defaultChecked}
            checked={valuesArray.includes(option.value)}
            id={option.value}
            onChange={(e) => onChange(e, option)}
            disabled={option.disabled || submittedFlag}
          />
          <FormLabel className="form-check-label" name={option.value} label={option.label} />
        </div>
            ))}
    </>
  );  
}

function MultiCheck ({
  disabled,
  name,
  options, // this prop will be overidden if options are specified in form JSON config
  unsetOthersOnSelected = [],
}) {

  const dispatch = useDispatch();
  const form = useSelector(store => store.enrollment.form);
  const evalues = useSelector(store => store.evalues);
  const valuesArray = evalues[name] || [];
  const { submittedFlag } = useSelector(store => store.enrollment);

  // defaults to what is being passed in by prop `options`,
  //  can be overidden by setting options in form JSON config
  let opts;
  if (!options) {
    opts = getOptions(form, name);
  } else {
    opts = options;
  }

  // an opt can provide a category prop which will categorize options underneath said cat
  let cats = [];
  if (opts.filter(opt => opt.category).length) {
    cats = [...(new Set(opts.reduce((acc, opt) => opt.category ? [...acc, opt.category] : acc, [])))];
  }

  function updateValues(value, checked, valuesArray) {
    // if we have an unsetOthersOnSelected prop then we want to unselect
    // all other values if it gets selected
    if (unsetOthersOnSelected.includes(value) && checked === true) {
      return [ value ];

    // if we have an unsetOthersOnSelected prop then when any OTHER value
    // is selected we want to UNSELECT this value
    } else if (unsetOthersOnSelected && (unsetOthersOnSelected !== value) && checked === true) {
      return [...valuesArray, value].filter(val => unsetOthersOnSelected !== val);

    // if value is checked, add it to the valuesArray
    } else if (checked === true) {
      return [...valuesArray, value];

    // if value is unchecked, remove it from valuesArray
    } else if (checked === false) {
      return [...valuesArray].filter(val => val !== value);
    }

    return [...valuesArray];
  }

  // if field doesn't exist, don't show it.
  if (!fieldExistsInConfig(form, name)) return null;

  function onChange(e, option) {
    handleChange({
      value: updateValues(option.value, e.target.checked, valuesArray),
      name,
    });
  }

  return !cats.length
    ? (
      <Options
        opts={opts}
        valuesArray={valuesArray}
        onChange={onChange}
        submittedFlag={submittedFlag}
        name={name}
      />
    ) : (
      <CategorizedOptions
        opts={opts}
        cats={cats}
        valuesArray={valuesArray}
        onChange={onChange}
        submittedFlag={submittedFlag}
        name={name}
      />
    );
}

export default MultiCheck;
