import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, light } from '@fortawesome/fontawesome-svg-core/import.macro';
import HTMLReactParser from 'html-react-parser';

import { Collapse } from '../../../common/components';

function BenefitComparison({
  benefits = [],
}) {
  useEffect(() => {
    // todo(@joeysapp): This was my method of handling arbitrarily-shaped DR data, but 
    //                  it's now really hard to reason about and requires lots of checks
    //                  -> rethink formatBenefits.js 

    // The shape of these are 3 dimensional objects:
    /* {
         'Hospital services': {            // Category
           'Primary doctor visit': {       // Service name
             'In-network': '$0 copay',     // NetworkDesc : CostShare
             'Out-of-network: '$0 copay',  // NetworkDesc : CostShare
           },
           'Specialist visit': {           // Service Name
             'text': '$0 copay',           // 'text' : CostShare
           },
         },
       'Preventive services': { ... },
    } */
    let allDropdowns = [];
    let allStates = {};
    benefits.forEach(b => {
      Object.keys(b).forEach(serviceName => {
        if (allDropdowns.indexOf(serviceName) === -1) {
          allDropdowns.push(serviceName);
          allStates[serviceName] = false;
        }
      });
    })
    setDropdowns(allDropdowns);
  }, [benefits]);

  const [dropdowns, setDropdowns] = useState([]);
  const [dropdownState, setDropdownState] = useState({});

  return (
    <div className="d-flex flex-column w-100 bg-gray-200">
      {dropdowns.map(category => {
        let rows = [];
        // if (!benefits[0][category]) return;
        Object.keys({ ...benefits[0][category] }).forEach(serviceName => {
          // let r = benefits[0][category][serviceName];
          if (rows.indexOf(serviceName) === -1) {
            rows.push(serviceName);
          }
        });
        Object.keys({ ...benefits[1][category] }).forEach(serviceName => {
          // let r = benefits[0][category][serviceName];
          if (rows.indexOf(serviceName) === -1) {
            rows.push(serviceName);
          }
        });
        return (
          <>
            <div
              className="d-flex fw-bold border shadow-sm p-3 ps-4 bg-white mt-3 user-select-none cursor-pointer"
              onClick={() => {
                setDropdownState(prevState => {
                  return {
                    ...prevState,
                    [category]: !prevState[category],
                  }
                })
              }}
            >
              {category}
              <FontAwesomeIcon
                icon={dropdownState[category] ? solid('angle-up') : solid('angle-down')}
                className="ms-auto me-2 fs-2"
              />
            </div>
            <Collapse
              isOpen={dropdownState[category]}
              className="shadow-sm"
            >
              {                
                <div className="bg-white">
                  {rows.map(serviceName => {
                    // if (!benefits[0][category] || !benefits[0][category][serviceName]) return;
                    return (
                      <div className="w-100">
                        <div className="fw-bold text-center border p-2">
                          {serviceName}
                        </div>
                        <div className="d-flex flex-row">

                          {/* Left side */}
                          <div className="d-flex flex-column w-50 p-3 border-end">
                            {benefits[0] && benefits[0][category] && benefits[0][category][serviceName] && !benefits[0][category][serviceName]['text']
                               ? (<>
                                    <div className="d-flex flex-column mb-2">                              
                                      <span className="text-muted">In-network</span>
                                      <span>
                                        {benefits[0] && benefits[0][category] && benefits[0][category][serviceName]
                                           ? HTMLReactParser(benefits[0][category][serviceName]['In-Network'] || '--')
                                           : '--'
                                          }
                                      </span>
                                    </div>
                                    <div className="d-flex flex-column mb-2">
                                      <span className="text-muted">Out-of-network</span>
                                      <span>
                                        {benefits[0] && benefits[0][category] && benefits[0][category][serviceName]
                                           ? HTMLReactParser(benefits[0][category][serviceName]['Out-of-Network'] || '--')
                                           : '--'
                                        }
                                      </span>
                                    </div>
                                  </>
                                 ) : (
                                   <>
                                     <div className="d-flex flex-column mb-2">
                                       {/* <span className="text-muted">Coverage</span> */}
                                       <span>
                                         {benefits[0] && benefits[0][category] && benefits[0][category][serviceName]
                                           ? HTMLReactParser(benefits[0][category][serviceName]['text'] || '--')
                                           : '--'
                                          }
                                       </span>
                                     </div>
                                   </>
                                 )
                            }
                          </div>

                          {/* Right side */}
                          <div className="d-flex flex-column w-50 p-3">
                            {benefits[1] && benefits[1][category] && benefits[1][category][serviceName] && !benefits[1][category][serviceName]['text']
                               ? (<>
                                    <div className="d-flex flex-column mb-2">                              
                                      <span className="text-muted">In-network</span>
                                      <span>
                                        {benefits[1] && benefits[1][category] && benefits[1][category][serviceName]
                                           ? HTMLReactParser(benefits[1][category][serviceName]['In-Network'] || '--')
                                           : '--'
                                          }
                                      </span>
                                    </div>
                                    <div className="d-flex flex-column mb-2">
                                      <span className="text-muted">Out-of-network</span>
                                      <span>
                                        {benefits[1] && benefits[1][category] && benefits[1][category][serviceName]
                                           ? HTMLReactParser(benefits[1][category][serviceName]['Out-of-Network'] || '--')
                                           : '--'
                                        }
                                      </span>
                                    </div>
                                  </>
                                 ) : (
                                   <>
                                     <div className="d-flex flex-column mb-2">
                                       {/* <span className="text-muted">Coverage</span> */}
                                       <span>
                                         {benefits[1] && benefits[1][category] && benefits[1][category][serviceName]
                                           ? HTMLReactParser(benefits[1][category][serviceName]['text'] || '--')
                                           : '--'
                                          }
                                       </span>
                                     </div>
                                   </>
                                 )
                            }
                          </div>                         
                        </div>                          
                      </div>
                    )})}
                </div>
              }
            </Collapse>
          </>
        )
      })
      }
    </div>
  )
};

export default BenefitComparison;
