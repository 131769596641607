const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_FIELD_VALIDATION':
      return {
        ...state,
        [action.name]: action.valid,
      };
    case 'SET_FIELD_VALIDATIONS':
      return {
        ...state,
        ...action.fields,
      };
    case 'CLEAR_ENROLLMENT_VALIDATION':
      return initialState;
    case 'CLEAR_ENROLLMENT_FORM':
      return initialState;
    default:
      return state;
  }
}
