import HTMLReactParser from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, light } from '@fortawesome/fontawesome-svg-core/import.macro';
/* eslint-disable */

export const formatDocuments = ({
  plan = {},
}) => {
  const documents = {};
  const hasDocuments = plan.plan?.PlanDocuments && plan.plan?.PlanDocuments.length;
  const component = (
    <div className="d-flex flex-column p-4">
    {!hasDocuments
      ? (<>There are no available documents at this time.</>)
      : null}
    {hasDocuments
      ? plan.plan.PlanDocuments.map((d, idx) => {
      return (
        <div
          className={`d-flex bg-white`}
          key={d.DocumentName}
        >
            <a
              className="btn d-flex align-items-center justify-content-center h-link w-50 m-2 p-2 bg-white border-primary text-primary"
              style={{ minWidth: '300px' }}
              target="_blank"
              href={d.URL}
              tabIndex={0}
              alt={d.DocumentName}
            >
              <FontAwesomeIcon icon={light('file-lines')} className="me-2" />
              {d.DocumentName}
            </a>
        </div>
        )})
    : null}
    </div>
  );
  return { component, text: 'Plan Documents' };
}

// https://github.com/mymedicarebot/agent-frontend/blob/dev/src/utils/formatFunctions.jsx#L34
const suppressBenefits = (benefits = []) => {
  const sb = [
    'Transportation',
    'Optional Supplemental Benefits',
    'Wellness Programs (e.g. fitness, nursing hotline)'
  ];
  return benefits.filter(b => sb.indexOf(b.CategoryName) === -1);
}

const insertBenefits = (benefits = []) => {
  return benefits;
}

// Takes a plan, returns a three dimensional object, like
/* {
   'Hospital services': {
     'Primary doctor visit': {
       'In-network': '$0 copay',
       'Out-of-network: '$0 copay',
     },
     'Specialist visit': {
       'text': '$0 copay',
     }
   },
   'Preventive services': { ... },
   ...}
*/
export const formatPlanBenefitDetails = (plan) => {
  let docurobotBenefits = [...(plan?.plan?.PlanBenefitDetails || [])];
  docurobotBenefits = suppressBenefits(docurobotBenefits);
  docurobotBenefits = insertBenefits(docurobotBenefits);
  // (Potentially) Split out any nested DR benefits into two here?
  // Cases: * b.Costshare contains a line-break (saw this once...)

  // Logically group benefits into { category: [ ...benefits ] }
  const groupedBenefits = docurobotBenefits.reduce((acc, b) => {
    return { 
      ...acc,
      [b.CategoryName]: [
        ...(acc[b.CategoryName] || []),
        b,
      ]
    }
  }, {});

  // Sort [ ...benefits ] by ServiceID, then sort by NetworkSort
  Object.keys(groupedBenefits).forEach(category => {
    groupedBenefits[category].sort((b1, b2) => b1.ServiceID - b2.ServiceID);
    groupedBenefits[category].sort((b1, b2) => b1.NetworkSort - b2.NetworkSort);
  });

  // Cases where we want to use a plan.plan[key] cost instead
  const categoryExceptions = { 'Deductibles': 'DeductibleFullText' };

  // V2, 2023-04-26: Grouping items in a two dimensional object instead of a component itself
  let planBenefitDetails = {};
  Object.keys(groupedBenefits).forEach(category => {
    planBenefitDetails[category] = {};
    groupedBenefits[category].map((b, idx) => {
      // NetworkDesc typically 'In-network' 'out of network' or empty string
      // ServiceName is benefit's actual name
      // Costshare is numeric value
      let readableNetwork = `${b.NetworkDesc}` || 'text'; // note: some benefits are not in/out of network
      let readableName = `${b.ServiceName}`;
      let readableCost = `${b.Costshare}`; // (TODO: Some costshares have 'In-and-out-of-network'...)
      if (readableName.length <= 5) {
        readableName = `${b.CategoryName}`;
      }
      if (categoryExceptions[category]) {
        readableCost = plan.plan[categoryExceptions[category]];
      }
      planBenefitDetails[category] = {
        ...(planBenefitDetails[category] || {}),                 // grouping all previous benefits
        [readableName]: {                                        // e.g. adding 'Deductibles': { }
          ...(planBenefitDetails[category][readableName] || {}), // e.g. grouping in-network: $420
          [readableNetwork]: readableCost,                       //      & out-of-network: $0
        },
      };
    })
  });
  return planBenefitDetails;
}

// Currently used on Plan detail's page, refactor out to use above
export const formatBenefits = ({
  plan = {},
}) => {
  let { plan: docurobotPlan = {} } = plan;
  let { PlanBenefitDetails = [] } = docurobotPlan;
  let docurobotBenefits = [...PlanBenefitDetails];
  docurobotBenefits = suppressBenefits(docurobotBenefits);
  docurobotBenefits = insertBenefits(docurobotBenefits);
  // (Potentially) Split out any nested DR benefits into two here?
  // Cases: * b.Costshare contains a line-break (saw this once...)

  // Logically group benefits into { category: [ ...benefits ] }
  const groupedBenefits = docurobotBenefits.reduce((acc, b) => {
    return { 
      ...acc,
      [b.CategoryName]: [
        ...(acc[b.CategoryName] || []),
        b,
      ]
    }
  }, {});

  // Sort [ ...benefits ] by ServiceID, then sort by NetworkSort
  Object.keys(groupedBenefits).forEach(category => {
    groupedBenefits[category].sort((b1, b2) => b1.ServiceID - b2.ServiceID);
    groupedBenefits[category].sort((b1, b2) => b1.NetworkSort - b2.NetworkSort);
  });

  // Cases where we want to use a plan.plan[key] cost instead
  const categoryExceptions = { 'Deductibles': 'DeductibleFullText' };

  // Reduce a given category's [ ...benefits ] into a single list component to display
  Object.keys(groupedBenefits).forEach(category => {
    let text = '';
    let component = (
      <div className="d-flex flex-column w-100">
          {groupedBenefits[category].map((b, idx) => {
            // NetworkDesc typically 'In-network' 'out of network' or empty string
            // ServiceName is benefit's actual name
            // Costshare is numeric value
            let readableName = `${b.NetworkDesc} ${b.ServiceName}: `;
            let readableCost = `${b.Costshare}`;
            if (readableName.length <= 5) {
              readableName = `${b.CategoryName}`;
            }
            if (categoryExceptions[category]) {
              readableName = category;
              readableCost = plan.plan[categoryExceptions[category]];
              text += `${readableCost}`;
            } else {
              text += `${readableName} ${readableCost}`
            }
            return (
              <div
                className={`d-flex p-3 ps-4 ${idx % 2 !== 0 ? 'bg-white' : 'bg-gray-200'}`}
                key={readableName}
              >
                <div
                  className="w-50"
                  style={{ wordBreak: 'none' }}
                >
                  {HTMLReactParser(readableName)}
                </div>
                <div className="w-50">
                  {HTMLReactParser(readableCost)}
                </div>
              </div>
            )
          })}
      </div>
    );
    groupedBenefits[category] = {
      // benefits: groupedBenefits[category], // Debugging utility
      component,
      text,
      // Side-by-side views
    };
  });
  return groupedBenefits;
};
