import axios from 'axios';
import { store } from '../store';

// `site.com?preview` will load the unpublished preview site theme.
const preview = new URLSearchParams(window.location.search).has('preview');

/**
 * Pull down the latest published theme.
 */
export function loadTheme(hash) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/config/theme/`,
      params: { hash },
    }).then(({ data }) => {
      store.dispatch({
        type: 'LOAD_THEME',
        payload: { ...data },
      });
      resolve({ data });
    }).catch((err) => {
      reject(err);
    });
  });
}

/**
 * Check for any published theme updates.
 */
export function validateTheme() {
  axios({
    url: `${process.env.REACT_APP_MMB_API_URL}/api/config/theme/versions/`,
  }).then(({ data }) => {
    const currentState = store.getState();
    const { hash } = currentState.theme;
    const builtHash = preview ? data.draft : data.live;

    if (hash !== builtHash) {
      loadTheme(builtHash);
    }
  });
}
