import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  handleChange,
  handleValidation,
  getValidClass,
  isRequired,
  getFieldKey,
  fieldExistsInConfig,
} from '../../../../utils/enroll';

import FormLabel from './FormLabel';

function TextInput ({
  autoFocus,
  clone,
  disabled,
  label,
  name,
  small,
  staticValue,
  className,
}) {

  const form = useSelector(store => store.enrollment.form);
  const evalues = useSelector(store => store.evalues);
  const value = evalues[name];
  const cloneValue = evalues[clone];
  const required = isRequired({ form, name, currentValues: evalues });
  const customLabel = getFieldKey(form, name, 'label');

  const validationRegex = useSelector(store => store.enrollment.validation[name]);
  const valid = useSelector(store => store.evalid[name]);
  const validClass = getValidClass({ valid, required, value });

  const { submittedFlag } = useSelector(store => store.enrollment);

  const isDisabled = (disabled || clone || submittedFlag);

  const classes = ['form-control', (!isDisabled && validClass)].join(' ')

  useEffect(() => {
  }, []);

  useEffect(() => {
    // If the field is cloned, we need to set it's value
    // when the cloned field gets updated instead of when
    // this field gets updated
    if (clone) {
      handleChange({ name, value: cloneValue });
      handleValidation({ value: cloneValue, validationRegex, name, required });
    }
    // if we are setting a static value, we need to make sure the value
    // gets properly set in redux on field load.
    if (staticValue) {
      handleChange({ name, value: staticValue });
      handleValidation({ value: staticValue, validationRegex, name, required });
    }
  }, [clone, cloneValue, staticValue]);

  // if field doesn't exist, don't show it.
  if (!fieldExistsInConfig(form, name)) return null;

  return (
    <>
      <FormLabel name={name} label={customLabel || label} required={required} />
      <div className={`${className} ${small ? undefined : 'col-md-6'}`}>
        <input
          type="text"
          autoFocus={((valid === undefined) && autoFocus)}
          className={classes}
          id={name}
          onChange={(e) => handleChange({ value: e.target.value, name, valid, validationRegex, required })}
          value={staticValue || evalues[clone || name] || ''}
          disabled={isDisabled}
          onBlur={() => handleValidation({ value, validationRegex, name, required })}
        />
      </div>
    </>
  );  
}

export default TextInput;
