import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../components';
import { handleValidation, extractValidation } from '../../utils/enroll';

import {
  getEnrollment,
  createEnrollment,
  getEnrollmentConfig,
  getEnrollmentOptions,
} from '../../actions/enrollment';
import { getPlan, getAdditionalPlanInfo } from '../../actions/plan';
import { getAgent } from '../../actions/agent';
import { getShopper } from '../../actions/shopper';
import { getQuote } from '../../actions/quote';
import { checkIfLoggedIn } from '../../actions/session';
import { getParam } from '../../utils/qs';
import { evaluateConditions } from '../../common/utils/enrollment';
import { store } from '../../store';

function EnrollmentLoader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shopper = useSelector(store => store);
  const {
    userWhoCreated: agentUsername,
    username: shopperUsername,
  } = useSelector(store => store.shopper);
  const [errMsg, setErrMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const quoteID = getParam('quoteID');

  const loadEnrollment = async () => {
    // NOTE(santeyio): not the nicest thing to instantiate all these variables
    // at the top like this, but I wanted to have fairly granular error handling.
    // Declaring variables inside of a try/catch block scopes the variables
    // so they then can't be accessed outside the try/catch. I think the
    // async/await syntax here makes it cleaner than promise chaining (even
    // with all the variables we have to declare up here).
    let enrollmentExists, carrier, planYear, effectiveDate, planID,
      enrollment, quote, validation, formConfig, filteredFormConfig,
      planMerge;

    // first double check that user is logged in
    checkIfLoggedIn().catch(() => {
      navigate(`/login?redirect=/enroll/load?quoteID=${quoteID}`);
    });

    // clear out previous form data and load quote
    try {
      setLoading(true);
      document.title = 'Enrollment';
      dispatch({ type: 'CLEAR_ENROLLMENT_FORM' });
      dispatch({ type: 'SET_ENROLLMENT_FIELD', payload: { isLoading: true } });
      quote = await getQuote(quoteID);
      ({ carrier, planYear, effectiveDate, planID } = quote);
      enrollmentExists = Boolean(quote.enrollment);
    } catch (err) {
      console.log(err);
      setErrMsg(<span>Unable to load the quote <i>{quoteID}</i></span>);
      setLoading(false);
      return;

    // get or create data for enrollment
    } try {
      if (!enrollmentExists) {
        enrollment = await createEnrollment(quoteID, shopper)
        dispatch({ type: 'SET_ALERT', payload: { resumeEnrollmentEmail: true } });
      } else {
        enrollment = await getEnrollment(quoteID);
      }
      dispatch({ type: 'SET_ALERT', payload: { formSpecificAlert: true } });
    } catch (err) {
      console.log(err);
      setErrMsg(<span>Unable to load or create enrollment for <i>{quoteID}</i></span>);
      setLoading(false);
      return;
      
    } try {
      formConfig = await getEnrollmentConfig(carrier, planYear);
    } catch (err) {
      console.log(err);
      setErrMsg(<span>There was an error loading the configuration for this form</span>);
      setLoading(false);
      return;

    // load values and defaults
    } try {
      const [
        sepOptions,
        raceOptions,
        ethnicityOptions,
        agent,
        plan,
        unformattedShopper,
      ] = await Promise.all([
        getEnrollmentOptions('sep_options'),
        getEnrollmentOptions('race_options'),
        getEnrollmentOptions('ethnicity_options'),
        getAgent(agentUsername), // reload agent to make sure perms are semi up to date
        getPlan(planID),
        getShopper(shopperUsername, { formatted: false }),
      ]);
      const additionalPlanInfo = await getAdditionalPlanInfo(planID, quote.county) || {};

      const fieldDefaults = await getEnrollmentOptions('field_defaults');

      // extract individual field defaults from config
      const defaultValidation = {};
      const defaultLabels = {};
      Object.entries(fieldDefaults).forEach(([ key, value ]) => {
        defaultValidation[key] = value.validation;
        defaultLabels[key] = value.label;
      });

      // override default validation with custom validation
      const customValidation = extractValidation(formConfig);
      validation = { ...defaultValidation, ...customValidation };

      // merge additional plan info (for anthem) into plan
      planMerge = {
        ...plan,
        plan: {
          ...additionalPlanInfo,
          ...plan.plan,
        },
      };

      dispatch({ type: 'SET_AGENT', payload: agent });
      dispatch({ type: 'LOAD_ENROLLMENT', payload: enrollment });

      // some fields require checking against conditions determined by plan data
      // for validation. Here we inject those values into store.evalues so that
      // the validation can read them.
      const isCSNP = (plan.plan.SNPIND === 1); // if SNPIND === 1 then it's CSNP
      const isDSNP = (plan.plan.SNPIND === 2); // if SNPIND === 2 then it's DSNP
      const isISNP = (plan.plan.SNPIND === 3); // if SNPIND === 3 then it's ISNP
      dispatch({ type: 'SET_EVALUES_FIELD', name: 'isCSNP', value: isCSNP });
      dispatch({ type: 'SET_EVALUES_FIELD', name: 'isDSNP', value: isDSNP });
      dispatch({ type: 'SET_EVALUES_FIELD', name: 'isISNP', value: isISNP });
      dispatch({
        type: 'LOAD_ENROLLMENT_VALUES',
        payload: {
          quote,
          plan: planMerge.plan,
        },
      });

      const { fields } = enrollment;

      console.log('unformattedShopper: ', unformattedShopper);
      // pull out shopper.data.beq keys from shopper
      const { data: { beq: beqData = {} } = {} } = unformattedShopper;
      // in shopper.data.beq keys need to be snake cased
      const beqReformatted = {
        street1: beqData.address,
        part_a_effective_date: beqData.partAEffectiveDate,
        part_b_effective_date: beqData.partBEffectiveDate,
      };

      // remove some of the longer data items from the shopper
      // before storing the shopper data in the enrollment fields
      const filteredShopper = {
        ...unformattedShopper,
        ...beqReformatted,
        quotes: undefined,
        campaigns: undefined,
        agency: undefined,
        data: undefined,
        email: unformattedShopper.contact_email || unformattedShopper.email || undefined,
      };
      const mergedFields = {
        ...filteredShopper,
        ...fields,
      };

      // iterate all fields and set values and validation status for each field
      // skip over plan and quote so we have the most up to date info on each
      Object.keys(mergedFields).forEach(name => {
        const skipFields = ['plan', 'quote'];
        if (!skipFields.includes(name)) {
          const value = mergedFields[name];
          const validationRegex = validation[name];
          // populate the form with existing shopper data
          // we want to set and validate 'bool false' values and 'int 0' values,
          // but not undefined / null / blank string values.
          if (value !== null && value !== undefined && value !== '') {
            handleValidation({ validationRegex, value, name });
            dispatch({ type: 'SET_EVALUES_FIELD', name, value });
            // if (value) dispatch({ type: 'SET_FIELD_VALIDATION', name, valid: true });
          }
        }
      });

      dispatch({
        type: 'SET_ENROLLMENT_FIELD',
        payload: {
          sep: sepOptions.value,
          race: raceOptions.value,
          ethnicity: ethnicityOptions.value,
          plan: planMerge,
          quote,
          labels: defaultLabels,
          validation,
        },
      });

    } catch (err) {
      console.log(err);
      setErrMsg(`There was a problem setting up the enrollment form.`);
      setLoading(false);
      return;

    } try {
      const { evalues: values } = store.getState();
      filteredFormConfig = formConfig.form.map(section => {
        let displaySection = true;

        if (section.displayConditions) {
          displaySection = evaluateConditions({
            conditions: section.displayConditions,
            currentValues: values,
          });
        }

        if (displaySection) {
          const filteredPages = (section.pages || []).map(page => {
            let displayPage = true;

            if (page.displayConditions) {
              displayPage = evaluateConditions({
                conditions: page.displayConditions,
                currentValues: values,
              });
            }

            if (displayPage) return page;

            return undefined;
          }).filter(page => (page !== undefined));

          return { ...section, pages: filteredPages };
        }

        return undefined;
      }).filter(section => (section !== undefined));

      dispatch({
        type: 'SET_ENROLLMENT_FIELD',
        payload: {
          ...formConfig,
          form: filteredFormConfig,
        },
      });

    } catch (err) {
      console.log(err);
      setErrMsg(`There was an error setting up the configuration for this form.`);
      setLoading(false);
      return;

    } try {
      const rfmCarrier = carrier.trim().replaceAll(' ', '-');
      const enrollmentURLBase = `/enroll/${rfmCarrier}/${planYear}`;

      if (!enrollment.submittedFlag) {
        const {
          route: sectionURL,
          pages: [ { route: pageURL } ],
        } = filteredFormConfig[0];
        const enrollmentURL = `${enrollmentURLBase}${sectionURL}${pageURL}`;
        dispatch({ type: 'SET_ENROLLMENT_FIELD', payload: { isLoading: false } });
        setLoading(false);
        navigate(enrollmentURL);
        return;
      } else {
        const enrollmentURL = `${enrollmentURLBase}/confirmation`;
        setLoading(false);
        navigate(enrollmentURL);
        return;
      }
    } catch (err) {
      console.log(err);
      setErrMsg(`There was a problem navigating to the enrollment.`);
      setLoading(false);
      return;
    }
  }

  useEffect(() => {
    loadEnrollment();
  }, []);

  return (
    <div className="container">
      <div className="row mt-5">
        {loading ? (
          <>
            <div className="d-flex col-md-12 justify-content-center mt-5">
              <h3 className="text-muted">One moment while we load your enrollment...</h3>
            </div>
            <div className="d-flex col-md-12 justify-content-center mt-5">
              <Spinner pixelSize="75" />
            </div>
          </>
        ) : ( errMsg && (
          <>
            <div className="d-flex col-md-12 justify-content-center mt-5">
              <h3>Something went wrong loading your enrollment.</h3>
            </div>
            <div className="d-flex col-md-12 justify-content-center">
              <p className="text-muted">Please close this window and try again</p>
            </div>
            <div className="d-flex col-md-12 justify-content-center mt-5">
              <div className="alert alert-warning">
                {errMsg}
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}


export default EnrollmentLoader;
