import * as moment from 'moment';
import { yesNo, yn, existsOrBlank } from './mapping-tools';
import { stripPhone, cleanDate } from './formatting';
import getAgentID from './getAgentID';

export default ({
  agent,
  plan,
  quote,
  fields: res,
  theme,
  confirmationNumber,
}) => {
  const { useCarrierPerms } = theme;

  let agentID;
  if (useCarrierPerms) {
    const ids = getAgentID({ agent, quote });
    agentID = ids.agentID;
  }

  const premiumDeducted = yesNo(['rrb', 'ssi'].includes(res.paymeth));
  const premiumDirectPay = yesNo(!['rrb', 'ssi'].includes(res.paymeth)); // if paymeth not specified this is set to true
  const premiumWithhold = ['rrb', 'ssi'].includes(res.paymeth) ? res.paymeth.toUpperCase() : '';
  const planYear = plan.PlanYear;
  const isEFT = res.paymeth === 'eft';
  const eftActType = (res.eft_acttype === 'checking') ? 'Checking' : 'Saving';
  const ltcfAddress = res.ltcf
    ? `${res.ltcf_addr_street1}${res.ltcf_addr_street2 ? ` ${res.ltcf_addr_street2}` : ''}, ${res.ltcf_addr_city}, ${res.ltcf_addr_state} ${res.ltcf_addr_zip}`
    : '';

  const isSEP = res.enrolltype === 'SEP';
  const isSepOther = res.sep_code === 'OTH';
  // format sep date as mmddyyyy
  const sepDate = res.sep_date ? `${res.sep_date.slice(5, 7)}${res.sep_date.slice(8, 10)}${res.sep_date.slice(0, 4)}` : '';
  const sepConcat = `${res.sep_code} ${sepDate}`.trim();

  const effectiveDate = moment(res.effective_date).format('MMDDYYYY');
  const agentFullName = `${agent.firstName} ${agent.lastName}`;
  const gender = (res.gender === 'male') ? 'M' : 'F';

  const premiumAmount = quote.premium;
  // const isLargePrint = yn(res.altwritten === 'LargePrint');

  let race = '';
  let ethnicity = '';

  if (Array.isArray(res.race) && res.race.length) {
    race = res.race.join(',');
  }
  if (Array.isArray(res.ethnicity) && res.ethnicity.length) {
    ethnicity = res.ethnicity.join(',');
  }

  const formattedData = [
    { col: 'ConfirmationNumber', val: confirmationNumber },
    { col: 'SubmitDate', val: moment().format('MMDDYYYY') },
    { col: 'ContractID', val: plan.ExternalContractID },
    { col: 'PlanID', val: plan.ExternalPlanID },
    { col: 'SegmentID', val: plan.ExternalSegmentID },
    { col: 'ApplicantTitle', val: '' },
    { col: 'ApplicantFirstName', val: res.first_name },
    { col: 'ApplicantMiddleInitial', val: res.middle_name },
    { col: 'ApplicantLastName', val: res.last_name },
    { col: 'ApplicantBirthDate', val: moment(res.birthdate).format('MMDDYYYY') },
    { col: 'ApplicantGender', val: gender },
    { col: 'ApplicantAddress1', val: res.street1 },
    { col: 'ApplicantAddress2', val: res.street2 },
    { col: 'ApplicantAddress3', val: '' },
    { col: 'ApplicantCity', val: (res.city || '').slice(0, 30) },
    { col: 'ApplicantCounty', val: res.county_name },
    { col: 'ApplicantState', val: res.state.toUpperCase() },
    { col: 'ApplicantZip', val: res.zipcode },
    { col: 'ApplicantPhone', val: stripPhone(res.home_phone) },
    { col: 'ApplicantEmailAddress', val: res.email },
    { col: 'ApplicantHICN', val: res.medicare_id },
    { col: 'ApplicantSSN', val: '' }, // we do not collect ssn
    { col: 'MailingAddress1', val: res.samemail ? res.street1 : res.mailstreet1 },
    { col: 'MailingAddress2', val: res.samemail ? res.street2 : res.mailstreet2 },
    { col: 'MailingAddress3', val: '' },
    { col: 'MailingCity', val: res.samemail ? res.city : res.mailcity },
    { col: 'MailingState', val: res.samemail ? res.state : res.mailstate },
    { col: 'MailingZip', val: res.samemail ? res.zipcode : res.mailzip },
    { col: 'MedicarePartA', val: cleanDate(res.part_a_effective_date) },
    { col: 'MedicarePartB', val: cleanDate(res.part_b_effective_date) },
    { col: 'EmergencyContact', val: res.hasemer ? `${res.emerfname} ${res.emerlname}` : '' },
    { col: 'EmergencyPhone', val: res.hasemer ? stripPhone(res.emertel) : '' },
    { col: 'EmergencyRelationship', val: res.hasemer ? res.emerrel : '' },
    { col: 'PremiumDeducted', val: premiumDeducted },
    { col: 'PremiumSource', val: '' },
    { col: 'OtherCoverage', val: yn(res.othercoverage) }, // applies to PDP, MAPD, SNP DE, PFFS-PD, CP-PD
    { col: 'OtherCoverageName', val: res.othercoverage ? res.othercoverage_name : '' },
    { col: 'OtherCoverageID', val: res.othercoverage ? res.othercoverage_num : '' },
    { col: 'LongTerm', val: yn(res.ltcf) },
    { col: 'LongTermName', val: res.ltcf ? res.ltcf_name : '' },
    { col: 'LongTermAddress', val: ltcfAddress },
    { col: 'LongTermPhone', val: res.ltcf ? stripPhone(res.ltcf_tel) : '' },
    { col: 'AuthorizedRepName', val: res.ispoa ? `${res.poa_fname} ${res.poa_lname}` : '' },
    { col: 'AuthorizedRepAddress', val: res.ispoa ? `${res.poa_addr_street1} ${res.poa_addr_street2 || ''}`.trim() : '' },
    { col: 'AuthorizedRepCity', val: res.ispoa ? res.poa_addr_city : '' },
    { col: 'AuthorizedRepState', val: res.ispoa ? res.poa_addr_state : '' },
    { col: 'AuthorizedRepZip', val: res.ispoa ? res.poa_addr_zip : '' },
    { col: 'AuthorizedRepPhone', val: res.ispoa ? res.poa_tel : '' },
    { col: 'AuthorizedRepRelationship', val: res.ispoa ? res.poa_rel : '' },
    { col: 'Language', val: res.preflang ? res.preflang : '' },
    { col: 'ESRD', val: '' }, // Per CMS as of 12/31/2020 ESRD no longer applicable (End-Stage Renal Disease)
    { col: 'StateMedicaid', val: yn(res.onmedicaid) },
    { col: 'WorkStatus', val: yn(res.willwork || res.willworkspouse) },
    { col: 'PrimaryCarePhysicianName', val: res.pcp_name || '' },
    { col: 'OtherCoverageGroup', val: res.othercoverage_group },
    { col: 'AgentID', val: agentID },
    { col: 'SubmitTime', val: moment().format() },
    { col: 'PartDSubAppInd', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'DeemedInd', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'SubsidyPercentage', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'DeemedReasonCode', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'LISCopayLevelID', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'DeemedCopayLevelID', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'PartDOptOutSwitch', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    // formatted as `SEPCODE mmddyyyy` or `SEPCODE`
    { col: 'SEPReasonCode', val: isSEP ? sepConcat : '' },
    // Only used when indicating OTH as the SEP Reason Code in order to explain the reason the individual is eligible to enroll
    { col: 'SEPCMSReasonCode', val: isSepOther ? res.sep_other : '' },
    { col: 'PremiumDirectPay', val: premiumDirectPay },
    { col: 'EnrollmentPlanYear', val: planYear },
    { col: 'RequestedEffectiveDate', val: effectiveDate },
    { col: 'PremiumWithhold', val: premiumWithhold },
    { col: 'PolicyPremiumAmount', val: premiumAmount },
    { col: 'RoutingNumber', val: isEFT ? res.eft_routing : '' },
    { col: 'BankName', val: isEFT ? res.eft_bankname : '' },
    { col: 'AccountNumber', val: isEFT ? res.eft_actnum : '' },
    { col: 'Accounttype', val: isEFT ? eftActType : '' },
    { col: 'IsSignaturedifferentfromOwner', val: isEFT ? yn(res.eft_sigdiff) : '' },
    { col: 'AccountholderName', val: isEFT ? res.eft_actname : '' },
    { col: 'AccessibilityFormat', val: res.altwritten },
    { col: 'EmailOptIn', val: yn(res.okemail) },
    { col: 'AgentName', val: agentFullName },
    { col: 'ProviderID', val: res.pcp_ipa || '' }, // wellcare IPA number goes here
    { col: 'Currentpatient', val: yn(res.pcp_established) },
    { col: 'CampaignCode', val: '' }, // not applicable to MMB
    { col: 'Race', val: race },
    { col: 'Ethnicity', val: ethnicity },

    { col: 'PrimaryCarePhysicianID', val: res.pcp_num },
    { col: 'PrimaryCarePhysicianNPI', val: '' },
    { col: 'ProviderGroupName', val: '' },
    { col: 'SignatureReceived', val: '' },
    { col: 'SignatureDate', val: '' },
    { col: 'ApplicantMobileNumber', val: '' },
    { col: 'MedicaidNumber', val: existsOrBlank(res.medicaidnum) },
    { col: 'TextOptin', val: '' },
    { col: 'SourceOfApplication', val: '' },
    { col: 'BuyUpPackage', val: '' },
    { col: 'PreferredMethodofContact', val: res.prefcontactmethod || '' },
  ];

  return { formattedData, agentID };
};
