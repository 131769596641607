import React from 'react';
import { useSelector } from 'react-redux';

import {
  handleChange,
  isRequired,
  getFieldKey,
  fieldExistsInConfig,
} from '../../../../utils/enroll';

import FormLabel from './FormLabel';

import '../../styles.scss';

function YesNo ({
  autoFocus,
  disabled,
  label,
  name,
  unsetFieldsOnTrue = [],
  unsetFieldsOnFalse = [],
  className = '',
}) {

  const form = useSelector(store => store.enrollment.form);
  const evalues = useSelector(store => store.evalues);
  const value = evalues[name];
  const required = isRequired({ form, name, currentValues: evalues });

  const validationRegex = useSelector(store => store.enrollment.validation[name]);

  const { submittedFlag } = useSelector(store => store.enrollment);

  const customLabel = getFieldKey(form, name, 'label');

  // if field doesn't exist, don't show it.
  if (!fieldExistsInConfig(form, name)) return null;

  function unsetFieldOnSecondClick(newValue, oldValue) {
    console.log('newValue: ', newValue, ' | oldValue: ', oldValue);
    if (newValue === oldValue) {
      handleChange({
        value: undefined,
        name,
        valid: true,
        validationRegex,
        unsetFieldsOnTrue,
        unsetFieldsOnFalse,
        required,
      });
    }
  }

  return (
    <div>
      <FormLabel name={name} label={customLabel || label} required={required} />
      <br />
      <button
        type="button"
        className={[className, 'yn-button me-4 mt-2', (value === true && 'yn-button-hl')].join(' ')}
        onClick={() => handleChange({
          value: true,
          name,
          valid: true,
          required,
          validationRegex,
          unsetFieldsOnTrue,
          unsetFieldsOnFalse,
          onChangeCallback: (newValue) => unsetFieldOnSecondClick(newValue, value),
        })}
        disabled={disabled || submittedFlag}
      >
        Yes
      </button>
      <button
        type="button"
        className={['yn-button mt-2', (value === false && 'yn-button-hl')].join(' ')}
        onClick={() => handleChange({
          value: false,
          name,
          valid: true,
          required,
          validationRegex,
          unsetFieldsOnTrue,
          unsetFieldsOnFalse,
          onChangeCallback: (newValue) => unsetFieldOnSecondClick(newValue, value),
        })}
        disabled={disabled || submittedFlag}
      >
        No
      </button>
    </div>
  );  
}

export default YesNo;
