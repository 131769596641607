import { store } from '../store';


export function getPlanCarrierDisplay({ quote, plan }) {
    const {
        theme: {
            planListFilterType = 'carrier'
        },
    } = store.getState();

    if (!['org', 'carrier', 'brand'].includes(planListFilterType)) {
        throw new Error('planListFilterType is invalid. src/utils/general.getPlanCarrierDisplay');
    }

    if (plan) {
        return plan[planListFilterType];
    } else if (quote) {
        return quote[planListFilterType];
    }
    return 'Error';
}
