import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { canEnrollCarrier, hasCarrierPerms } from '../utils/enroll';

// note(@joeysapp)
// used on QuoteCard and PlanDetails to load in evalues or create an enrollment

// actions
import { getShopper } from '../actions/shopper';

function EnrollmentLoaderButton({
  className,
  quote = {},
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { quoteID, enrollment, carrier, planYear, carrierID, state, planType, cpsID } = quote;
  const { lifespan } = useSelector(store => store.session);
  const { created,  username } = useSelector(store => store.shopper);
  const { enrollDisabledPlans = [] } = useSelector(store => store.plan);

  let btnText;
  let btnColor = 'primary';
  const disabled = !canEnrollCarrier({ carrier, planYear }) || !hasCarrierPerms(planType, carrierID, state, planYear) || (enrollDisabledPlans.indexOf(cpsID) !== -1);


  if (enrollment && (enrollment.submittedFlag || enrollment.submitted_flag)) {
    btnText = 'View confirmation';
    btnColor = 'success';
  } else if (enrollment) {
    btnText = 'Continue enrollment';
    btnColor = 'secondary';

  // even if disabled, still show 'enroll now', we just don't push to enrollment page
  } else {
    btnText = 'Enroll now';
  }

  const reloadShopper = async () => {
    getShopper(username)
      .then((shopper) => dispatch({ type: 'SET_SHOPPER', payload: shopper }));
  }

  const handleLoad = async () => {
    const enrollmentWindow = window.open(`/enroll/load?quoteID=${quoteID}`, '_blank');
    // listen for postMessage telling us an enrollment has been submitted
    // and reload quotes on submission.
    window.addEventListener('message', (event) => {
      if (event.origin === window.location.origin) {
        const { data: { enrollmentSubmitted } } = event;
        if (enrollmentSubmitted) {
          reloadShopper();
        }
      }
    });
    // also listen for closing of enrollment window and refresh quotes list
    // if enrollment window is closed
    const timer = setInterval(() => {
      if (enrollmentWindow.closed) {
        clearInterval(timer);
        reloadShopper();
      }
    }, 1000);
  }
  
  return (
    <button
      className={`btn btn-${btnColor} fw-bold ${className}`}
      onClick={() => {
        if (!created || !lifespan) {
          navigate('/signup');
        } else if (disabled) {
          dispatch({ type: 'SET_MODAL', payload: { contactAgentModal: true }});
        } else {
          handleLoad();
        }
      }}
    >
      {btnText}
    </button>
  );
}

export default EnrollmentLoaderButton;
