import { refreshSession, checkIfLoggedIn } from '../actions/session';
import { logoutShopper } from '../actions/shopper';
import { store } from '../store';

const actionsToIgnore = [
  'persist/PERSIST', 
  'persist/REHYDRATE',
 
  'SET_TOKEN_CREATED',
  'SET_TOKEN_LIFESPAN',
  'CLEAR_SESSION',
];
let tokenIsUpdating = false;

// Function that fires on every redux action.
export default () => (next) => (action) => {
   if (actionsToIgnore.indexOf(action.type) === -1) {
     const currentState = store.getState();
     const currentTime = new Date();

     const { created, lifespan } = currentState.session;
     const { autoRefreshTokenMinutes } = currentState.theme;
     const secondsSinceAuth = (currentTime - new Date(created)) / 1000;

     // User's token is about to expire but they are active, firing redux actions
     const inRefreshWindow = (secondsSinceAuth < lifespan) && (secondsSinceAuth >= (lifespan - (autoRefreshTokenMinutes * 60)));
     if (inRefreshWindow && !tokenIsUpdating) {
       tokenIsUpdating = true;
       refreshSession()
         .then(() => {
           tokenIsUpdating = false;
         });
     } else if (created && (secondsSinceAuth > lifespan)) {
       // If the user somehow didn't have their SessionRefreshModal timeout set, we want this safeguard
       // Potentially occurs on hot reloads - maybe other opportunities?
       store.dispatch({ type: 'CLEAR_SESSION' });
       localStorage.removeItem('SessionToken');
       window.location = '/login';
     }
   }
  return next(action);
}
