import React from 'react';
import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';

import OtherCoveragePage from './OtherCoverage';
import MedicarePage from './Medicare';
import PlanPage from './Plan';
import OSBPage from './OSB';
import PCPPage from './PCP';

function Medicare ({ pageTitle }) {
  return (
    <Routes>
      <Route path="medicare" element={<MedicarePage pageTitle={pageTitle} />} />
      <Route path="other-coverage" element={<OtherCoveragePage pageTitle={pageTitle} />} />
      <Route path="plan" element={<PlanPage pageTitle={pageTitle} />} />
      <Route path="osb" element={<OSBPage pageTitle={pageTitle} />} />
      <Route path="pcp" element={<PCPPage pageTitle={pageTitle} />} />
    </Routes>
  );  
}

export default Medicare;
