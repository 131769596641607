import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { YesNo, TextInput } from '../../components/inputs';
import { Collapse } from '../../../../common/components';

import '../../styles.scss';

function Emergency ({ pageTitle }) {
  const { hasemer } = useSelector(store => store.evalues);

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <FormRow>
          <YesNo
            label="Would you like to provide an emergency contact?"
            name="hasemer"
          />
        </FormRow>
        <Collapse indent isOpen={hasemer}>
          <FormRow>
            <TextInput
              label="First Name"
              name="emerfname"
            />
          </FormRow>
          <FormRow>
            <TextInput
              label="Last Name"
              name="emerlname"
            />
          </FormRow>
          <FormRow>
            <TextInput
              label="Phone number"
              name="emertel"
            />
          </FormRow>
          <FormRow>
            <TextInput
              label="Relationship to you"
              name="emerrel"
            />
          </FormRow>
        </Collapse>
      </div>
    </div>
  );  
}

export default Emergency;
