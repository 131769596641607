import React from 'react';

import { Sidebar } from '../nav';

import '../../styles.scss';

function TitleRow ({ children, className, id, style }) {
  return (
    <div
      className={['col title-row', className].join(' ')}
      id={id}
      style={style}
    >
      <h4 className="fw-bold">
        <Sidebar type="mobile" />
        {children}
      </h4>
    </div>
  );  
}

export default TitleRow;
