import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// Components
import Topbar from '../../components/Topbar';

// Design ticket: https://mymedicarebot.atlassian.net/browse/MYR-4414
function ContactFlow() {
  const navigate = useNavigate();
  const agent = useSelector(store => store.agent);

  return (
    <>
      <Topbar />
      <div className='mmb-container'>
        <div className='bg-white w-100 d-flex h-100 align-items-center flex-column'>
          <div
            className="mt-3 ms-auto me-3 fs-2 text-secondary"
            onClick={() => { navigate('/plan'); }}
          >
            <FontAwesomeIcon
              icon={solid('times')}
              className="h-link p-2"
            />
          </div>
          <div className='d-flex flex-column align-items-center text-center m-2' style={{ maxWidth: '600px' }}>
            <div>
              <span className="fw-bold me-1 text-capitalize">
                {agent.firstName} {agent.lastName}
              </span>
              <span>is a licensed Medicare agent for {agent.agency || 'YourHealthPlan '}
              and can help you enroll in a plan or answer your questions about Medicare.</span>
            </div>
            <button
              className="btn btn-primary fw-bold mt-4 mb-4 ps-5 pe-5"
              onClick={() => { console.log('foo'); }}
              style={{ whiteSpace: 'pre' }}
            >
              Request help to enroll
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactFlow;
