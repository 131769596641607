const initialState = {
  created: null,
  lifespan: 0,

  fingerprint: null,

  // Client versioning
  latestVersion: null,
  version: null,
  lastDismissed: null, // tbd in theming
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return { ...state, created: null, lifespan: 0 };
    case 'SET_TOKEN_CREATED':
      return { ...state, created: action.payload };
    case 'SET_TOKEN_LIFESPAN':
      return { ...state, lifespan: action.payload };
    case 'SET_FINGERPRINT':
      return { ...state, fingerprint: action.payload };
    case 'UPDATE_LATEST_VERSION':
      return { ...state, latestVersion: action.payload };
    case 'UPDATE_LOCAL_VERSION':
      return { ...state, version: state.latestVersion };
    default:
      return state;
  }
}
