import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// Components
import Topbar from '../../../components//Topbar';
import PlanCard from './PlanCard';

// Design ticket: https://mymedicarebot.atlassian.net/browse/MYR-4768
import './PlanList.scss';
function PlanList(props) {
  const dispatch = useDispatch();

  // Store
  const shopper = useSelector(store => store.shopper);
  const { plans, planType, planListScroll } = useSelector(store => store.plan);
  
  // Dropdown states & references
  const [ typePanel, toggleTypePanel ] = useState(false);
  const [ tRef, setTRef ] = useState();
  const handleClick = (event) => {
    // close open dropdowns when user clicks outside of them
    if (!typePanel) return;
    if (!tRef?.contains(event.target)) {
      toggleTypePanel(false);
    }
  }

  // NOTE(jentgelmeier): I removed mmb-common-js import so this getPlan needs to be rewritten to make that api call
  function getPlans(shopper) {
    return [];
  }

  // Load-in
  const [ plansLoading, setPlansLoading ] = useState();
  useEffect(() => {
    if (!plans && !plansLoading) {
      setPlansLoading(true);
      getPlans(shopper)
        .then(plans => {
          dispatch({ type: 'SET_PLANS', payload: plans });
        }).catch(err => {
          console.log('plans/list getPlans err: ', err);
        }).finally(() => {
          setPlansLoading(false);
        });
    }
    document.addEventListener('mousedown', handleClick);
    return function() {
      document.removeEventListener('mousedown', handleClick);
    }
  });

  const handleScroll = (e) => {
    // save user's location in the list, so they can look at a PlanDetails, click
    // back and return to the same position in the list
  }

  return (
    <>
      <Topbar showAgent />
      <div className='mmb-container show-agent bg-gray-200'>
        <div className='bg-white w-100 d-flex align-items-center flex-column shadow-sm'>
          <div
            className={`position-relative h-text m-4 mb-0 ${typePanel ? 'bg-gray-200' : ''}`}
            style={{ whiteSpace: 'nowrap' }}
            ref={setTRef}
            onClick={(e) => {
              if (typePanel && tRef.contains(e.target) && e.target.id !== 'type-panel-toggle') {
                // if  panel is open and user clicks inside it, do not close
                // but if user clicks header, close it
                return;
              }
              toggleTypePanel(!typePanel);
            }}
          >
            <span className='ms-2 me-2 text-serif fw-bold fs-2' id='type-panel-toggle'>
              {
                planType === 'MAPD'
                  ? 'Medicare Advantage Plans'
                  : 'Prescription Drug Plans'
              }
              <FontAwesomeIcon className='fs-4 mb-1 ms-2' icon={solid('caret-down')} />
            </span>
            <div
              className={`type-panel ${typePanel ? 'open' : ''} fs-4 shadow-sm`}
              onClick={(e) => {
                if (!e.target.id) return;
                dispatch({ type: 'SET_PLAN_FIELD', payload: { planType: e.target.id }});
                toggleTypePanel(false);
              }}
            >
              <span id='MAPD' className='h-link p-2 m-2 mb-0'>
                Medicare Advantage Plans
              </span>
              <span id='PDP' className='h-link p-2 m-2 mt-0'>
                Prescription Drug Plans
              </span>
            </div>
          </div>
          <div className='text-center m-3' style={{ maxWidth: '600px' }}>
            Click on a plan to learn more about its coverage and benefits, or click the
            "Enroll now" button to begin your online enrollment application.
          </div>
        </div>
        <div className='d-flex flex-column align-items-center w-100'>
          {plans.filter(p => planType === p.planType).map(plan => <PlanCard plan={plan} />)}
        </div>
      </div>
    </>
  );
}

export default PlanList;
