import React from 'react';
import { useSelector } from 'react-redux';

import {
  getFieldKey,
  fieldExistsInConfig,
} from '../../../../utils/enroll';

import FormLabel from './FormLabel';

function TextBlock({
  cols = '12',
  className,
  name,
}) {

  const {
    enrollment: { form },
  } = useSelector(store => store);
  const customLabel = getFieldKey(form, name, 'label');
  const defaultLabel = useSelector(store => store.enrollment.labels[name]);

  // if field doesn't exist, don't show it.
  if (!fieldExistsInConfig(form, name)) return null;

  return (
    <div className={`col-md-${cols} ${className}`}>
      <p> 
        <FormLabel name={name} label={customLabel || defaultLabel} />
      </p>
    </div>
  );  
}

export default TextBlock;
