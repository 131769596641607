import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { DatePicker, TextInput } from '../../components/inputs';

import '../../styles.scss';

function Coverage ({ pageTitle }) {
  const rowClasses = 'input-row';

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <FormRow>
          Please provide the following information from your red, white,
          and blue Medicare card.
        </FormRow>
        <FormRow className="fs-5 text-muted mb-1">
          Your Medicare card should look like this:
        </FormRow>
        <FormRow>
          <img className="shadow-sm" src="/img/medicare-card.jpg" style={{ width: '400px' }} />
        </FormRow>
        <FormRow>
          <TextInput
            label="Medicare number"
            name="medicare_id"
          />
        </FormRow>
        <FormRow>
          <DatePicker
            label="Hospital (Part A) effective date"
            name="part_a_effective_date"
          />
        </FormRow>
        <FormRow>
          <DatePicker
            label="Medical (Part B) effective date"
            name="part_b_effective_date"
          />
        </FormRow>
      </div>
    </div>
  );  
}

export default Coverage;
