import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { prettifyPartialPhone } from '../common/utils/prettify';

import { DisclaimerFooter } from './';

const ContactAgentModal = () => {
  const dispatch = useDispatch();

  const { contactAgentModal } = useSelector(store => store.modals);
  const { firstName, lastName, mobilePhone, homePhone, email } = useSelector(store => store.agent);
  const [ opacity, setOpacity ] = useState(0);

  useEffect(() => {
    if (contactAgentModal) { setOpacity(1); }
  }, [ contactAgentModal ]);


  return contactAgentModal ? (
    <div
      className="d-flex position-absolute h-100 w-100 justify-content-center fade"
      style={{ top: 0, zIndex: 1000, background: 'rgba(0, 0, 0, 0.7)', opacity }}
    >
      <div
        className="d-flex flex-column bg-white p-5 pt-4 me-0 ms-auto justify-content-center align-items-center text-center"
        style={{ maxWidth: '600px', height: '100vh' }}
      >
        <button
          className="bg-white h-link d-flex text-secondary ms-auto me-0"
          style={{ border: '1px solid white' }}
          tabIndex={0}
          type="button"
          onClick={() => {
            setOpacity(0);
            setTimeout(() => {
              dispatch({ type: 'SET_MODAL', payload: { contactAgentModal: false }});
            }, 100);
          }}
        >
          <FontAwesomeIcon className="fs-1 p-1" icon={solid('times')} />
        </button>

        <div className="mt-2 fs-3">
          To enroll in this plan, please contact your agent
          <span className="text-capitalize fw-bold"> {firstName} {lastName}</span>
          {mobilePhone || homePhone ? (
            <>
              <span className="ms-1">by phone at</span>
              <span
                className="fw-bold ms-1 me-1"
              >
                {prettifyPartialPhone(mobilePhone || homePhone)}
              </span>
            </>
          ) : null}
          {email ? (
            <>
              {mobilePhone || homePhone ? 'or' : ''}
              <span className="ms-1">by email at</span>
              <span
                className="fw-bold ms-1"
              >
                {email}
              </span>
            </>
          ) : null}.
        </div>
        <DisclaimerFooter className="mb-2 mt-auto" />     
      </div>
    </div>
  ) : null;
}
export default ContactAgentModal;
