import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function ResumeAlert () {
  const dispatch = useDispatch();
  const { email } = useSelector(store => store.shopper);
  const { resumeEnrollmentEmail } = useSelector(store => store.alerts);

  return resumeEnrollmentEmail ? (
    <div role="alert" className="alert alert-warning">
      <div className="float-end" style={{ marginTop: '-.3rem', marginRight: '-.3rem' }}>
        <FontAwesomeIcon
          size="lg"
          icon={solid('xmark')}
          role="button"
          tabIndex={0}
          onClick={() => dispatch({ type: 'SET_ALERT', payload: { resumeEnrollmentEmail: false } })}
        />
      </div>
      <div className="row">
        <div className="d-flex col-md-1">
          <FontAwesomeIcon size="xl" icon={solid('envelope')} />
        </div>
        <div className="d-flex col-md-11">
          Need to finish later? We sent an email to {email} with
          a link to this application, so you can easily pick up
          where you left off.
        </div>
      </div>
    </div>
  ) : null;
}

export default ResumeAlert;
