import React from 'react';
import { Spinner } from './index';

function LoadingOverlay({ message }) {
  return (
    <div
      style={{
        position: 'fixed',
        overflowY: 'auto',
        width: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: '9999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.5s ease-out',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: '10px',
          boxShadow: '0px 0px 5px 5px white',
          opacity: '0.90',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <span className="fw-bold fs-4 me-2">
          {message ? message : 'Loading...'}
        </span>
        <Spinner pixelSize="20" />
      </div>
    </div>
  );
}

export default LoadingOverlay;
