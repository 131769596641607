import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, light } from '@fortawesome/fontawesome-svg-core/import.macro';

// Components
import { LoaderButton } from '../../../common/components';

// Util
import { toCurrency } from '../../../common/utils/prettify';

// Design ticket: https://mymedicarebot.atlassian.net/browse/MYR-4422
//                https://mymedicarebot.atlassian.net/browse/MYR-4768
import './PlanCard.scss';
function PlanCard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { plan } = props;
  const shopper = useSelector(store => store.shopper);

  const [ enrollLoading, setEnrollLoading ] = useState();
  const [ enrollErrors, setEnrollErrors ] = useState();

  const handleEnroll = (e) => {
    setEnrollLoading(true);
    navigate('/signup');
    setEnrollLoading(false);   
  }

  const benefits = plan.extraBenefits ? plan.extraBenefits.map(b => {
    const hasBenefit = b.BenefitValue === 'Yes';
    return (
      <span className='me-3 d-flex fs-5' key={b.BenefitName}>
        <FontAwesomeIcon
          icon={hasBenefit ? solid('check') : solid('times')}
          style={{ height: '18px' }}
          className={`${hasBenefit ? 'text-blue' : 'text-danger'} me-2`}
        />
        {b.BenefitName}
      </span>
    )
  }) : [];

  return (
    <div className='quote-card d-flex flex-column align-items-center mb-5' key={plan.planID}>
      <div
        className='d-flex flex-column bg-white shadow-sm border m-4'
        style={{
          maxWidth: 'min(75vw, 600px)',
          minWidth: 'min(75vw, 600px)',
        }}
      >
        <div className="d-flex m-4">
          <span
            className="d-flex flex-column p-2 h-link w-100"
            onClick={() => {
              // const readableName = plan.planName.replaceAll(' ', '-');
              // const queryString = `name=${readableName}&id=${plan.planID}`;
              // navigate(encodeURI(`/plan?${queryString}`));

              dispatch({ type: 'SET_SELECTED_PLAN', payload: plan.planID });
              navigate('/plan');
            }}
          >
            {plan.carrier}
            <span className="fs-4 mt-0 fw-bold text-blue text-decoration-underline">{plan.planName}</span>  
            <span className="text-muted fs-6">View details</span>
            <span className="d-flex font-monospace fw-bolder">
              {plan.starRating} / 5

            </span>
          </span>

          <span className="ms-auto me-0 ps-3">
            <FontAwesomeIcon
              className="fs-3 plan-save-button"
              icon={light('heart')}
            />
          </span>
        </div>
        <div className="d-flex flex-row border-grey border-top w-100">
          <div className="d-flex flex-column w-50 border-grey border-end p-4">
            <div className="d-flex flex-column p-2" style={{ lineHeight: '32px' }}>
              <span className="fs-2 fw-bold mb-0">{toCurrency(`${plan.monthlyPremium}`)}</span>
              <span className="">Monthly premium</span>
            </div>
            {plan.deductible
              ? (
                <div className="d-flex flex-column p-2">
                  <span className="fw-bold">{toCurrency(`${plan.deductible}`)}</span>
                  <span>Deductible</span>
                </div>
              )
              : null
            }
            <div className="d-flex flex-column p-2">
              <span className="fw-bold">{toCurrency(`${plan?.plan?.EstimatedAnnualRXOOPC}`)}</span>
              <span>Annual maximum out-of-pocket</span>
            </div>
            {
              false || shopper.hasDrugs
                ? (
                  <div className="d-flex flex-column p-2">
                    <span className="fw-bold">{toCurrency(`${plan.plan?.RetailEstimatedAnnualDrugCost}`)}</span>
                    <span>Estimated annual out-of-pocket costs</span>
                  </div>
                ) :
                (
                  <div className="d-flex flex-column p-2 h-link">
                    <span className="fw-bold text-blue text-decoration-underline">
                      Add prescription drugs
                    </span>
                    <span className="text-muted fst-italic">Estimated annual out-of-pocket costs</span>
                  </div>
                )
            }
          </div>
          <div className="d-flex flex-column w-50 p-4">
            {
              false || shopper.hasDoctors
                ? (
                  <div className="d-flex flex-column p-2">
                    <span>1 of 2</span>
                    <span>of your doctors covered</span>
                  </div>
                ) :
                (
                  <div className="d-flex flex-column p-2 h-link">
                    <span className="fw-bold text-blue text-decoration-underline">
                      Add doctors
                    </span>
                    <span className="text-muted fst-italic">of your doctors covered</span>
                  </div>
                )
            }
            {
              false || shopper.hasDrugs
                ? (
                  <div className="d-flex flex-column p-2 h-link">
                    <span>{plan.drugsCovered} of {999}</span>
                    <span className="text-muted fst-italic">of your prescription drugs covered</span>
                  </div>
                ) :
                (
                  <div className="d-flex flex-column p-2 h-link">
                    <span className="fw-bold text-blue text-decoration-underline">
                      Add prescription drugs
                    </span>
                    <span className="text-muted fst-italic">of your prescription drugs covered</span>
                  </div>
                )
            }
          </div>

        </div>

        {plan.extraBenefits
          ? (
            <>
              <span className="text-muted mt-3">Coverage includes:</span>
              <div className="mt-1 w-100 d-flex flex-wrap" style={{ lineHeight: '1.25rem' }}>
                {benefits}
              </div>
            </>
          ) : null
        }
      </div>
      <div className="mt-3 mb-auto">
        <LoaderButton
          text="Enroll now"
          loading={enrollLoading}
          loadingText="Loading.."
          className="btn btn-primary fw-bold mt-2 w-100 quote-enroll-button"
          onClick={handleEnroll}
          errors={enrollErrors}
        />
      </div>
    </div>
  );
}

export default PlanCard;
