import React, { useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HTMLReactParser from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, light } from '@fortawesome/fontawesome-svg-core/import.macro';

// Components
import {
  Topbar,
  DisclaimerFooter,
  EnrollmentLoaderButton,
  LoadingOverlay,
  PlanCoverage,
}  from '../../../components';
import BenefitDropdown from '../details/BenefitDropdown';
import BenefitComparison from './BenefitComparison';

// Utils
import { formatPlanBenefitDetails, formatDocuments } from '../../../utils/plan-utils/';
import { toCurrency } from '../../../common/utils/prettify';

// Design ticket: https://mymedicarebot.atlassian.net/browse/MYR-4767
function PlanComparison() {
  // todo(@joeysapp): Improve nested plan benefits / move individual table elemnts to separate functions
  // todo(@joeysapp): Implement decent "highlight plan differences" feature 
  const planDifferencesFeature = false;

  const navigate = useNavigate();

  const [highlight, setHighlight] = useState(false);
  const [errs, setErrs] = useState();
  const [loading, setLoading] = useState();

  const { selectedPlanQuote } = useSelector(store => store.plan);
  const plan = useSelector(store => store.plan.snapshotPlans[selectedPlanQuote.quoteID]);
  const [coverage, setCoverage] = useState([]);
  const [benefits, setBenefits] = useState({});
  const [documents, setDocuments] = useState({});

  const currentPlan = useSelector(store => store.plan.snapshotPlans['currentPlan']);
  const [currentCoverage, setCurrentCoverage] = useState([]);
  const [currentBenefits, setCurrentBenefits] = useState({});
  const [currentDocuments, setCurrentDocuments] = useState({});

  useLayoutEffect(() => {
    setBenefits(formatPlanBenefitDetails(plan));
    setCurrentBenefits(formatPlanBenefitDetails(currentPlan));

    setDocuments(formatDocuments({ plan }));
    setCurrentDocuments(formatDocuments({ plan: currentPlan }));
  }, []);
  
  useLayoutEffect(() => {
    if (!plan || !plan.plan) return;
    const benefits = [
      { label: 'Rx Coverage', value: plan.plan.HasRXCoverage },
      { label: 'Mail Order Pharmacy', value: plan.plan.ContainMailOrder },
    ];
    if (plan.plan.PlanYear === '2023') {
      benefits.push({ label: 'Insulin Savings Program', value: plan.plan.InsulinSavingsProgram });
    }
    const eBenefits = (plan.plan.ExtraBenefits && plan.planType !== 'PDP')
      ? plan.plan.ExtraBenefits.map(b => {
        return {
          label: b.BenefitName,
          value: b.BenefitValue === 'Yes',
        };
      })
      : [];
    setCoverage([ ...benefits, ...eBenefits ]);
  }, [plan]);

  useLayoutEffect(() => {
    if (!currentPlan || !currentPlan.plan) return;
    const benefits = [
      { label: 'Rx Coverage', value: plan.plan.HasRXCoverage },
      { label: 'Mail Order Pharmacy', value: plan.plan.ContainMailOrder },
    ];
    if (plan.plan.PlanYear === '2023') {
      benefits.push({ label: 'Insulin Savings Program', value: plan.plan.InsulinSavingsProgram });
    }
    const eBenefits = (currentPlan.plan.ExtraBenefits && currentPlan.planType !== 'PDP')
      ? currentPlan.plan.ExtraBenefits.map(b => {
        return {
          label: b.BenefitName,
          value: b.BenefitValue === 'Yes',
        };
      })
      : [];
    setCurrentCoverage([ ...benefits, ...eBenefits ]);
  }, [currentPlan]);

  return (
    <>
      {loading && <LoadingOverlay />}
      <Topbar showAgent />
      <div className='mmb-container show-agent bg-gray-200'>
        <div className='bg-white w-100 d-flex align-items-center flex-column shadow-sm pt-4'>
            {/* navigate back to quotes/plans */}
            <div className="d-flex flex-column w-100 justify-content-center align-items-center">
              <div className="d-flex justify-content-center text-link">
                <span
                  className="h-link p-2 rounded"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate('/quotes');
                  }}
                >
                  <FontAwesomeIcon icon={solid('arrow-left')} className="me-2" />
                  <span className="fw-bold text-decoration-underline">Back to quotes</span>
                </span>
              </div>
            </div>
              <div className='text-serif text-center fw-bold fs-1 m-2'>
                Compare Plans
              </div>
              {planDifferencesFeature
                 ? (<div className="m-3">
                      <span
                        className={`h-link p-2 rounded ${highlight ? 'bg-success-light' : ''}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setHighlight(!highlight);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={highlight ? solid('square-check') : light('square')}
                          className={`me-2 ${highlight ? 'text-success' : ''}`}
                        />
                        Highlight plan differences
                      </span>
                    </div>
                   ) : null}
        </div>
        <div className='bg-white w-100 d-flex align-items-center flex-column shadow-sm sticky-top' style={{ zIndex: '1' }}>
          <div className="d-flex w-100">
                <div className="border border-success bg-success-light border-end-0 w-100"/>
                <div className="card d-flex flex-row m-0 border-0" style={{ minWidth: '700px' }}>
                  <div
                    className="w-50 border border-success bg-success-light d-flex flex-column justify-content-center align-items-center text-center p-3 border-end-0"
                  >
                    <div className="bg-secondary text-white p-3 pt-2 pb-2 fw-bold">
                      Current plan
                    </div>
                    <div className="mt-2 fw-bold">
                      {currentPlan?.planName}
                    </div>
                  </div>
                  <div
                    className="w-50 border border-success bg-success-light d-flex flex-column justify-content-center align-items-center text-center p-3 border-end-0"
                  >
                    {!errs ? <EnrollmentLoaderButton plan={plan} quote={selectedPlanQuote} /> : null}
                    <div className="mt-2 fw-bold">
                      {plan?.planName}
                    </div>
                  </div>
                </div>
                <div
                  className="border border-success bg-success-light w-100"
                />
              </div>
        </div>
        {/* Content below white header */}
        {/* Plan benefits/details */}
        <div className={`d-flex card flex-column bg-white shadow-sm ${errs || loading ? 'd-none' : ''}`}>
          <div className="d-flex flex-column align-items-center">

            <div className="fw-bold border-bottom w-100 text-center p-3">
              Monthly premium
            </div>
            <div className="d-flex w-100 border-bottom">
              <span className={`w-50 p-3 ms-2 text-start border-end`}>
                <span className={`p-2 rounded ${highlight && currentPlan?.monthlyPremium < plan?.monthlyPremium ? 'bg-success-light' : ''}`}>
                  {toCurrency(currentPlan?.monthlyPremium?.toString()) || '--'}
                </span>
              </span>
              <span className="w-50 p-3 ms-2 text-start">
                <span className={`p-2 rounded ${highlight && currentPlan?.monthlyPremium > plan?.monthlyPremium ? 'bg-success-light' : ''}`}>
                  {toCurrency(plan?.monthlyPremium?.toString()) || '--'}
                </span>
              </span>
            </div>


             <div className="fw-bold border-bottom w-100 text-center p-3">
               Annual medical deductible
             </div>
             <div className="d-flex w-100 border-bottom">
               <span className="w-50 p-3 ms-2 text-start border-end">
                 {currentBenefits && currentBenefits['Deductibles']
                   ? (<div>
                       {HTMLReactParser(currentBenefits['Deductibles']['Health deductible'].text)}
                      </div>)
                   : <>Prescription Drug Deductible: {toCurrency(currentPlan?.deductible?.toString())}</>
                 }
               </span>
               <span className="w-50 p-3 ms-2 text-start">
                 {benefits && benefits['Deductibles']
                   ? (<div>
                       {HTMLReactParser(benefits['Deductibles']['Health deductible'].text)}
                      </div>)
                   : <>Prescription Drug Deductible: {toCurrency(plan?.deductible?.toString())}</>
                 }
               </span>
             </div>

             <div className="fw-bold border-bottom w-100 text-center p-3">
               Out-of-pocket-maximum
             </div>
             <div className="d-flex w-100 border-bottom">
               <span className="w-50 p-3 ms-2 text-start border-end">
                 {currentBenefits['Maximum Out-of-Pocket']
                    ? (<div>
                         {HTMLReactParser(currentBenefits['Maximum Out-of-Pocket']['Maximum you pay for health services'].text)}
                       </div>)
                    : '--'
                 }
               </span>
               <span className="w-50 p-3 ms-2 text-start">
                 {benefits['Maximum Out-of-Pocket']
                    ? (<div>
                         {HTMLReactParser(benefits['Maximum Out-of-Pocket']['Maximum you pay for health services'].text)}
                       </div>)
                    : '--'
                 }
               </span>
             </div>

            <div className="fw-bold border-bottom w-100 text-center p-3">
              Coverage
            </div>
            <div className="d-flex w-100 border-bottom">
              <span className="w-50 p-3 ms-2 text-start border-end">
                {currentCoverage
                   ? (
                     <div className="w-75">
                       <PlanCoverage coverage={currentCoverage} />
                     </div>
                   )
                   : '--'
                }
              </span>
              <span className="w-50 p-3 ms-2 text-start">
                {coverage
                   ? (
                     <div className="w-75">
                       <PlanCoverage coverage={coverage} />
                     </div>
                   )
                   : '--'
                }
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex card mb-5 bg-gray-200 border-0">
          <BenefitComparison
            benefits={[currentBenefits, benefits]}
          />
          <div className="d-flex mt-3 bg-white">
            <BenefitDropdown
              label="Plan Documents"
              currentBenefits={currentDocuments}
              benefits={documents}
            />
          </div>
        </div>
        <div className="pt-3 pb-5 mb-0 mt-auto">
          <DisclaimerFooter />
        </div>
      </div>
    </>
  );
}

export default PlanComparison;
