import * as moment from 'moment';

export function prettyPhoneDisplay(phoneNumberString) {
  const cleaned = (`${phoneNumberString}`)
    .replace(/^\+1/, '')
    .replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return ` (${match[1]}) ${match[2]}-${match[3]}`;
  }
  return cleaned;
}

export function stripPhone(phoneNumberString) {
  const cleaned = (`${phoneNumberString}`)
    .replace(/-/g, '')
    .replace(/\s/g, '');
  return cleaned;
}

export function cleanDate(dateString, format = 'MMDDYYYY') {
  const d = moment(dateString);
  if (d.isValid()) {
    return d.format(format);
  }
  return '';
}
