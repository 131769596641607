import React from 'react';
import { Routes, Route } from 'react-router-dom';

// pages
import PersonalInfoPage from './PersonalInfo';
import AddressPage from './Address';
import ContactPage from './Contact';
import EmergencyPage from './Emergency';
import CSNPPage from './CSNP';

function Contact ({ pageTitle }) {
  return (
    <Routes>
      <Route path="personal" element={<PersonalInfoPage pageTitle={pageTitle} />} />
      <Route path="contact" element={<ContactPage pageTitle={pageTitle} />} />
      <Route path="address" element={<AddressPage pageTitle={pageTitle} />} />
      <Route path="csnp" element={<CSNPPage pageTitle={pageTitle} />} />
    </Routes>
  );  
}

export default Contact;
