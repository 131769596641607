const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_EVALUES_FIELD':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'SET_EVALUES_FIELDS':
      return {
        ...state,
        ...action.fields,
      };
    case 'LOAD_ENROLLMENT_VALUES':
      return { ...state, ...action.payload };
    case 'CLEAR_ENROLLMENT_VALUES':
      return initialState;
    case 'CLEAR_ENROLLMENT_FORM':
      return initialState;
    default:
      return state;
  }
}
