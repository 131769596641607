import React from 'react';
import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';

import ElectionPeriodPage from './ElectionPeriod';
import OptionalPage from './Optional';
import MiscellaneousPage from './Miscellaneous';
import MailOrderPage from './MailOrder';

function Eligibility ({ pageTitle }) {
  return (
    <Routes>
      <Route path="election" element={<ElectionPeriodPage pageTitle={pageTitle} />} />
      <Route path="optional" element={<OptionalPage pageTitle={pageTitle} />} />
      <Route path="misc" element={<MiscellaneousPage pageTitle={pageTitle} />} />
      <Route path="mailorder" element={<MailOrderPage pageTitle={pageTitle} />} />
    </Routes>
  );  
}

export default Eligibility;
