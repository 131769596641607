import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { Radio, TextInput, YesNo, Select } from '../../components/inputs';

import { getField } from '../../../../utils/enroll';

import PaymentBox from '../../components/PaymentBox';

import '../../styles.scss';

function Payment ({ pageTitle }) {
  const rowClasses = 'input-row';
  const { paymeth } = useSelector(store => store.evalues);
  const { form } = useSelector(store => store.enrollment);

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <FormRow>
          <PaymentBox />
        </FormRow>
        <FormRow>
          You may pay your monthly plan premium and/or late enrollment penalty that you currently have or may owe by electronic funds transfer (EFT), automatic deduction from your Social Security Administration (SSA) or Railroad Retirement Board (RRB) benefit check. You may also choose to pay by mail using a coupon book. If you do not select a payment option below you may be defaulted to coupon book.
        </FormRow>
        <FormRow>
          If you have to pay a Part D-Income Related Monthly Adjustment Amount (Part D-IRMAA), you must pay this extra amount in addition to your plan premium. DON’T PAY THE PLAN the Part D-IRMAA.
        </FormRow>
        <FormRow>
          <Radio
            label="Select a payment method"
            name="paymeth"
            boldLabel
            unsetFieldsOnCondition={{
              condition: (paymeth !== 'eft'),
              fieldsToUnset: [
                'eft_acttype',
                'eft_bankname',
                'eft_actnum',
                'eft_routing',
                'eft_actname',
                'eft_sigdiff',
              ],
            }}
          >
            {(paymeth === 'eft') && (
              <FormRow>
                {getField(form, 'eft_acttype') && (
                  <FormRow>
                    <Select
                      label="Bank account type"
                      name="eft_acttype"
                    />
                  </FormRow>
                )}
                {getField(form, 'eft_bankname') && (
                  <FormRow>
                    <TextInput
                      label="Bank name"
                      name="eft_bankname"
                    />
                  </FormRow>
                )}
                {getField(form, 'eft_actnum') && (
                  <FormRow>
                    <TextInput
                      label="Account number"
                      name="eft_actnum"
                    />
                  </FormRow>
                )}
                {getField(form, 'eft_routing') && (
                  <FormRow>
                    <TextInput
                      label="Routing number"
                      name="eft_routing"
                    />
                  </FormRow>
                )}
                {getField(form, 'eft_actname') && (
                  <FormRow>
                    <TextInput
                      label="Account holder name"
                      name="eft_actname"
                    />
                  </FormRow>
                )}
                {getField(form, 'eft_sigdiff') && (
                  <FormRow>
                    <YesNo
                      label="Is signature different from owner?"
                      name="eft_sigdiff"
                    />
                  </FormRow>
                )}
              </FormRow>
            )}
          </Radio>
        </FormRow>
      </div>
    </div>
  );  
}

export default Payment;
