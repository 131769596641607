import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { isRequired } from '../../../../utils/enroll';
import { useOutsideClick } from '../../../../hooks';
import { Collapse } from '../../../../common/components';

import '../../styles.scss';

function isComplete({ pages, fields, validFields, currentValues, form }) {
  let sectionComplete = true;
  let sectionFields = [];
  if (fields) {
    sectionFields = fields;
  } else if (pages) {
    pages.forEach(page => page.fields.forEach(field => sectionFields.push(field)));
  }
  sectionFields.forEach(field => {
    const required = isRequired({ form, name: field.name, currentValues });
    const validState = validFields[field.name];
    if (required && !validState) sectionComplete = false;
    if (!required && (validState === false)) sectionComplete = false;
  });
  return sectionComplete;
}

function IndicatorIcon({ sectionComplete, className }) {
  const { touchedSubmitPage } = useSelector(store => store.enrollment);
  const SUCCESS = 'var(--bs-success)';
  const GRAY = 'var(--bs-gray-400)';
  const RED = 'var(--bs-danger)';

  let iconColor = GRAY;
  let icon = solid('check');

  if (sectionComplete) {
    iconColor = SUCCESS;
  } else if (touchedSubmitPage) {
    iconColor = RED;
    icon = solid('xmark');
  }

  return (
    <FontAwesomeIcon
      color={iconColor}
      icon={icon}
      className={className}
    />
  );
}

function SavingIndicator() {
  const { isSaving, saveError } = useSelector(store => store.enrollment);

  if (saveError) {
    return <span className="text-danger fw-bold">Error Saving</span>;
  } else if (isSaving) {
    return (
      <span className="fw-bold text-muted">
        <div className="spinner-border spinner-border-sm me-1" role="status" />
        Saving...
      </span>
    );
  }
  return <span className="fw-bold text-muted">Saved</span>
}

function Heading({ children, route, title, pages, firstChildRoute }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = location.pathname.includes(route);

  // are all sub sections complete and valid?
  const validFields = useSelector(store => store.evalid);
  const currentValues = useSelector(store => store.evalues);
  const form = useSelector(store => store.enrollment.form);
  const sectionComplete = isComplete({ pages, validFields, currentValues, form });

  const openIcon = <FontAwesomeIcon icon={solid('chevron-up')} />;
  const closedIcon = <FontAwesomeIcon icon={solid('chevron-down')} />;

  const handleClick = () => {
    setTimeout(() => (
      dispatch({ type: 'SET_DISPLAY_FIELD', payload: { enrollmentSidebarOpen: false } })
    ), 50);
    navigate(firstChildRoute);
  }

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="row row-cols-auto heading mb-1 align-items-center"
        onClick={handleClick}
      >
        <IndicatorIcon sectionComplete={sectionComplete} />
        <div className="fw-bold px-1">
          {title}
        </div>
        {open ? openIcon : closedIcon }
      </div>
      <Collapse isOpen={open}>
        {children}
      </Collapse>
    </>
  );
}

function SubHeading ({ children, fields, route }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // are all fields inputted and valid?
  const validFields = useSelector(store => store.evalid);
  const currentValues = useSelector(store => store.evalues);
  const form = useSelector(store => store.enrollment.form);
  const sectionComplete = isComplete({ fields, validFields, currentValues, form });

  const highlight = location.pathname === route;

  const handleClick = () => {
    setTimeout(() => (
      dispatch({ type: 'SET_DISPLAY_FIELD', payload: { enrollmentSidebarOpen: false } })
    ), 50);
    navigate(route);
  }

  return (
    <div
      className="row row-cols-auto subhead align-items-center"
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      <IndicatorIcon className="ms-4 me-0" sectionComplete={sectionComplete} />
      <div className={['ps-2 py-2', (highlight ? 'subhead-highlight' : '')].join(' ')}>
        {children}
      </div>
    </div>
  );
}

function Sidebar() {
  const FORM = useSelector(store => store.enrollment.form) || [];
  const baseRoute = useSelector(store => store.enrollment.baseRoute) || [];

  return (
    <div className="sticky-md-top sticky-lg-top sticky-xl-top pt-4">
      {FORM.map((section) => {
        const headingRoute = `/enroll${baseRoute}${section.route}`;
        const firstChildRoute = `${headingRoute}${section.pages[0].route}`;
        return (
          <Heading
            key={section.navTitle}
            title={section.navTitle}
            route={headingRoute}
            firstChildRoute={firstChildRoute}
            pages={section.pages}
          >
            {section.pages.map((page) => {
              const subheadingRoute = `${headingRoute}${page.route}`;
              return (
                <SubHeading fields={page.fields} route={subheadingRoute} key={page.navTitle}>
                  {page.navTitle}
                </SubHeading>
              );
            })}
          </Heading>
        );
      })}
    </div>
  );  
}

function MobileSidebar() {
  const dispatch = useDispatch();
  const ref = useRef();
  const { enrollmentSidebarOpen: isOpen } = useSelector(store => store.display);
  useOutsideClick(ref, () => dispatch({ type: 'SET_DISPLAY_FIELD', payload: { enrollmentSidebarOpen: false } }));

  const styles = {
    position: 'fixed',
    backgroundColor: 'white',
    height: '100%',
    width: '90%',
    zIndex: 1,
    boxShadow: '10px -5px 10px grey',
    top: '0px',
    // hide sidebar to the left of screen on mobile
    // so that the transition works
    left: isOpen ? '0px' : '-1000px',
    paddingLeft: '1.5em',
    transition: 'all 0.7s ease-out',
  };

  return (
    <div
      ref={ref}
      style={styles}
    >
      <FontAwesomeIcon
        size="xl"
        icon={solid('xmark')}
        className="float-end mt-2 me-2 text-muted"
        role="button"
        onClick={() => dispatch({ type: 'SET_DISPLAY_FIELD', payload: { enrollmentSidebarOpen: false } })}
      />
      <Sidebar />
      <div style={{ position: 'fixed', bottom: '.3rem' }}>
        <SavingIndicator />
      </div>
    </div>
  );
}

function SidebarCollapse({ type }) {
  const dispatch = useDispatch();

  if (type === 'browser') {
    return (
      <div className="d-none d-md-block">
        <Sidebar />
        <div className="d-flex me-3 fixed-bottom justify-content-end">
          <SavingIndicator />
        </div>
      </div>
    );
  } else if (type === 'mobile') {
    return (
      <div className="d-md-none d-inline me-3">
        <MobileSidebar />
        <FontAwesomeIcon
          size="lg"
          icon={solid('bars')}
          onClick={() => dispatch({ type: 'SET_DISPLAY_FIELD', payload: { enrollmentSidebarOpen: true } })}
          role="button"
        />
      </div>
    );
  }
}

export default SidebarCollapse;
