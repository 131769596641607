import moment from 'moment';
import getAgentID from './getAgentID';
import {
  YESNO,
  YESNONONE,
  YESNONE,
  YESNOBLANK,
  existsOrBlank,
  existsOrNull,
} from './mapping-tools';

const ANTHEM_EXAMPLE_CONFIG = {
  partnerID: 'MYMEDICAREBOT',
  clientID: 'secureauthApp303',
  senderApp: 'MYMCBOT',
};

/* eslint-disable quote-props,max-len */
const ANTHEM_ETHNICITY_MAP = {
  'NA': 'NOHLSP',
  'MX': 'MEXCHI',
  'OTH': 'AHLSOS',
  'PR': 'PUTRIC',
  'CUB': 'CUBANN',
  'NR': 'NOTANS',
};

const ANTHEM_RACE_MAP = {
  '101': 'WHITE',
  '201': 'BLKAFA',
  '300': 'AMIALN',
  '499': 'OTHASN',
  '521': 'GUACHA',
  '401': 'ASNIND',
  '411': 'CHINES',
  '441': 'KOREAN',
  '501': 'NATHAI',
  '599': 'OTHPIS',
  '431': 'JAPANE',
  '421': 'FILIPI',
  '451': 'VIETNA',
  '511': 'SAMOAN',
  '999': 'NOTANS',
};

const SEP_REMAP_TO_OTH = [
  'RET',
  'LT2',
  'NLS',
  '12G',
  '12J',
  '5ST',
  'LPI',
  'REC',
  'ACC',
  'IND',
  'OSD',
];
/* eslint-enable */

export default ({
  agent,
  quote,
  fields: res,
  plan,
  theme,
  confirmationNumber: confirmation, // eslint-disable-line
}) => {
  const {
    useCarrierPerms,
    anthemConfig = ANTHEM_EXAMPLE_CONFIG,
  } = theme;

  const {
    clientID,
    partnerID,
    senderApp,
  } = anthemConfig;

  let agentID;
  let agencyID;
  if (useCarrierPerms) {
    ({ agentID, agencyID } = getAgentID({ agent, quote })); // eslint-disable-line
  } else {
    agentID = agent.npn; // eslint-disable-line
  }

  const { state, effectiveDate } = quote;

  const nowDate = moment().format('YYYY-MM-DD');

  let raceArray = [];
  if (Array.isArray(res.race)) raceArray = res.race.map(r => ANTHEM_RACE_MAP[r]);
  let ethnicityArray = [];
  if (Array.isArray(res.ethnicity)) ethnicityArray = res.ethnicity.map(e => ANTHEM_ETHNICITY_MAP[e]);

  let payMeth = '';
  let payMethSubType = 'NONE';
  if (res.paymeth === 'cb' || !res.paymeth) payMeth = 'BILLING';
  if (res.paymeth === 'eft') payMeth = 'AUTOBANKDRAFT';
  if (['rrb', 'ssi'].includes(res.paymeth)) {
    payMeth = 'SOCSECCHECK';
    if (res.paymeth === 'ssi') payMethSubType = 'SOCIALSECURITY';
    if (res.paymeth === 'rrb') payMethSubType = 'RRB';
  }
  const isSelectNoDrugCovPlan = (quote.planType === 'MA') ? YESNO(quote.planType) : undefined;

  function formatSEP(code) {
    if (res.sep_code) {
      if ((code === 'OTH') && SEP_REMAP_TO_OTH.includes(res.sep_code)) return 'YES';
      if (code === res.sep_code) return 'YES';
      if (code !== res.sep_code) return 'NO';
    }
    return 'NONE';
  }

  function formatSEPOTHDesc() {
    if (res.sep_code === 'OTH') return res.sep_other;
    if (SEP_REMAP_TO_OTH.includes(res.sep_code)) return `${res.sep_code} ${res.sep_date || ''}`;
    return null;
  }

  function formatSEPDate(code) {
    if (res.sep_code === code) return res.sep_date;
    return null;
  }

  // notes are from Anthem_Enrollment_MA_MappingDocument_V4.21_v2.xlsx / setApplication_2023 tab
  const formattedData = {
    setApplicationRequests: [{
      partnerId: partnerID,
      user: {
        userId: clientID,
        shopperRole: 'BROKER',
      },
      application: {
        acn: null, // 'NOT Applicable for external partner'
        brandName: null, // do not pass
        state,
        isAgentAssisted: 'YES',
        isAgentSubmitted: 'YES',
        reqEffDate: effectiveDate,
        emoAppId: confirmation, // TODO I think this is right?
        appSource: senderApp,
        createPartnerId: partnerID,
        submitPartnerId: partnerID,
        applicationOrigin: 'ELECTRONIC',
        caseId: null, // Conditional required...? Pass the Partner Group Name.
        // confirmationNo: null,
        lob: 'SENIOR',
        enrollmentName: plan.PlanName, // TODO I think this is right?
        isWorkingWithAgent: 'YES',
        planMaterialLanguage: res.preflang || null,
        planMaterialFormat: res.altwritten || null,
        accessControlList: [
          {
            accessType: 'OWNER',
            user: {
              userId: partnerID,
              shopperRole: 'BROKER',
            },
          },
        ],
        agent: [
          {
            // agentId: null,
            agentLevel: 'WRITINGAGENT', // TODO can be either 'ParentAgent' or 'WritingAgent'
            agentTIN: agentID,
            firstName: agent.firstName,
            lastName: agent.lastName,
            agencyName: null, // TODO we don't have agency name, is it included in rts?
            phoneNo: agent.mobilePhone,
            emailAddress: agent.email,
            agentType: 'BROKER',
            isAssistApplicant: null,
            soaAppointmentType: 'NONE', // TODO what value should go here? Assume 'NONE' since we don't associate soas with enrollments?
            soaCollectedMethodType: 'NONE',
            voiceVaultConfirmationNo: null,
            dsnpVerificationCode: null,
            action: 'UPDATE',
            eSignature: {
              firstName: agent.firstName,
              mi: null, // not required
              lastName: agent.lastName,
              firstNameConfirm: agent.firstName,
              miConfirm: null, // not required
              lastNameConfirm: agent.lastName,
              state: null, // TODO we don't have agent state
              city: null, // we don't have agent city
              signatureDate: nowDate,
              isAgreeWithAgreement: 'YES',
              isAssistApplicantSubmitApp: 'YES',
              isAgreeToProvideOriginalApp: 'YES',
              action: 'UPDATE',
            },
          }, { // agency info
            agentTIN: existsOrNull(agencyID),
            agentType: 'BROKER',
            agentLevel: 'PARENTAGENT',
            action: 'UPDATE',
          },
        ], // end agent
        applicant: [
          {
            isMailingAddrSameAsHomeAddr: YESNO(res.samemail),
            demographic: {
              lastName: res.last_name,
              firstName: res.first_name,
              mi: res.middle_name,
              dateOfBirth: res.birthdate,
              gender: res.gender.toUpperCase(),
              emailAddress: res.email,
              primaryPhNo: res.home_phone,
              alternatePhNo: null,
              homePhoneNumberType: 'NONE',
              altPhoneNumberType: null,
              relationshipType: 'APPLICANT',
              action: 'UPDATE',
            },
            address: [
              {
                addressLine1: res.street1,
                addressLine2: res.street2,
                city: res.city,
                county: res.county_name,
                countyCode: res.county,
                state: res.state.toUpperCase(),
                postalCode: res.zipcode,
                addressType: 'HOME',
                action: 'UPDATE',
              },
              {
                addressLine1: res.mailstreet1,
                addressLine2: res.mailstreet2,
                city: res.mailcity,
                state: res.mailstate,
                postalCode: res.mailzip,
                addressType: 'MAILING',
                action: 'UPDATE',
              },
            // remove the mailing address if samemail is not set
            ].filter((a) => (res.samemail || a.addressType !== 'MAILING')),
            eSignature: {
              voiceId: 'NONE', // required if soaCollectedMethodType is RECORDEDCALL. we aren't populating soaCollectedMethodType
              signatureDate: nowDate,
              action: 'UPDATE',
            },
            planSelection: {
              action: 'UPDATE',
              plan: [
                {
                  isSelectNoDrugCovPlan,
                  planId: plan.CustomerPlanID, // TODO seriously? 'Refer to the Application Matrix. This is a mandatory parameter.'
                  action: 'UPDATE',
                },
              ],
            },
            isHavePCP: YESNO(res.pcp_first_name),
            provider: {
              pcpNo: res.pcp_num,
              firstName: res.pcp_first_name,
              middleInitial: res.pcp_middle_name || null,
              lastName: res.pcp_last_name,
              isExistingPatient: YESNONONE(res.pcp_established),
              pmgName: res.pcp_pmg,
              action: 'UPDATE',
              address: {
                addressLine1: res.pcp_street1,
                city: res.pcp_city,
                state: res.pcp_state,
                postalCode: res.pcp_zip,
                addressType: 'NONE',
                action: 'UPDATE',
              },
            },
            priorCoverage: [
              {
                isHaveOtherPrescrDrugCov: 'NO',
                otherPrescrDrugCov: {
                  covPolicyNo: res.othercoverage_num,
                  covName: res.othercoverage_name,
                  covGroupNo: res.othercoverage_group,
                  covStartDate: null, // not required
                  covEndDate: null, // not required
                  action: 'UPDATE',
                },
                isCoveredThruStateMedicaid: YESNO(res.onmedicaid),
                medicaidNo: existsOrNull(res.medicaidnum),
                isYouOrYourSpouseWork: YESNO(res.willwork || res.willworkspouse),
                isHaveCOPDHeartDiseaseAndDiabetes: YESNONONE(res.csnp_prequal), // not required
                receiveNursingService: YESNONONE(res.skilled_nursing),
                needContinuityOfCare: YESNONONE(res.continuity_care),
                isPlanTransfer: YESNO(!res.csnp_is_plan_transfer, null),
                enrolleeHealthPlan: (res.csnp_is_plan_transfer || null), // not required
                referToCTMP: null, // not required
                action: 'UPDATE',
              },
            ],
            raceAndOrigin: {
              origin: ethnicityArray,
              race: raceArray,
              action: 'UPDATE',
            },
            // addlServicesContactByText: null, // not required // NOTE(santeyio): removed 09/01/2023
            // addlServicesContactByEmail: null, // not required // NOTE(santeyio): removed 09/01/2023
            optMailOrderPresc: YESNONE(res.mailorder), // not required
            recvEOBByMail: YESNO(res.eobbymail),
            recvMonthlyBillByMail: YESNO(res.billbymail),
            recvPlanDOCByMail: YESNO(res.plandocsbymail),
            isVeteran: YESNOBLANK(res.veteran, 'NOTANS'),
            action: 'UPDATE',
          },
        ], // end applicants
        isApplyingForOthers: YESNO(res.ispoa),
        enrollingPerson: {
          firstName: res.poa_fname,
          lastName: res.poa_lname,
          phoneNo: res.poa_tel,
          relationship: res.poa_rel,
          action: 'UPDATE',
          address: {
            addressLine1: res.poa_addr_street1,
            addressLine2: res.poa_addr_street2,
            city: res.poa_addr_city,
            state: res.poa_addr_state,
            postalCode: res.poa_addr_zip,
            addressType: 'NONE',
            action: 'UPDATE',
          },
        },
        paymentSelection: {
          ongoingPayment: {
            paymentAmt: plan.TotalPlanPremium,
            isAuthorizeMonthlyAcctDed: YESNO(res.paymeth === 'eft'),
            paymentType: payMeth,
            billingFrequency: 'MONTHLY',
            bankAcct: {
              accountNo: res.eft_actnum,
              confirmAcctNo: res.eft_actnum,
              routingNo: res.eft_routing,
              accountType: res.eft_acttype,
              bankName: res.eft_bankname,
              accountHolderName: res.eft_actname,
              action: 'UPDATE',
            },
            rrbSSBPaymentSubType: payMethSubType,
            action: 'UPDATE',
          },
          action: 'UPDATE',
        },
        attestationOfEligibility: {
          isAEP: YESNO(res.enrolltype === 'AEP'),
          isNewToMedicare: formatSEP('NEW'),
          isAge65NotNewToMedicare: YESNO(['IEP', 'ICE'].includes(res.sep_code)),
          isMoveOutsideServArea: formatSEP('MOV'),
          moveDate: formatSEPDate('MOV'),
          isLeftPace: formatSEP('PAC'),
          leftPACEDate: formatSEPDate('PAC'),
          isLongTermCare: formatSEP('LTC'),
          ltcDate: formatSEPDate('LTC'),
          releasedfromIncarceration: formatSEP('INC'),
          releasedfromIncarcerationDate: formatSEPDate('INC'),
          lawfulPresenceStatusInUS: formatSEP('LAW'),
          lawfulPresenceStatusInUSDate: formatSEPDate('LAW'),
          isInvoluntaryLostDrugCov: formatSEP('LCC'),
          lostCovDate: formatSEPDate('LCC'),
          isLeaveEmployer: formatSEP('LEC'),
          leaveEmployerDate: formatSEPDate('LEC'),
          leaveEmployerCovStartDate: null, // not required to pass
          isPharmacyAssistance: formatSEP('PAP'),
          isReturnedToUS: formatSEP('RUS'),
          returnedToUSDate: formatSEPDate('RUS'),
          isContractEndsWithMedicare: formatSEP('MYT'),
          isOther: formatSEP('OTH'),
          otherDetails: formatSEPOTHDesc(),
          isDisenrolledFromSNP: formatSEP('SNP'),
          disenrolledFromSNPDate: formatSEPDate('SNP'),
          isHaveMedicareMedicaidOrHelpFromMedicare: formatSEP('MDE'),
          isChangeInMedicaid: formatSEP('MCD'),
          changeInMedicaidDate: formatSEPDate('MCD'),
          isChooseDiffPlan: formatSEP('DIF'),
          chooseDiffPlanDate: formatSEPDate('DIF'),
          isNotEnrolledFEMADisaster: formatSEP('DST'),
          isChangeDuringMAOEP: YESNO(['OEP', 'OEP-N', 'OEP-I'].includes(res.sep_code)),
          isHaveQualifyingCondition: formatSEP('CSN'),
          action: 'UPDATE',
        },
        medicareInsurance: {
          medicareClaimNo: res.medicare_id,
          partAEffDate: res.part_a_effective_date,
          partBEffDate: res.part_b_effective_date,
          action: 'UPDATE',
        },
        electronicSupportForm: {
          preQualForm: {
            name: 'CSNPPreQualForm',
            preQualQuestions: [
              {
                qualQuestion: 'CHFAnswer',
                qualAnswer: YESNONONE(res.snp_chf),
              },
              {
                qualQuestion: 'CVDAnswer',
                qualAnswer: YESNONONE(res.snp_cvd),
              },
              {
                qualQuestion: 'DiabetesAnswer',
                qualAnswer: YESNONONE(res.snp_diabetes),
              },
              {
                qualQuestion: 'HaveCOPDAnswer',
                qualAnswer: YESNONONE(res.snp_lung),
              },
              {
                qualQuestion: 'ESRDAnswer',
                qualAnswer: YESNONONE(res.snp_esrd),
              },
              {
                qualQuestion: 'DialysisCenterName',
                qualAnswer: existsOrBlank(res.snp_esrd_dialysis_center),
              },
              {
                qualQuestion: 'DialysisCenterID',
                qualAnswer: existsOrBlank(res.snp_esrd_dialysis_id),
              },
              {
                qualQuestion: 'DialysisCenterPhNo',
                qualAnswer: existsOrBlank(res.snp_esrd_dialysis_tel),
              },
              {
                qualQuestion: 'NephrologistName',
                qualAnswer: existsOrBlank(res.snp_esrd_nephrologist_name),
              },
              {
                qualQuestion: 'NephrologistPhNo',
                qualAnswer: existsOrBlank(res.snp_esrd_nephrologist_tel),
              },
            ],
            provider: {
              firstName: existsOrNull(res.snp_physician_first_name),
              middleInitial: existsOrNull(res.snp_physician_middle_name),
              lastName: existsOrNull(res.snp_physician_last_name),
              phoneNo: existsOrNull(res.snp_physician_tel),
              faxNo: existsOrNull(res.snp_physician_fax),
              address: {
                addressLine1: existsOrNull(res.snp_physician_street1),
                city: existsOrNull(res.snp_physician_city),
                state: existsOrNull(res.snp_physician_state),
                postalCode: existsOrNull(res.snp_physician_zip),
                action: 'UPDATE',
              },
              action: 'UPDATE',
            },
            action: 'UPDATE',
          },
          action: 'UPDATE',
        },
      }, // end application
      isSubmit: 'YES',
    }],
  };
  return { formattedData, agentID };
};
