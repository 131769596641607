function formatAdditionalEffectiveDate(date) {
  if (date) return `val: ${date.val} | desc: ${date.desc}`;
  return false;
}

export default function constructData(theme) {
  const { themeType } = theme;

  const data = [
    {
      themeType: ['all'],
      name: 'logo',
      value: theme.logo,
      desc: 'The URL for the logo image to display',
    },
    {
      themeType: ['consumer'],
      name: 'activeUserRefreshTokenMinutes',
      value: theme.activeUserRefreshTokenMinutes,
      desc: 'How often the token gets refreshed if a user is active on the site. Active means clicking items within the site',
    },
    {
      themeType: ['consumer'],
      name: 'cmsID',
      value: theme.cmsID,
      desc: 'Material id is configured per site. It can be overridden on specific enrollment forms via enrollment form configuration.',
    },
    {
      themeType: ['consumer'],
      name: 'title',
      value: theme.title,
      desc: 'title of the site displayed in the browser tab',
    },
    {
      themeType: ['all'],
      name: 'favicon',
      value: theme.favicon,
      desc: 'URL to the image to use for the favicon',
    },
    {
      themeType: ['consumer'],
      name: 'useCarrierPermissions',
      value: theme.useCarrierPermissions,
      desc: 'Determines whether carrier permissions are checked before allowing enrollment.',
    },
    {
      themeType: ['agent'],
      name: 'soaLink',
      value: theme.soaLink,
      desc: 'Link to the soa portal for viewing SOAs',
    },
    {
      themeType: ['agent'],
      name: 'privacyLink',
      value: theme.privacyLink,
      desc: 'Link to privacy policies used in portal.',
    },
    {
      themeType: ['agent'],
      name: 'enrollment',
      value: theme.enrollment,
      desc: 'Link to the enrollment portal associated with this agent portal',
    },
    {
      themeType: ['agent'],
      name: 'greeting',
      value: theme.greeting,
      desc: 'Text displayed on the landing / login page',
    },
    {
      themeType: ['agent'],
      name: 'currentPlan',
      value: theme.features?.currentPlan,
      desc: 'This enables features related to an agent being able to set a "current plan" for a shopper',
    },
    {
      themeType: ['agent'],
      name: 'shopperDetailsNotes',
      value: theme.features?.shopperDetailsNotes,
      desc: 'This shows / hides the dropdown notes section on the shopper details page.',
    },
    {
      themeType: ['agent'],
      name: 'captchaLogin',
      value: theme.features?.captchaLogin,
      desc: 'When this is set to true captcha is required to login to the portal.',
    },
    {
      themeType: ['agent'],
      name: 'carrierPermissions',
      value: theme.features?.carrierPermissions,
      desc: 'This determines whether RTS / Carrier permissions are used to limit agents enrollment ability',
    },
    {
      themeType: ['agent'],
      name: 'doctors',
      value: theme.features?.doctors,
      desc: 'This determines whether you are able to add doctors and view coverage information on the plan list and details',
    },
    {
      themeType: ['agent'],
      name: 'soa',
      value: theme.features?.soa,
      desc: 'This turns the ability to send soas on and off, and whether the soa popup shows on the planlist.',
    },
    {
      themeType: ['agent'],
      name: 'careHighlights',
      value: theme.features?.careHighlights,
      desc: 'Turns on / off care highlights display',
    },
    {
      themeType: ['agent'],
      name: 'telephony',
      value: theme.features?.telephony,
      desc: 'Turns on / off telephony features (call type on shopper details, softphone window, call records page)',
    },
    {
      themeType: ['agent'],
      name: 'hra',
      value: theme.features?.hra,
      desc: 'Turns the HRA feature on / off',
    },
    {
      themeType: ['agent'],
      name: 'voiceSOADebug',
      value: theme.features?.voiceSOADebug,
      desc: 'If turned on then you dont need to be in a call to do a voice soa',
    },
    {
      themeType: ['agent'],
      name: 'telephonyDebug',
      value: theme.features?.telephonyDebug,
      desc: 'If true then you dont need to be in a call to navigate a shopper, even if telephony feature is turned on',
    },
    {
      themeType: ['agent'],
      name: 'planListFilterType',
      value: theme.features?.planListFilterType,
      desc: 'This can be either "carrier", "brand", or "org". Determines which name to use from the DR data to display the carrier name.',
    },
    {
      themeType: ['consumer'],
      name: 'planListFilterType',
      value: theme.planListFilterType,
      desc: 'This can be either "carrier", "brand", or "org". Determines which name to use from the DR data to display the carrier name.',
    },
    {
      themeType: ['agent'],
      name: 'carrierLevelCI',
      value: theme.features?.carrierLevelCI,
      desc: 'Turns on / off CI tab on plan details and CI section on plan compare.',
    },
    {
      themeType: ['agent'],
      name: 'disclaimerDSNP',
      value: theme.disclaimerDSNP,
      desc: 'Text for the DSNP disclaimer. If this is false/red then the disclaimer will not show',
    },
    {
      themeType: ['agent'],
      name: 'additionalEffectiveDate',
      value: formatAdditionalEffectiveDate(theme.additionalEffectiveDate),
      desc: `If there is a value here it will be the default selected effective date on the shopper
      details page. If it duplicates one of the 3 auto generated dates it will just set that as the
      default selected date and will not create a duplicate in the dropdown. If it is not set, then
      the default selected effective date will be the closest date to today.`,
    },
    {
      themeType: ['agent'],
      name: 'requireLIS',
      value: theme.features?.requireLIS,
      desc: 'If this is true then selecting an LIS value is required on shopper details.',
    },
    {
      themeType: ['agent'],
      name: 'effectiveUpdate',
      value: theme.features?.effectiveUpdate,
      desc: `If this is true, then we when we load the plan list page we check to see if the
      current effective date is valid. It is valid if it is equal to or greater than today's date.
      If this config is true and the effective date is invalid, we push the agent back to shopper
      details page and ask them to update the effective date with a modal. If this config is false
      this check will not happen.`,
    },
    {
      themeType: ['agent'],
      name: 'dashShowLeadType',
      value: theme.features?.dashShowLeadType,
      desc: 'Toggles visibility of the "Lead type" column on the dashboard list of shoppers table.',
    },
    {
      themeType: ['agent'],
      name: 'agentNotifications',
      value: theme.features?.agentNotifications,
      desc: 'Agent meeting notifications',
    },
    {
      themeType: ['agent'],
      name: 'medigap',
      value: theme.features?.medigap,
      desc: 'This config has not been tested in quite a while and is mostly deprecated.',
    },
    {
      themeType: ['agent'],
      name: 'disclaimerHumanaProvider',
      value: theme.features?.disclaimerHumanaProvider,
      desc: 'A disclaimer that shows on the doctors page for Humana specifically.',
    },
    {
      themeType: ['agent'],
      name: 'shareQuote',
      value: theme.features?.shareQuote,
      desc: 'Turns on and off the ability to share a quote directly to the consumer that can be opened on the consumer portal',
    },
    /*
    {
      themeType: [],
      name: '',
      value: '',
      desc: '',
    },
    */
  ];

  return data.filter(config => {
    if (config.themeType.includes('all')) return true;
    if (config.themeType.includes(themeType)) return true;
    return false;
  });
}
