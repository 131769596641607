import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import './landing.css';

// Actions
import { getAgent } from '../../actions/agent';

// Utils
import { prettifyPartialPhone, stripPhone } from '../../common/utils/prettify';

// Components
import Topbar from '../../components/Topbar';
import DisclaimerFooter from '../../components/DisclaimerFooter';
import { LoaderButton } from '../../common/components';
import { retrieveAndGetQuote } from '../../actions/quote';


export function TextInput({
  className = '',
  style = {},
  errors = '',
  placeholder = '',
  value = '',
  onChange = () => {},
  onEnter = () => {},
  onBlur = () => {},
  label = '',
  fadeMessage = '',
  toggleVisibility = false,
  type = 'text',
}) {
  const [ visible, setVisible ] = useState(false);

  return (
    <div className={className} style={style}>
      {label && (
        <label className="form-label fs-4 fw-bold" htmlFor={label}>
          {label}
        </label>
      )}
      <div className="d-flex position-relative">
        <input
          className={`text-input form-control border-primary pb-1 ${errors ? 'border-danger' : ''}`}
          placeholder={placeholder}
          type={toggleVisibility && !visible ? 'password' : type}
          tabIndex="0"
          value={value}
          onChange={(e) => onChange(e)}
          onBlur={(e) => onBlur(e)}
          onKeyDown={(e) => {
            if (onEnter && e.key === 'Enter') onEnter(e);
          }}
          aria-label={label}
        />
        {toggleVisibility && (
          <span
            className="d-flex position-absolute align-items-center justify-content-center h-100 fs-4"
            style={{ right: '0rem', cursor: 'pointer', width: '2.5rem' }}
            onClick={() => setVisible(!visible)}
          >
            <FontAwesomeIcon
              icon={visible ? faEyeSlash : faEye}
            />
          </span>
        )}
      </div>
      <div className={`text-input-errors fs-6 ${errors ? 'show' : 'hide'}`}>
        <div className={`d-flex align-items-center w-auto ${errors ? 'text-danger' : 'text-success'}`}>
          <FontAwesomeIcon
            className="fs-5 me-1"
            style={{ minWidth: '18px' }}
            icon={errors ? faTimes : faCheck}
          />
          {errors || fadeMessage}
        </div>
      </div>
    </div>
  );
}

// Design ticket: https://mymedicarebot.atlassian.net/browse/MYR-4422
function VerifyIdentity({ flow = 'quote' }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ code, setCode ] = useState('');
  const [ codeErrs, setCodeErrs ] = useState();
  const [ dob, setDob ] = useState();
  const [ dobErrs, setDobErrs ] = useState();
  const [ phone, setPhone ] = useState();
  const [ phoneErrs, setPhoneErrs ] = useState();
  const [ loading, setLoading ] = useState();
  const [ errs, setErrs ] = useState();
  const [ fadeMessage, setFadeMessage ] = useState();

  const {
    shopper,
    agent,
    theme: {
      esignMaterialID,
      esignDisclaimerLastUpdate,
    },
  } = useSelector(store => store);
  let splashSrc = '/img/splash.jpg';

  const inputs = [
    {
      label: 'Activation code',
      value: code,
      onChange: (e => {
        setCode(e.target.value);
        setCodeErrs();
        setFadeMessage('Activation code is required.');
        setTimeout(() => { setFadeMessage() }, 1000);
      }),
      onBlur: (() => {
        if (!code) setCodeErrs('Activation code is required.')
      }),
      onEnter: (() => handleSubmit()),
      errors: codeErrs,
      fadeMessage,
    },
    {
      label: 'Date of birth',
      value: dob,
      type: 'date',
      onChange: (e => {
        setDob(e.target.value);
        setDobErrs();
        setFadeMessage('Date of birth is required.');
        setTimeout(() => { setFadeMessage() }, 1000);
      }),
      onBlur: (() => {
        if (!dob) setDobErrs('Date of birth is required.')
      }),
      onEnter: (() => handleSubmit()),
      errors: dobErrs,
      fadeMessage,
    },
    {
      label: 'Phone number',
      value: prettifyPartialPhone(phone),
      onChange: (e => {
        const { value: val } = e.target;
        if (val.length <= 16) {
          let unformatted = stripPhone(val);
        // NOTE(santeyio): this is jenky... I'm pretty tired and I'm not sure
        // that this is the best way to fix this?
        const re = /^\([0-9]{1,3}$/g;
        if (re.test(val)) {
          if (val.length === 4) unformatted = val.slice(1, 3);
          if (val.length === 3) unformatted = val.slice(1, 2);
          if (val.length === 2) unformatted = val.slice(1, 1);
        }
        setPhone(unformatted);
        }
      }),
      onBlur: (() => {
        if (!phone) {
          setPhoneErrs('Phone number is required.')
        } else if (phone.length < 10) {
          setPhoneErrs('Please enter a valid phone number.')
        } else {
        setFadeMessage(phoneErrs);
        setPhoneErrs();
        setTimeout(() => { setFadeMessage() }, 1000);
        }
      }),
      onEnter: (() => handleSubmit()),
      errors: phoneErrs,
      fadeMessage,
    },
  ];

  useEffect(() => {
    if (!shopper.username || !shopper.quoteID) {
      setErrs('Please follow the link from your agent.');
    }

    if (agent.username && !agent.firstName) {
      getAgent(agent.username)
        .then(agent => {
          dispatch({ type: 'SET_AGENT', payload: agent });
        });
      }
  }, [shopper, agent]);

  function handleNavigation() {
    if (flow === 'esign') navigate('/signup?flow=esign');
    if (flow === 'quote') navigate('/quotes');
  }

  function handleSubmit(e) {
    if (!code || !dob || !phone) {
      if (!code) setCodeErrs('Activation code is required');
      if (!dob) setDobErrs('Date of birth is required');
      if (!phone) setPhoneErrs('Phone number is required');
    } else {
      setLoading(true);
      retrieveAndGetQuote({
        username: shopper.username,
        quote_id: shopper.quoteID,
        quote_code: code,
        birthdate: dob,
        phone,
      }).then(quote => {
        dispatch({ type: 'CLEAR_SHOPPER_QUOTES' }); 
        dispatch({ type: 'ADD_SHOPPER_QUOTE', payload: quote });
        dispatch({ type: 'CLEAR_SESSION' });
        localStorage.removeItem('SessionToken');

        // depending on the flow we navigate to different places
        handleNavigation();
      }).catch(err => {
        const { response } = err;
        let errMsg = 'There was a problem verifying your credentials.';
        if (response && response.data) {
          let errObj = response.data;
          if (errObj.quote_id && errObj.username) {
            errMsg = 'Please follow the link from your agent';
          }
          if (errObj.phone) {
            setPhoneErrs('Please enter a correct phone number.');
          }
          if (errObj.non_field_errors) {
            errMsg = 'Quote not found. Please double check that you entered your information correctly.';
          }
        }
        setErrs(errMsg);
      }).finally(() => setLoading(false));
    }
  }

  let headerText = '';
  let customMaterialID;
  let customDisclaimerLastUpdate;
  if (flow === 'quote') headerText = 'View your Medicare quote';
  if (flow === 'esign') {
    headerText = 'Sign and complete your Medicare Advantage or Medicare Prescription Drug plan enrollment application';
    customMaterialID = esignMaterialID;
    customDisclaimerLastUpdate = esignDisclaimerLastUpdate
  }

  return (
    <>
      <Topbar showAgent />
      <div className='mmb-container show-agent'>
        <div className='bg-white w-100 d-flex h-100 flex-m-row-col'>
          <div id="landing-img" className="position-relative">
            <img
              className="position-absolute"
              src={splashSrc}
              aria-label="couple enjoying medicare recommendation"
            />
          </div>
          <div id="landing-text" className="d-flex flex-column align-items-center">
            <div className="text-serif fw-bold fs-1 m-3 mb-0 text-center">
              {headerText}
            </div>
            <div className="text-center m-4 d-flex flex-column align-items-center" style={{ maxWidth: '450px' }}>
              Please verify your identity. Your activation code is in the email or text message from your broker.
            <div className="d-flex flex-column text-start mt-4 mb-3" style={{ width: '300px' }}>
              {inputs.map((i) => (
                <TextInput key={i.label} className="mb-3 w-100" {...i} />
              ))}
                <LoaderButton
                  text="Submit"
                  loading={loading}
                  loadingText="Submitting.."
                  className="btn btn-primary fw-bold w-100 mt-2"
                  onClick={() => handleSubmit()}
                  errors={errs}
                  disabled={!shopper.username || !shopper.quoteID}
                >
                  Continue
                </LoaderButton>
              </div>
            </div>
            <DisclaimerFooter
              customMaterialID={customMaterialID}
              customLastUpdate={customDisclaimerLastUpdate}
              className="p-3"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyIdentity;
