const initialState = {
  plans: [],
  planType: 'MAPD',
  selectedPlanQuote: {},
  enrollDisabledPlans: [],

  snapshotPlans: {/* load on QuoteList mount - "snapshot" of plans at quote time */},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'ADD_SNAPSHOT_PLAN':
      return { ...state, snapshotPlans: { ...state.snapshotPlans, [action.payload.quoteID]: action.payload }};
    case 'SET_CURRENT_PLAN':
      return { ...state, snapshotPlans: { ...state.snapshotPlans, 'currentPlan': action.payload }};
    case 'SET_DISABLED_PLANS':
      return { ...state, enrollDisabledPlans: action.payload };  
    case 'SET_PLANS':
      return { ...state, plans: action.payload };
    case 'SET_PLAN_FIELD':
      return { ...state, ...action.payload };
    case 'CLEAR_PLANS':
      return initialState;
    default:
      return state;
  }
}
