import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { FormRow, TitleRow } from '../../components/layout';
import { DatePicker, Radio, TextInput, YesNo } from '../../components/inputs';
import { Collapse } from '../../../../common/components';

import { handleChange, isAepPeriod } from '../../../../utils/enroll';

import '../../styles.scss';

function ElectionPeriod ({ pageTitle }) {
  const dispatch = useDispatch();
  const rowClasses = 'input-row';
  const { enrolltype, isaep, sep_code } = useSelector(store => store.evalues);
  const {
    sep,
    quote: { effectiveDate },
  } = useSelector(store => store.enrollment);
  const { debugElectionPeriod } = useSelector(store => store.theme);
  const sepOptions = sep.map(opt => ({ value: opt.code, label: opt.desc }));
  const needsReason = sep.map(c => (c.needsReason ? c.code : undefined)).filter(c => c);
  const needsDate = sep.map(c => (c.needsDate ? c.code : undefined)).filter(c => c);

  const [ isDuringAep, setIsDuringAep ] = useState(null);

  // watch value of isaep and use to set enrolltype
  useEffect(() => {
    if (isaep === true) {
      handleChange({ value: 'AEP', name: 'enrolltype', forceValidation: true });
    } else if (isaep === false) {
      handleChange({ value: 'SEP', name: 'enrolltype', forceValidation: true });
    }

    // on initial mount, determine if we are outside aep. if so, disable input and set to "No"
    if (isDuringAep === null) {
      const isAEP = isAepPeriod(moment(effectiveDate).year());
      if (isAEP) {
        setIsDuringAep(true);
      } else {
        handleChange({ value: 'SEP', name: 'enrolltype', forceValidation: true });
        handleChange({ value: false, name: 'isaep', forceValidation: true });
        setIsDuringAep(false);
      }
    }
  }, [isaep]);

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <FormRow>
          Typically, you may enroll in a Medicare Advantage plan during the Annual Enrollment Period (AEP) from October 15 through December 7 of each year. There are exceptions that may allow you to enroll in a Medicare Advantage plan outside of this period.
        </FormRow>
        <FormRow>
          <YesNo
            label="I am enrolling during the Annual Enrollment Period (October 15 - December 7)"
            name="isaep"
            disabled={!isDuringAep && !debugElectionPeriod}
            // we need to clear any previously selected sep values if field is changed
            // from sep to aep.
            unsetFieldsOnTrue={['sep_code', 'sep_date', 'sep_other']}
          />
        </FormRow>
        <Collapse
          indent
          isOpen={enrolltype === 'SEP'}
          recalculateHeightOnChange={sep_code}
        >
          <FormRow>
            <Radio
              label="If you are enrolling outside of the Annual Enrollment Period, please select the statement that applies to you. By selecting an option you certify that, to the best of your knowledge, you are eligible for an Enrollment Period. If we later determine that this information is incorrect, you may be disenrolled."
              options={sepOptions}
              name="sep_code"
            >
              {needsReason.includes(sep_code) && (
                <div className="pb-3">
                  <TextInput
                    label="Please specify the reason"
                    name="sep_other"
                  />
                </div>
              )}
              {needsDate.includes(sep_code) && (
                <div className="pb-3">
                  <DatePicker
                    label="Date of event"
                    name="sep_date"
                  />
                </div>
              )}
            </Radio>
          </FormRow>
        </Collapse>
      </div>
    </div>
  );  
}

export default ElectionPeriod;
