const initialState = {
  contactMethod: '',
  contactPhone: '',
  contactTime: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_CONTACT_FIELD':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
