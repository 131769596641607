import React from 'react';
import { useSelector } from 'react-redux';

import {
  handleChange,
  isRequired,
  getOptions,
  getFieldKey,
  fieldExistsInConfig,
} from '../../../../utils/enroll';

import FormLabel from './FormLabel';
import { Collapse } from '../../../../common/components';

function Radio ({
  boldLabel,
  clone,
  children,
  disabled,
  label,
  name,
  options, // this prop will be overidden if options are specified in form JSON config
  unsetFieldsOnCondition = {}, 
  // unsetFieldsOnCondition takes an object in the shape of
  // {
  //   condition: (some boolean),
  //   fieldsToUnset: [ 'field1', 'field2', ... ],\
  // }
  onChangeCallback = () => {}, 
}) {
// This component requires an options array to be provided in the shape
// [ { value: 'val', label: 'whatever text' }, &etc... ]

  const form = useSelector(store => store.enrollment.form);
  const evalues = useSelector(store => store.evalues);
  const value = evalues[name];
  const required = isRequired({ form, name, currentValues: evalues });
  const validationRegex = useSelector(store => store.enrollment.validation[name]);
  const { submittedFlag } = useSelector(store => store.enrollment);
  const customLabel = getFieldKey(form, name, 'label');

  // defaults to what is being passed in by prop `options`,
  //  can be overidden by setting options in form JSON config
  let opts;
  if (!options) {
    opts = getOptions(form, name);
  } else {
    opts = options;
  }

  // if field doesn't exist, don't show it.
  if (!fieldExistsInConfig(form, name)) return null;

  return (
    <>
      <FormLabel label={customLabel || label} name={name} required={required} />
      {opts.map(option => (
        <div key={option.value}>
          <div
            className="form-check mt-2 mb-3"
            style={{ paddingLeft: '1.75rem' }}
            onClick={() => handleChange({
              value: option.value,
              name,
              validationRegex,
              forceValidation: true,
              required,
              unsetFieldsOnCondition,
              onChangeCallback,
            })}
          >
            <input
              className={['form-check-input'].join(' ')}
              type="radio"
              id={String(option.value)}
              name={name}
              value={option.value}
              checked={option.value === value}
              disabled={disabled || submittedFlag}
              onChange={() => {}} // React throws an error if I don't put a blank onChange here
            />
            <label htmlFor={String(option.value)} className={['form-check-label', ((option.desc || boldLabel) && 'fw-bold')].join(' ')}>
              {option.label}
            </label>
            {option.desc && (
              <div className="text-muted">
                {option.desc}
              </div>
            )}
          </div>
          <Collapse isOpen={(option.value === value)} indent="2">
            {children}
          </Collapse>
        </div>
      ))}
    </>
  );
}

export default Radio;
