import { store } from '../store';
import moment from 'moment';
import { evaluateConditions } from '../common/utils/enrollment';

export function getPageFromUrl(form, url) {
  const urlParts = url.split('/');
  let returnPage = undefined;
  form.forEach((section) => {
    if (section.route === `/${urlParts.at(-2)}`) {
      section.pages.forEach(page => {
        if (page.route === `/${urlParts.at(-1)}`) {
          returnPage = page; 
        }
      }); 
    }   
  }); 
  return returnPage;
}

export function getAllFields(form) {
  const fields = []; 
  form.forEach(section => {
    section.pages.forEach(page => {
      page.fields.forEach(field => fields.push(field));
    }); 
  }); 
  return fields;
}

export function getRoutes(form, baseRoute) {
  const urls = []; 
  const formRoute = `/enroll${baseRoute}`;
  form.forEach(section => {
    const sectionRoute = section.route;
    section.pages.forEach(page => {
      urls.push(`${formRoute}${sectionRoute}${page.route}`);
    }); 
  }); 
  return urls;
}

export function getField(form, name) {
  let rfield = false;
  form.forEach(section => {
    section.pages.forEach(page => {
      page.fields.forEach(field => {
        if (field.name === name) {
          rfield = field;
        }
      });
    });
  });
  return rfield;
}

export function fieldExistsInConfig(form, name) {
  const fields = getAllFields(form);
  let fieldExists = false;
  fields.forEach(field => {
    if (field.name === name) fieldExists = true;
  });
  return fieldExists;
}

export function getFieldKey(form, name, key) {
  // fields have a shape of
  // {
  //   name: 'home_address', 
  //   required: true|false,
  //   (optional) label: 'Home Address',
  //   ... etc
  // }
  const field = getField(form, name);
  return field.label;
}

export function getOptions(form, name) {
  // returns either `false` or an array in the shape
  // [
  //   {label: 'field label', value: 'value'},
  //   {label: 'field label', value: 'value'},
  //   &etc...
  // ]
  let options = false;
  const field = getField(form, name);
  if (field.options) {
    options = field.options;
  }
  return options;
}

export function extractValidation(formConfig) {
  const fields = getAllFields(formConfig.form);
  const validation = {};
  fields.forEach(field => {
    if (field.validation) {
      validation[field.name] = field.validation;
    }
  });
  return validation;
}

export function isRequired({ form, name, currentValues }) {
  let required = false;
  const field = getField(form, name);
  if (field.requiredConditions && currentValues) {
    required = evaluateConditions({
      currentValues,
      conditions: field.requiredConditions,
    });
  } else if (field.required) {
    required = true;
  }
  return required;
}

export function getValidClass({ valid, required, value }) {

  let classText = '';

  // checks for non-required fields
  if (!required && (value === '' || value === undefined)) {
    classText = '';
  } else if (!required && valid && valid !== undefined) {
    classText = 'is-valid';
  } else if (!required && !valid && valid !== undefined) {
    classText = 'is-invalid';

  // checks for required fields
  } else if (valid !== undefined && valid) {
    classText = 'is-valid';
  } else if (valid !== undefined && !valid) {
    classText = 'is-invalid';
  }
  return classText;
}

export function handleChange({
  value,
  name,
  valid,
  validationRegex,
  forceValidation,
  required,
  unsetFieldsOnTrue = [],
  unsetFieldsOnFalse = [],
  unsetFieldsOnCondition = {},
  onChangeCallback = (newValue) => {}, // callback is passed the changed value
}) {
  store.dispatch({ type: 'SET_EVALUES_FIELD', name, value });
  // update field validation on every change only after
  // field validation has been initially set by the first
  // onBlur event (or optionally force it with an arg).
  if (valid !== undefined || forceValidation) {
    handleValidation({ value, validationRegex, name, required });
  }

  // run callbacks
  unsetFieldsOnTrue.forEach((field) => {
    if (value === true) {
      store.dispatch({ type: 'SET_EVALUES_FIELD', name: field, value: undefined });
      store.dispatch({ type: 'SET_FIELD_VALIDATION', name: field, value: undefined });
    }
  });
  unsetFieldsOnFalse.forEach((field) => {
    if (value === false) {
      store.dispatch({ type: 'SET_EVALUES_FIELD', name: field, value: undefined });
      store.dispatch({ type: 'SET_FIELD_VALIDATION', name: field, value: undefined });
    }
  });
  if (unsetFieldsOnCondition.condition) {
    unsetFieldsOnCondition.fieldsToUnset.forEach((field) => {
      store.dispatch({ type: 'SET_EVALUES_FIELD', name: field, value: undefined });
      store.dispatch({ type: 'SET_FIELD_VALIDATION', name: field, value: undefined });
    });
  }
  onChangeCallback(value);
}

function isValid({ value, validationRegex }) {
  const re = new RegExp(validationRegex);

  // re.test(val) will convert val to a string (e.g. if val is undefined
  // it will cast val to a string 'undefined'). If value is undefined
  // we should test an empty string.
  let testVal = '';
  if (value !== undefined) {
    testVal = value;
  }
  return re.test(testVal);
}

export function handleValidation({ value, validationRegex, name, required }) {
  // if the field is not required and is blank, unset it's validation key
  if (!required && (value === '' || value === undefined || value === null)) {
    store.dispatch({ type: 'SET_FIELD_VALIDATION', name, valid: undefined });

  // else if normal value, do normal validation
  } else if (isValid({ value, validationRegex })) {
    store.dispatch({ type: 'SET_FIELD_VALIDATION', name, valid: true });
  } else {
    store.dispatch({ type: 'SET_FIELD_VALIDATION', name, valid: false });
  }
}

// used for receiving and posting our mmb-api enrollment objects
export function fmtEnrollment(e, fields, options) {
  options = {
    post: false,
    ...options,
  };

  let fmt = {};
  if (options.post) {
    fmt = {
      quote_id: e.quoteID,
      carrier: e.carrier,
      // posting - these are evalues
      fields: fields,
      submitted_flag: e.submittedFlag,
      submit_date: e.submitDate,
    }
  } else {
    fmt = {
      quoteID: e.quote_id,
      carrier: e.carrier,
      enrollmentID: e.enrollment_id,
      fields: e.fields,
      submittedFlag: e.submitted_flag,
      submitDate: e.submit_date,
      confirmationNumber: e.confirmation_number,
    }
  }
  return fmt;
}

export const isAepPeriod = (year = '2022') => {
  const aepStart = moment(`${Number(year) - 1}-10-15`);
  const today = moment();
  const aepEnd = moment(`${Number(year) - 1}-12-07`);
  return aepStart <= today && today <= aepEnd;
}

export function canEnrollCarrier({ carrier, planYear }) {
  const { enrollCarriers } = store.getState().theme;

  if (Array.isArray(enrollCarriers)) {
    if (enrollCarriers.includes(carrier)) return true;
  } else {
    // if `carriersWithPricing` is not specified or false in the theme,
    // then assume all plans have pricing.
    if (!enrollCarriers) return true;

    // if it is specified, in theme
    const validCarriers = Object.keys(enrollCarriers);
    const validYears = enrollCarriers[carrier] || [];
    if (validCarriers.includes(carrier) && validYears.includes(planYear)) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export const hasCarrierPerms = (planType, planCarrierID, planState, planYear) => {
  const {
    agent,
    theme: { useCarrierPerms },
  } = store.getState();

  if (!useCarrierPerms) return true;

  const perms = agent.carrierPermissions || [];

  let hasPerms = false;
  let iNpn; // individual
  let cNpn; // corporate

  if (agent.npn_type === 'individual') {
    iNpn = agent.npn;
    cNpn = agent.npnLinked;
  } else if (agent.npn_type === 'corporate') {
    iNpn = agent.npnLinked;
    cNpn = agent.npn;
  } else {
    iNpn = agent.npn;
    cNpn = agent.npnLinked;
  }

  const iPerm = perms.find((p) => (
    p.active
      && p.carrier_id === planCarrierID
      && p.npn === iNpn
      && String(p.plan_year) === String(planYear)
  )) || {};
  const cPerm = perms.find((p) => (
    p.active
      && p.carrier_id === planCarrierID
      && p.npn === cNpn
      && String(p.plan_year) === String(planYear)
  )) || {};

  const iStates = iPerm.states || [];
  const cStates = cPerm.states || [];
  const iHasState = iStates.includes(planState);
  const cHasState = cStates.includes(planState);

  const hasProduct = (perm, product) => {
    const productMap = {
      MAPD: perm.product_mapd,
      PDP: perm.product_pdp,
      MA: perm.product_ma,
      Medigap: perm.product_medigap,
    };
    return Boolean(productMap[product]);
  };

  if (iHasState && cHasState) {
    hasPerms = hasProduct(iPerm, planType);
  } else if (iHasState) {
    hasPerms = hasProduct(iPerm, planType);
  } else if (cHasState) {
    hasPerms = hasProduct(cPerm, planType);
  }

  return hasPerms;
};
