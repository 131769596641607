export const toPhone = (number = '', options = {}) => {
  let phone = '';
  if (number) {
    if (typeof number === 'number') {
      number = number.toString();
    } else {
      // strip string of all non-numeric chars
      number = number.replaceAll(/[^0-9]/g, '');
    }
    let areaCode, centralOfficeCode, lineNumber, countryCode;

    if (number.length === 10) {
      areaCode = number.substring(0, 3); 
      centralOfficeCode = number.substring(3, 6); 
      lineNumber = number.substring(6, 10);
    } else if (number.length === 11) {
      countryCode = number.substring(0, 1);
      areaCode = number.substring(1, 4); 
      centralOfficeCode = number.substring(4, 7); 
      lineNumber = number.substring(7, 11);
    } else {
      return '[invalid phone number]';
    }
    if (number.length > 10) {
      phone = `${countryCode}-${areaCode}-${centralOfficeCode}-${lineNumber}`;
    } else if (number.length > 6) {
      phone = `(${areaCode}) ${centralOfficeCode} ${lineNumber}`;
    } else if (number.length >= 3) {
      phone = `(${areaCode}) ${centralOfficeCode} ${centralOfficeCode.length === 3 ? '-' : ''}`;
    } else if (number.length > 0) {
      phone = `(${areaCode} )`; // give space for user to type
    }   
  }
  return phone;
}
