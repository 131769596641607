export const fallback = (condition, ifTrue, ifFalse) => (condition ? ifTrue : ifFalse);

export const yesNo = (condition) => (condition ? 'Yes' : 'No');

export const YESNO = (condition) => (condition ? 'YES' : 'NO');

export const YESNONE = (condition) => (condition ? 'YES' : 'NONE');

export const YESNONONE = (value) => {
  if (value === undefined) return 'NONE';
  if (value) return 'YES';
  return 'NO';
};

export const yn = (condition) => (condition ? 'Y' : 'N');

export const ynb = (condition, blank = '') => {
  if (condition === undefined || condition === null || condition === '') return blank;
  if (condition) return 'Y';
  return 'N';
};

export const yesNoBlank = (value, blank = '') => {
  if (value === undefined) return blank;
  if (value) return 'Yes';
  return 'No';
};

export const YESNOBLANK = (value, blank = '') => {
  if (value === undefined) return blank;
  if (value) return 'YES';
  return 'NO';
};

export const existsOrBlank = (field, blank = '') => {
  if (field === undefined || field === null || field === '') return blank;
  return field;
}; // eslint-disable-line

export const existsOrNull = (field) => (field ? field : null); // eslint-disable-line
