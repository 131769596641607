import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { MultiCheck, Radio, Select, TextInput, YesNo } from '../../components/inputs';
import { fieldExistsInConfig } from '../../../../utils/enroll';
import { toPhone } from '../../../../utils/format';

import '../../styles.scss';

function Optional ({ pageTitle }) {
  const rowClasses = 'input-row';
  const {
    providerLookupLink,
    carrierName,
    race,
    ethnicity,
    form,
    plan: {
      plan: {
        MemberPhoneNumber,
        WebsiteAddress = '[DR.WebsiteAddress]',
        BrandName = '[DR.BrandName]',
        CustomerServiceHours = '[DR.CustomerServiceHours]',
        PlanTypeDesc: planType = '[DR.PlanTypeDesc]',
      },
    },
  } = useSelector(store => store.enrollment);

  const planPhone = MemberPhoneNumber ? toPhone(MemberPhoneNumber) : '[DR.MemberPhoneNumber]';
  const raceExists = fieldExistsInConfig(form, 'race');
  const ethnicityExists = fieldExistsInConfig(form, 'ethnicity');
  const veteranExists = fieldExistsInConfig(form, 'veteran');

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <FormRow>
          Answering the following questions is your choice.  You can’t be denied coverage because you don’t fill them out.
        </FormRow>

        {/* don't show PCP fields for PDP plans */}
        {planType.toLowerCase() !== 'pdp' && (
          <>
            <TitleRow>Primary care physician</TitleRow>
            <FormRow>
              We strongly recommend that all medical plan applicants include their primary care physician’s (PCP) information below.  If you do not include your PCP information on this application, the plan will assign a PCP for you.
            </FormRow>
            <FormRow showIfFieldExists="pcp_name">
              <TextInput
                label="List your Primary Care Physician (PCP), clinic, or health center"
                name="pcp_name"
              />
            </FormRow>
            <FormRow showIfFieldExists="pcp_first_name">
              <TextInput
                label="PCP First Name"
                name="pcp_first_name"
              />
            </FormRow>
            <FormRow showIfFieldExists="pcp_last_name">
              <TextInput
                label="PCP Last Name"
                name="pcp_last_name"
              />
            </FormRow>
            <FormRow showIfFieldExists="pcp_established">
              <YesNo
                label="Are you an established patient?"
                name="pcp_established"
              />
            </FormRow>
            <FormRow>
              <a href={providerLookupLink} target="_blank" className="fw-bold">
                {`View ${carrierName} provider directory`}
              </a>
            </FormRow>
            <FormRow showIfFieldExists="pcp_num">
              <TextInput
                label="Provider number"
                name="pcp_num"
              />
            </FormRow>
            <FormRow showIfFieldExists="pcp_ipa">
              <TextInput
                label="Wellcare IPA Number"
                name="pcp_ipa"
              />
            </FormRow>
            <FormRow showIfFieldExists="primarycare_id">
              <TextInput
                label="Primary careID"
                name="primarycare_id"
              />
            </FormRow>
            <FormRow showIfFieldExists="pcp_pmg">
              <TextInput
                label="Primary Medical Group"
                name="pcp_pmg"
              />
            </FormRow>
            <FormRow showIfFieldExists="pcp_street1">
              <TextInput
                label="Physician address"
                name="pcp_street1"
              />
            </FormRow>
            <FormRow showIfFieldExists="pcp_city">
              <TextInput
                label="Physician city"
                name="pcp_city"
              />
            </FormRow>
            <FormRow showIfFieldExists="pcp_state">
              <TextInput
                label="Physician state"
                name="pcp_state"
              />
            </FormRow>
            <FormRow showIfFieldExists="pcp_zip">
              <TextInput
                label="Physician zipcode"
                name="pcp_zip"
              />
            </FormRow>
          </>
        )}

        <TitleRow>Work status</TitleRow>
        <FormRow showIfFieldExists="willwork">
          <YesNo
            label="Do you work?"
            name="willwork"
          />
        </FormRow>
        <FormRow showIfFieldExists="willworkspouse">
          <YesNo
            label="Does your spouse work?"
            name="willworkspouse"
          />
        </FormRow>

        {raceExists && (
          <>
            <TitleRow>Race</TitleRow>
            <FormRow>
              What's your race? Select all that apply.
              <div className="ms-2">
                <MultiCheck
                  name="race"
                  options={race}
                  unsetOthersOnSelected="999"
                />
              </div>
            </FormRow>
          </>
        )}

        {ethnicityExists && (
          <>
            <TitleRow>Ethnicity</TitleRow>
            <FormRow>
              Are you Hispanic, Latino/a, or Spanish origin? Select all that apply.
              <div className="ms-2">
                <MultiCheck
                  name="ethnicity"
                  options={ethnicity}
                  unsetOthersOnSelected="NR"
                />
              </div>
            </FormRow>
          </>
        )}

        {veteranExists && (
          <>
            <TitleRow>Veteran Status</TitleRow>
            <FormRow>
              <YesNo name="Veteran" />
            </FormRow>
          </>
        )}

        <TitleRow>Language preference</TitleRow>
        <FormRow>
          <Select
            label="Select one if you want us to send you information in a language other than English."
            name="preflang"
          />
        </FormRow>

        <TitleRow>Alternate format</TitleRow>
        <FormRow>
          <Select
            label="Select one if you want us to send you information in an accessible format."
            name="altwritten"
          />
          <div className="mt-3">
            Please contact {BrandName} at {planPhone} if you need information in a language or accessible format other than what's listed above. The plans office hours are {CustomerServiceHours.trim()}, TTY users can call 711.
          </div>
        </FormRow>

      </div>
    </div>
  );  
}

export default Optional;
