import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, light } from '@fortawesome/fontawesome-svg-core/import.macro';

// Components
import Topbar from '../../components/Topbar';
import DisclaimerFooter from '../../components/DisclaimerFooter';
import { LoaderButton } from '../../common/components';

// Design ticket: https://mymedicarebot.atlassian.net/browse/MYR-4919
function OptOut(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const shopper = useSelector(store => store.shopper);

  const [ unsub, setUnsub ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const handleClick = () => {
    setLoading(true);
    setErrorMessage('');
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/notify/email/unsubscribe/`,
      data: { email: shopper.email, signature: shopper.signature },
    }).then(() => {
      // window.location.href = '/';
      setTimeout(() => {
        setUnsub(true);
        setLoading(false);        
      }, 400);
    }).catch((err) => {
      // const { response } = err;

      // Above error is "Invalid signature", which isn't very helpful.. so for now
      let errMsg = 'There was a problem verifying your credentials.';
      // if (response) errMsg = Object.keys(response.data).reduce((msg, key) => msg += response.data[key], ' ');
      // else errMsg = 'There was a problem logging in.';

      setErrorMessage(errMsg);
      setLoading(false);
    });
  }

  return (
    <>
      <Topbar hideRight />
      <div className='mmb-container'>
        <div className='bg-white w-100 d-flex h-100 align-items-center flex-column'>
          <div className='d-flex flex-column align-items-center text-center mt-6' style={{ maxWidth: '600px' }}>
            <div>
              You are requesting to unsubscribe
              <span className="fw-bold ms-1 me-1 text=bold">
                {shopper.email}
              </span>
              from receiving future email messages.
            </div>
            <div className="mt-4">Please click the confirm button below if this is correct.</div>
            {
              // note(@joeysapp): LoaderButton doesn't have ability to take in FontAwesomeIcons as text atm
              !unsub
              ? (
                <LoaderButton
                  className="btn btn-primary fw-bold mt-5 ps-6 pe-6"
                  onClick={() => handleClick()}
                  style={{ whiteSpace: 'pre', display: unsub ? 'none' : '' }}
                  disabed={unsub}
                  loading={loading}
                  text="Confirm"
                  loadingText="Confirming..."
                />
              )
              : (
                <div>
                  <button className="fw-bold mt-5 ps-6 pe-6 form-control btn btn-primary" disabled>
                    <FontAwesomeIcon className="ms-2 me-1" icon={solid('check')}/>
                    <span className="me-2">Confirmed</span>
                  </button>
                </div>
              )
            }

            {errorMessage && <span className="text-danger mt-3">{errorMessage}</span>}

          </div>
          <div className="pb-5" style={{ marginTop: '200px' }}>            
            <DisclaimerFooter />
          </div>
        </div>
      </div>
    </>
  );
}

export default OptOut;

