import axios from 'axios';
import { fmtPlan, fmtPlans } from '../common/utils/format';

const getPlanDocuments = (planID, county) => {
  return new Promise((resolve, reject) => {
    return axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/documents/${planID}/${county}/`,
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err);
    });
  });
}

export async function getSnapshotPlan(data) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('SessionToken');

    // DR Post Data shape
    // data = {
    //   CountyFIPS: shopper.county,
    //   Zipcode: shopper.zicode,
    //   Pharmacies: [],
    //   Dosages: [],
    //   EffectiveDate: shopper.effectiveDate,
    //   SubsidyLevel: shopper.subsidyLevel,
    //   SubsidyPercent: shopper.subsidyPercent,
    //   Plans: planIDs.map(p => { return { PlanID: p }}),
    //   AdditionalPlanDataReturn: [0, 1, 2, 3, 4, 5],
    // }

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/summary/`,
      headers: { Authorization: `Token ${token}` },
      data,
    }).then(async function(resPlans) {
      let [plan] = fmtPlans(resPlans.data.PlanInfo);
      plan.quoteID = data.QuoteID; // Used as a key in QuoteList
      getPlanDocuments(plan.planID, data.CountyFIPS)
        .then((planDocuments) => {
          plan.plan.PlanDocuments = planDocuments;
        }).catch(() => {
          plan.plan.PlanDocuments = [];
        }).finally(() => {
          resolve(plan);
        });
    });
  });
}

// uses docurobot plan id, not cps id
// - only occurrence is getting linked a cpsID URL - does that still happen?
export function getPlan(planID, county, data = {}) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('SessionToken');
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/${planID}/`,
      headers: { Authorization: `Token ${token}` },
    }).then((resPlans) => {
      if (county) {
        getPlanDocuments(planID, county)
          .then((resDocs) => {
            resPlans.data.PlanDocuments = resDocs;
            resolve(fmtPlan(resPlans.data));
          }).catch((err) => {
            resPlans.data.PlanDocuments = [];
            resolve(fmtPlan(resPlans.data));
          });
      } else {
        resolve(fmtPlan(resPlans.data));
      }
    }).catch((err) => {
      reject(err);
    });
  });
};

export function getAdditionalPlanInfo(planID, countyFips) {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plan/${planID}/${countyFips}/customerplan/`,
    headers: { Authorization: `Token ${token}` },
  })
    .then(({ data }) => data[0] || {})
    .catch(() => {});
};

export function getPlanByCPS(cpsID, planYear, county) {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plan/${cpsID}/${planYear}/`,
    headers: { Authorization: `Token ${token}` },
  }).then(async function(res) {
    let [plan] = fmtPlan(res.data);
    if (county) {
      plan.plan.PlanDocuments = await getPlanDocuments(plan.planID, county);
    }
    return plan;
  });
}
