import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TextInput } from './VerifyIdentity';
import { LoaderButton } from '../../common/components';

// Actions
import handleLogin from '../../utils/handleLogin';

// Components
import Topbar from '../../components/Topbar';
import DisclaimerFooter from '../../components/DisclaimerFooter';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // handle user navigating to /login but is already logged in
  });

  const [ email, setEmail ] = useState();
  const [ password, setPassword ] = useState();

  const [ emailErrs, setEmailErrs ] = useState();
  const [ pwErrs, setPwErrs ] = useState();
  const [ fadeMessage, setFadeMessage ] = useState();

  const [ loginErrs, setLoginErrs ] = useState();
  const [ loginLoading, setLoginLoading ] = useState();

  const inputs = [
    {
      label: 'Email',
      value: email,
      onChange: (e => {
        setEmail(e.target.value);
        setEmailErrs();
        setFadeMessage('Email is required.');
        setTimeout(() => { setFadeMessage() }, 1000);
      }),
      onEnter: (() => handleLoginWrapper()),
      errors: emailErrs,
      fadeMessage,
    },
    {
      label: 'Password',
      toggleVisibility: true,
      value: password,
      onChange: (e => {
        setPassword(e.target.value);
        setPwErrs();
        setFadeMessage('Password is required.');
        setTimeout(() => { setFadeMessage() }, 1000);
      }),
      onEnter: (() => handleLoginWrapper()),
      errors: pwErrs,
      fadeMessage,
    }
  ];

  async function handleLoginWrapper() {
    let redirect = '/quotes';
    if (searchParams.get('redirect')) redirect = (window.location.search).slice('?redirect='.length);

    const hasErrors = emailErrs?.length || pwErrs?.length;
    if (!email || !password) {
      if (!email) setEmailErrs('Email is required');
      if (!password) setPwErrs('Password is required');
    } else if (!hasErrors) {
      setLoginLoading(true);
      try {
        // this helper runs the api call, sets the desired data in redux, and throws granular errors
        await handleLogin({ email, password, dispatch });
        navigate(redirect);
      } catch ({ message }) {
        setLoginErrs(message);
      }
      setLoginLoading(false);
    }
  };

  const site = process.env.REACT_APP_HOSTNAME || window.location.hostname;
  const url = process.env.REACT_APP_MMB_API_URL;
  const forgotPasswordLink = `${url}/reg/password_reset/?site=${site}&linkback=${url}`;

  return (
    <>
      <Topbar />
      <div className='mmb-container'>
        <div className="text-serif fw-bold fs-1 m-3 mb-0">
          Sign in
        </div>
        <div className="text-center m-4 d-flex flex-column" style={{ maxWidth: '600px' }}>
          <span>
            Please sign into your account
            to access your online application.
          </span>
        </div>
        <div className="d-flex flex-column align-items-left" style={{ width: '300px' }}>
          {inputs.map((i) => (
            <TextInput key={i.label} className="mb-1" {...i} />
          ))}
          <div className="mb-3" style={{ whiteSpace: 'pre' }}>
            <LoaderButton
              text="Sign in"
              loading={loginLoading}
              loadingText="Signing in.."
              className="btn btn-primary fw-bold w-100 mt-2"
              onClick={() => handleLoginWrapper()}
              errors={loginErrs}
            />
          </div>
        </div>
        <div
          className="text-center mt-3 d-flex flex-column p-2 h-link"
          style={{ maxWidth: '600px', cursor: 'pointer' }}
          onClick={() => window.open(forgotPasswordLink, '_blank') }
        >
          <span className="ms-1 text-decoration-underline text-blue">
            Forgot your password?
          </span>
        </div>
        <div
          className="text-center mb-3 mt-1 d-flex flex-column p-2 h-link"
          style={{ maxWidth: '600px', cursor: 'pointer' }}
          onClick={() => navigate('/signup') }
        >
          <span>Don't have an account?</span>
          <span className="ms-1 text-decoration-underline text-blue">
            Create account
          </span>
        </div>
        <DisclaimerFooter />
      </div>
    </>
  );
}

export default Login;
