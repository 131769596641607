import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function WithGA({ children, options = {} }) {
  const location = useLocation();
  const trackPage = (page) => {
    // ReactGA.set({ page, ...options });
    ReactGA.send('pageview');
  };  
  useEffect(() => trackPage(location.pathname), [location]);

  return children;
}

export default WithGA;
