import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Topbar from '../../components/Topbar';
import DisclaimerFooter from '../../components/DisclaimerFooter';
import { TextInput } from '../landing/VerifyIdentity';

function RecLanding() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const shopper = useSelector(store => store.shopper);
  const { completedRec } = useSelector(store => store.rec);
  let splashSrc = '/img/splash.jpg';
  useEffect(() => {
    // set splash image in theme
    // NOTE(santeyio): ???
    splashSrc = splashSrc;    
    // shopper is already logged in and has not previously set zip
    if (shopper && shopper.zipcode) {
      setZip(shopper.zipcode);
    }
  }, [ shopper ]);

  const [ errs, setErrs ] = useState();
  const [ validMsg, setValidMsg ] = useState();

  // current UX: values are cleared (only saved in redux for plan rec)
  const [ zip, setZip ] = useState();

  const handleSubmit = (e) => {
    const onlyAlphaNum = !/\D/.test(zip);
    if (!zip || !onlyAlphaNum || zip.length !== 5) {
      setErrs(['Please enter a valid zip code.']);
    } else {
      setErrs([]);

      // shopper already gone through flow, we have their info
      if (completedRec) {
        navigate('/recommended-plans');
      } else {
        navigate('/questionnaire');
      }
    }
  };

  return (
    <>
      <Topbar showAgent />
      <div className='mmb-container show-agent'>
        <div className='bg-white w-100 d-flex h-100 flex-m-row-col'>
          <div id="landing-img" className="position-relative">
            <img
              className="position-absolute"
              src={splashSrc}
              aria-label="couple enjoying medicare recommendation"
            />
          </div>
          <div id="landing-text">
            <div className="text-serif fw-bold fs-1 m-3 mb-0 text-center">
              Get a personalized Medicare plan recommendation
            </div>
            <div className="text-center m-4 d-flex flex-column align-items-center" style={{ maxWidth: '650px' }}>
              Plans vary by region. Enter your ZIP code to see plans in your area.
              <TextInput
                className="mt-4"
                style={{ minWidth: '300px' }}
                value={zip}
                errors={errs}
                placeholder="Enter ZIP..."
                onChange={(e) => {
                  if (e.target.value.length <= 5) {
                    setZip(e.target.value);
                    // if shopper changes zip, we let them get more plan recs
                    dispatch({ type: 'SET_REC_FIELD', payload: { completedRec: false }});

                    // if shopper typing w/ errs, turn error red->green and then hide it
                    if (e.target.value && errs && !/\D/.test(e.target.value)) {
                      setErrs();
                      setValidMsg('Please enter a valid zip code.');
                      setTimeout(() => { setValidMsg() }, 1000);
                    }
                  }
                }}
                fadeMessage={validMsg}
              />
              <button
                className="btn btn-primary fw-bold mt-4" style={{ width: '300px' }}
                onClick={() => handleSubmit()}
                text="Get Recommendation"
                aria-label="submit zipcode and get recommendations"
              >
                Get Recommendation
              </button>
              <div className="mt-4">
                <DisclaimerFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecLanding;
