import React from 'react';

function CustomMessage({ text }) {
  return text ? (
    <div role="alert" className="alert alert-warning">
      <div className="row">
        <div className="d-flex col-md-11">
          {text}
        </div>
      </div>
    </div>
  ) : null;
}

export default CustomMessage;
