import React, { useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import './BenefitDropdown.scss';
const BenefitDropdown = ({
  label = '',
  className,
  // Shape is { component, label, text } where text can be used for keys/headers
  benefits = {},
  currentBenefits = {}, // Views from ../compare
}) => {
  const [ open, toggleOpen ] = useState(false);
  return benefits && benefits.component ? (
    <>
      <div className={`dd ${open ? 'open' : ''} shadow-sm ${className}`} key={`benefits-${label}`}>
        <div
          id={label}
          className="dd-header d-flex fw-bold border shadow-sm p-3 ps-4 w-100 user-select-none cursor-pointer align-items-center"
          onClick={() => {
            toggleOpen(!open);
          }}
          >
          <span className="user-select-none">
            {HTMLReactParser(label)}
          </span>
          <FontAwesomeIcon
            icon={open ? solid('angle-up') : solid('angle-down')}
            className="ms-auto me-2 fs-2"
          />
        </div>
        <div className="dd-content">
            {label === 'Part B drugs' ? (
              <>
                <div className="dd-row p-3 d-block">
                  <span className="fw-bold">Important message about what you pay for Vaccines</span>
                  <span>
                    - These Medicare plans with
                    prescription drug coverage offer most Part D vaccines at no additional cost to you,
                    even if your plan has a deductible and you haven't paid it.
                    Age and availablity restrictions may apply.
                  </span>
                 </div>
                 <div className="dd-row p-3 pt-0 d-block">
                <span className="fw-bold">Important Message about what you pay for Insulin</span>
                  <span>
                    - For Medicare plans with prescription
                    drug coverage, you won't pay more than $35 for a one-month (up to 30-day) supply of each  
                    Part D insulin product covered by this plan, no matter what cost-sharing tier it's on,
                    even if your plan has a deductible and you haven't paid it.
                  </span>
                </div>
              </>
            ) : null}
          <div className="d-flex mt-auto mb-auto">
            {currentBenefits.component}
            {benefits.component}
          </div>
        </div>
      </div>

      <div className="" id="dd-footer"/>
    </>
  ) : null;
}

export default BenefitDropdown;
