import moment from 'moment-timezone';

export function retypeStr(str) {
  if (str === 'false') {
    return false;
  } if (str === 'true') {
    return true;
  } if (str === 'undefined') {
    return undefined;
  }
  return String(str);
}

export function evaluateConditions({ currentValues, conditions = [] }) {
  // conditions is an array that would look something like this:
  //
  // [ 'onmedicaid|true', '&&', 'isDSNP|true' ]
  // or...
  // [ 'evalues_val|test', '||', 'evalues_other_val|undefined' ]
  //
  // - you can also use '!|' for not equals evaluation, e.g. [ 'evalues_val!|test' ]
  // - you can also use '*|' for an 'includes' evaluation. [ 'evalues_val*|test' ] will
  //     evaluate like `evalues_val.includes('test')`. To do a negative 'includes'
  //     evaluation use '!*|', which evaluates like `!evalues_val.includes('test')`
  // - you can also use 'todayIsIn|MM-DD,MM-DD' with the first MM-DD being the lower
  //     month-day and the second MM-DD being the higher month-day of the range
  //
  // NOTE(santeyio): It would be simpler to use js eval() however use of eval()
  // is HIGHLY discouraged in all cases for security reasons.

  // if there are no conditions specified then default to returning a 'true' eval
  if (!conditions.length) return true;

  let evaluation = false;

  const evaluated = conditions.map(condition => {

    if (condition === '&&') {
      return '&&';
    } if (condition === '||') {
      return '||';

    // if condition is blank then evaluate it as true
    } if (condition === '') {
      return true;
    }

    let fieldToCheckValue = '';
    let useInequality = false;
    let useNegation = false;
    let useInclusion = false;
    let splitName = '';
    let retypedValue = '';

    const [ fieldName, valueToCompare ] = condition.split('|');

    // check to see if it's a date comparison
    if (fieldName.slice(0, 9) === 'todayIsIn') {
      if (fieldName.slice(-1) === '!') useNegation = true;

      const today = moment.tz(moment(), moment.tz.guess());
      const [ lowerDate, higherDate ] = valueToCompare.split(',');
      const compareDateLower = moment.tz(moment(lowerDate, 'MM-DD'), moment.tz.guess());
      const compareDateHigher = moment.tz(moment(higherDate, 'MM-DD'), moment.tz.guess());

      if ((today >= compareDateLower) && (today <= compareDateHigher)) {
        if (useNegation) return false;
        return true;
      }

      // condition evaluates as false, so...
      if (useNegation) return true;
      return false;
    }

    // check to see if this is an inequality comparison
    if (fieldName.at(-1) === '!') {
      useInequality = true;
      splitName = fieldName.slice(0, -1).split('.');

    // check to see if we should do 'includes' operation instead of 'equality' comparison
    } else if (fieldName.at(-1) === '*') {
      useInclusion = true;
      if (fieldName.at(-2) === '!') {
        useNegation = true;
        splitName = fieldName.slice(0, -2).split('.');
      } else {
        splitName = fieldName.slice(0, -1).split('.');
      }

    // fallback to normal equality comparison
    } else {
      splitName = fieldName.split('.');
    }

    // find the value we're looking for in the enrollment values object
    try {
      if (splitName.length === 1) {
        fieldToCheckValue = currentValues[splitName[0]];
      } else if (splitName.length === 2) {
        fieldToCheckValue = currentValues[splitName[0]][splitName[1]];
      } else if (splitName.length === 3) {
        fieldToCheckValue = currentValues[splitName[0]][splitName[1]][splitName[2]];
      }

    // if the value doesn't exist, we return an evaluation status of false
    } catch (err) {
      console.error(err);
      return false;
    }

    retypedValue = retypeStr(valueToCompare);

    // If we're doing an 'includes' operation. Note that we do not retype booleans,
    //   null, or undefined values in the case of inclusion. We always cast both values to string.
    if (useInclusion) {
      if (useNegation) return !String(fieldToCheckValue).toLowerCase().includes(valueToCompare.toLowerCase());
      return String(fieldToCheckValue).toLowerCase().includes(valueToCompare.toLowerCase());
    }

    // fallback to default of using an equality comparison
    if ((typeof retypedValue === 'string') && (typeof fieldToCheckValue === 'string')) {
      if (useInequality) return (fieldToCheckValue.toLowerCase() !== retypedValue.toLowerCase());
      return (fieldToCheckValue.toLowerCase() === retypedValue.toLowerCase());
    }
    // if fieldToCheckValue is a number we need to cast it to a string for comparison
    if ((typeof fieldToCheckValue === 'number') && (typeof retypedValue === 'string')) {
      if (useInequality) return (String(fieldToCheckValue).toLowerCase() !== retypedValue.toLowerCase());
      return (String(fieldToCheckValue).toLowerCase() === retypedValue.toLowerCase());
    }
    // final fallback
    if (useInequality) return (fieldToCheckValue !== retypeStr(valueToCompare));
    return (fieldToCheckValue === retypeStr(valueToCompare));

  });

  // now evaluated looks something like
  // [ true, '&&', false, '||', true ]
  //
  // the evaluation logic evaluate conditions in order by condensing
  // conditions iterating through the array. for example, this array of conditions:
  //
  // [ true, '&&', false, '||', true, '&&', false ]
  //
  // would evaluate like this:
  //
  // [ ((true && false) || true ) && false ]
  //
  // so make sure you pay attention to how you order the conditions!

  if (evaluated.includes('&&') || evaluated.includes('||')) {
    evaluated.forEach((val, i) => {
      // these evaluate all condition pairs after the first in the array
      if (val === '&&' && i >= 2) {
        evaluation = (evaluation && evaluated[i + 1]);
      } else if (val === '||' && i >= 2) {
        evaluation = (evaluation || evaluated[i + 1]);
      // these evaluate the first pair of conditions in the array
      } else if (val === '&&' && i === 1) {
        evaluation = (evaluated[i - 1] && evaluated[i + 1]);
      } else if (val === '||' && i === 1) {
        evaluation = (evaluated[i - 1] || evaluated[i + 1]);
      // set the initial evaluation to the first value in the array
      } else if (i === 0) {
        evaluation = val;
      }
    });

  // if we don't have any '&&' or '||' statements in the array
  // of conditions then we assume a logical 'or' operator between
  // statements.
  } else {
    evaluation = evaluated.includes(true);
  }

  return evaluation;
}
