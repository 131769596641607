const initialState = {
  zip: '',
  prescriptionDrugCount: '',
  currentlyEnrolled: '',
  receivesHelpPaying: '',
  pageIdx: 0,

  completedRec: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_REC_FIELD':
      return { ...state, ...action.payload };
    case 'CLEAR_REC':
      return initialState;
    default:
      return state;
  }
}
