/* eslint-disable quote-props */
import * as moment from 'moment-timezone';
import { yesNo } from './mapping-tools';
import { stripPhone, cleanDate } from './formatting';
import getAgentID from './getAgentID';

export default ({
  agent,
  quote,
  fields: res,
  plan,
  theme,
  confirmationNumber,
}) => {
  const { useCarrierPerms } = theme;

  let agentID;
  let agentNPN;
  if (useCarrierPerms) {
    const ids = getAgentID({ agent, quote });
    agentID = ids.agentID;
    agentNPN = ids.agentNPN;
  } else {
    agentNPN = agent.npn;
  }

  const isSEP = res.enrolltype === 'SEP';
  const sepDate = res.sep_date ? moment(res.sep_date).format('MMDDYYYY') : '';
  const formattedSEP = isSEP ? (`${res.sep_code} ${sepDate}`.trim()) : '';
  const premiumDirectPay = yesNo(!['rrb', 'ssi'].includes(res.paymeth));
  let premiumDeductedSS = '';
  let premiumDeductedRRB = '';
  let premiumWithhold = '';
  if (res.paymeth === 'rrb') {
    premiumWithhold = 'RRB';
    premiumDeductedSS = 'No';
    premiumDeductedRRB = 'Yes';
  }
  if (res.paymeth === 'ssi') {
    premiumWithhold = 'SSA';
    premiumDeductedSS = 'Yes';
    premiumDeductedRRB = 'No';
  }
  const planYear = plan.PlanYear;
  const isEFT = res.paymeth === 'eft';
  const isCouponBook = ((res.paymeth === 'direct') || !res.payment); // if paymeth is not populated default to coupon book
  const eftActType = (res.eft_acttype === 'checking') ? '1' : '2';
  const effectiveDate = moment(res.effective_date).format('MMDDYYYY');
  const ltcfAddress = res.ltcf
    ? `${res.ltcf_addr_street1}${res.ltcf_addr_street2 ? ` ${res.ltcf_addr_street2}` : ''}, ${res.ltcf_addr_city}, ${res.ltcf_addr_state} ${res.ltcf_addr_zip}`
    : '';
  const submitTimeInPacific = moment.tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss');

  let race = '';
  let ethnicity = '';

  if (res.race) {
    race = Object.entries(res.race).map(([k, v]) => (v ? k : '')).filter(v => v).join(',');
  }
  if (res.ethnicity) {
    ethnicity = Object.entries(res.ethnicity).map(([k, v]) => (v ? k : '')).filter(v => v).join(',');
  }

  const formattedData = [
    { col: 'ConfirmationNumber', val: confirmationNumber },
    { col: 'SubmitDate', val: moment().format('MMDDYYYY') },
    { col: 'ContractID', val: plan.ExternalContractID },
    { col: 'PlanID', val: plan.ExternalPlanID },
    { col: 'SegmentID', val: plan.ExternalSegmentID },
    { col: 'ApplicantTitle', val: '' },
    { col: 'ApplicantFirstName', val: res.first_name },
    { col: 'ApplicantMiddleInitial', val: res.middle_name },
    { col: 'ApplicantLastName', val: res.last_name },
    { col: 'ApplicantBirthDate', val: moment(res.birthdate).format('MMDDYYYY') },
    { col: 'ApplicantSex', val: (res.gender === 'male') ? 'M' : 'F' },
    { col: 'ApplicantAddress1', val: res.street1 },
    { col: 'ApplicantAddress2', val: res.street2 },
    { col: 'ApplicantAddress3', val: '' },
    { col: 'ApplicantCity', val: (res.city || '').slice(0, 30) },
    { col: 'ApplicantCounty', val: res.county_name },
    { col: 'ApplicantState', val: res.state.toUpperCase() },
    { col: 'ApplicantZip', val: res.zipcode },
    { col: 'ApplicantPhone', val: stripPhone(res.home_phone) },
    { col: 'ApplicantEmailAddress', val: res.email },
    { col: 'ApplicantMBI', val: res.medicare_id },
    { col: 'ApplicantSSN', val: '' }, // SSN of applicant for SNP DE plans only
    { col: 'MailingAddress1', val: res.samemail ? res.street1 : res.mailstreet1 },
    { col: 'MailingAddress2', val: res.samemail ? res.street2 : res.mailstreet2 },
    { col: 'MailingAddress3', val: '' },
    { col: 'MailingCity', val: res.samemail ? res.city : res.mailcity },
    { col: 'MailingState', val: res.samemail ? res.state : res.mailstate },
    { col: 'MailingZip', val: res.samemail ? res.zipcode : res.mailzip },
    { col: 'MedicarePartA', val: cleanDate(res.part_a_effective_date, 'MMYYYY') },
    { col: 'MedicarePartB', val: cleanDate(res.part_b_effective_date, 'MMYYYY') },
    { col: 'EmergencyContact', val: res.hasemer ? `${res.emerfname} ${res.emerlname}` : '' },
    { col: 'EmergencyPhone', val: res.hasemer ? stripPhone(res.emertel) : '' },
    { col: 'EmergencyRelationship', val: res.hasemer ? res.emerrel : '' },
    { col: 'PremiumDeducted_SS', val: premiumDeductedSS },
    // (spec says 'field only appears if field 39 'LongTerm' is 'Yes' or 58 'ChronicSpecialNeedsPlanProviderName' is populated)
    { col: 'AdditionalProviderInformation', val: '' },
    { col: 'OtherCoverage', val: yesNo(res.othercoverage) }, // applies to PDP, MAPD
    { col: 'OtherCoverageName', val: res.othercoverage ? res.othercoverage_name : '' },
    { col: 'OtherCoverageID', val: res.othercoverage ? res.othercoverage_num : '' },
    { col: 'LongTerm', val: yesNo(res.ltcf) },
    { col: 'LongTermName', val: res.ltcf ? res.ltcf_name : '' },
    { col: 'LongTermAddress', val: ltcfAddress },
    { col: 'LongTermPhone', val: res.ltcf ? stripPhone(res.ltcf_tel) : '' },
    { col: 'AuthorizedRepName', val: res.ispoa ? `${res.poa_fname} ${res.poa_lname}` : '' },
    { col: 'AuthorizedRepAddress', val: res.ispoa ? `${res.poa_addr_street1} ${res.poa_addr_street2 || ''}`.trim() : '' },
    { col: 'AuthorizedRepCity', val: res.ispoa ? res.poa_addr_city : '' },
    { col: 'AuthorizedRepState', val: res.ispoa ? res.poa_addr_state : '' },
    { col: 'AuthorizedRepZip', val: res.ispoa ? res.poa_addr_zip : '' },
    { col: 'AuthorizedRepPhone', val: res.ispoa ? res.poa_tel : '' },
    { col: 'AuthorizedRepRelationship', val: res.ispoa ? res.poa_rel : '' },
    { col: 'Language', val: res.preflang ? res.preflang : '' },
    { col: 'ESRD', val: '' },
    { col: 'StateMedicaid', val: res.onmedicaid ? res.medicaidnum : 'No' },
    { col: 'WorkStatus', val: yesNo(res.willwork) },
    { col: 'PrimaryCarePhysician', val: (res.pcp_name || '') },
    { col: 'OtherCoverageGroup', val: res.othercoverage_group },
    { col: 'AgentID', val: agentID },
    { col: 'SubmitTime', val: submitTimeInPacific },
    { col: 'ChronicSpecialNeedsPlanProviderName', val: (res.csnp_provider_name || '') },
    { col: 'ChronicSpecialNeedsPlanProviderAddress', val: (res.csnp_provider_address || '') },
    { col: 'ChronicSpecialNeedsPlanProviderCity', val: (res.csnp_provider_city || '') },
    { col: 'ChronicSpecialNeedsPlanProviderState', val: (res.csnp_provider_state || '') },
    { col: 'ChronicSpecialNeedsPlanProviderZIP', val: (res.csnp_provider_zip || '') },
    { col: 'ChronicSpecialNeedsPlanProviderPhonenumber', val: (res.csnp_provider_tel || '') },
    { col: 'ChronicSpecialNeedsPlanProviderFax', val: (res.csnp_provider_fax || '') },
    { col: 'SEPReasonCode', val: formattedSEP },
    { col: 'SEPCMSReasonCODE', val: '' }, // was instructed to leave this blank by Molina
    { col: 'PremiumDirectPay', val: premiumDirectPay },
    { col: 'EnrollmentPlanYear', val: planYear },
    { col: 'PremiumWithhold', val: premiumWithhold },
    { col: 'SpouseWorkStatus', val: yesNo(res.willworkspouse) },
    { col: 'AccessibilityFormat', val: (res.altwritten || '') },
    { col: 'EmailOptIn', val: yesNo(res.okemail) },
    { col: 'TextCallOptIn', val: '' }, // not a required field and not populated on our form so excluding
    { col: 'Blank7', val: '' },
    { col: 'PremiumDeductEFT', val: yesNo(res.paymeth === 'eft') },
    { col: 'PremiumDeductCreditCard', val: yesNo(res.paymeth === 'cc') },
    { col: 'PremiumDeductSS', val: yesNo(res.paymeth === 'ssi') },
    { col: 'PremiumDeductBill', val: yesNo(isCouponBook) },
    { col: 'AgentFirstName', val: agent.firstName },
    { col: 'AgentLastName', val: agent.lastName },
    { col: 'AgentNationalID', val: agentNPN },
    { col: 'AgentStateLicenseNumber', val: '' }, // we don't collect/store state license info on agents
    { col: 'AgentIDHealthPlan', val: '' }, // N/A according to cigna doc
    { col: 'AgencyName', val: `${agent.firstName} ${agent.lastName}` }, // was instructed to use agent name by Molina
    { col: 'AgencyID', val: agentID }, // was instructed to use agent molina rts id by Molina
    { col: 'SourceID', val: 'mymedicarebot' },
    { col: 'OEC_InsertedWhen', val: '' }, // instructed by Molina to leave this blank
    { col: 'ProviderID', val: res.pcp_num || '' }, // should be providers NPI
    { col: 'MedicalGroupID', val: '' }, // not applicable?
    { col: 'AccountName', val: isEFT ? res.eft_actname : '' },
    { col: 'BankRoutingNum', val: isEFT ? res.eft_routing : '' },
    { col: 'BankAccountNum', val: isEFT ? res.eft_actnum : '' },
    { col: 'AccountType', val: isEFT ? eftActType : '' }, // 1 = checking 2 = savings
    { col: 'AltPhoneNumber', val: '' }, // don't collect this info
    { col: 'AlernateLanguage', val: '' }, // applicant's speaking language. MMB doesn't collect this info.
    { col: 'AltFormats', val: (res.altwritten || '') },
    { col: 'ElectronicEOCWKit', val: '' }, // don't collect this info
    { col: 'ProposedEffdate', val: effectiveDate }, // don't collect this info
    { col: 'ExistingMember', val: '' }, // don't collect this info
    { col: 'LangPreference', val: (res.preflang || '') },
    { col: 'PCP_Address', val: res.pcp_street1 },
    { col: 'Race', val: race },
    { col: 'Ethnicity', val: ethnicity },
    { col: '', val: '' }, // ?
    { col: 'Electonic_Materials_EmailAddress', val: '' }, // not collected
    { col: 'PremiumDeducted_RRB', val: premiumDeductedRRB },
    { col: 'Question14', val: '' },
    { col: 'Question15', val: '' },
  ];

  return { formattedData, agentID };
};
