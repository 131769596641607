import axios from 'axios';
import moment from 'moment';
import { store } from '../store';

export function validateClientVersion() {
  return new Promise((resolve) => {
    axios({
      url: '/version.json',
      params: { cb: Date.now() }, // Cache-buster.
    }).then(res => {
      if (res.status === 200) {
        const clientVersion = store.getState().session.version;
        const latestVersion = res.data.version;

        if (latestVersion !== clientVersion) {
          store.dispatch({ type: 'SET_MODAL', payload: { staleClientModal: true }});
          store.dispatch({ type: 'UPDATE_LATEST_VERSION', payload: latestVersion });
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(`[Client Validation ERR] ${res.status}: ${res.statusText}`);
      }
    })
  });
}

export function checkIfLoggedIn() {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('SessionToken');
    if (!token) {
      // Don't bother hitting the api if no token is set.
      reject(new Error('No valid token available.'));
    }
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/shopper/check-if-logged-in/`,
      headers: { Authorization: `Token ${token}` },
      params: { site: process.env.REACT_APP_HOSTNAME || window.location.hostname },
    }).then((res) => {
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  });
}

export const validateSession = () => {
  return new Promise((resolve, reject) => {
    const currentState = store.getState();
    const currentTime = new Date();

    const { created, lifespan } = currentState.session;
    // const { activeUserRefreshTokenMinutes } = currentState.theme;
    const secondsSinceAuth = (currentTime - new Date(created)) / 1000;

    if (secondsSinceAuth < lifespan) {
      resolve();
    } else {
      reject();
    }
  });
}

// User can click this in components/SssionModal, displayed when token about to expire
export const refreshSession = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('SessionToken');
    return axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/token-auth/refresh/`,
      method: 'post',
      headers: { Authorization: `Token ${token}` },
      data: { token },
    }).then(({ status, data }) => {
      if (status === 201) {
        const { token, created, lifespan } = data;       
        localStorage.setItem('SessionToken', token);
        store.dispatch({ type: 'SET_TOKEN_CREATED', payload: created });
        store.dispatch({ type: 'SET_TOKEN_LIFESPAN', payload: lifespan });        
        resolve();
      }
    }).catch(err => {
      store.dispatch({ type: 'CLEAR_SESSION' });
      localStorage.removeItem('SessionToken');
      window.location = '/login';
      reject();
    });
  });
}
