import { loginShopper, getShopper } from '../actions/shopper';
import { getAgent } from '../actions/agent';
import { getFingerprint } from '../common/utils/fingerprint';

export default async function handleLogin({ email, password, dispatch }) {
  const fingerprint = await getFingerprint();

  // Clearing also occurs in TopBar mount - address this in session/theming
  dispatch({ type: 'CLEAR_SHOPPER' }); 
  dispatch({ type: 'CLEAR_ENROLLMENT_VALUES' }); 
  dispatch({ type: 'CLEAR_ENROLLMENT_VALIDATION' }); 

  try {
    const loginResponse = await loginShopper({ email, password, fingerprint }); 
    const { username, token, created, lifespan } = loginResponse;
    localStorage.setItem('SessionToken', token);

    // set shopper info in redux
    try {
      const shopper = await getShopper(username); 
      const { quotes, userWhoCreated: agentUsername } = shopper;
      dispatch({ type: 'SET_SHOPPER', payload: shopper }); 
      dispatch({ type: 'SET_TOKEN_CREATED', payload: created }); 
      dispatch({ type: 'SET_TOKEN_LIFESPAN', payload: lifespan }); 

      // set agent info in redux
      try {
        const agent = await getAgent(agentUsername); 
        dispatch({ type: 'SET_AGENT', payload: agent }); 
      } catch {
        throw new Error('Unable to load associated agent information');
      }   

    } catch { // Authorized fine but is not a shopper
      localStorage.removeItem('SessionToken');
      throw new Error('The consumer portal is not intended for use by field agents.');
    };  

  // misc errors
  } catch ({ response: { data: { Error: apiMsg } = {} } = {} }) {
    let errMsg = 'There was a problem verifying your credentials.';
    if (apiMsg === 'User does not have shopper access') {
      errMsg = 'The consumer portal is not intended for use by field agents.';
    }
    throw new Error(errMsg)
  }
};  
