const initialState = {

};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_SHOPPER':
      return { ...action.payload };
    case 'SET_SHOPPER_FIELD':
      return { ...state, ...action.payload };
    case 'CLEAR_SHOPPER_QUOTES':
      return { ...state, quotes: [] };
    case 'ADD_SHOPPER_QUOTE':
      return { ...state, quotes: [...state.quotes, action.payload] };
    case 'LOAD_ENROLLMENT':
      const quoteID = action.payload.quoteID;
      return {
        ...state,
        quotes: state.quotes.map((quote) => {
          if (quote.quoteID === quoteID) {
            quote['enrollment'] = action.payload;
          }
          return quote;
        }),
      };
    case 'CLEAR_SHOPPER':
      return initialState;
    default:
      return state;
  }
}
