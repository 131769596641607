import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// components
import DisclaimerFooter from '../../../../components/DisclaimerFooter';

// utils
import { prettifyPartialPhone, toCurrency } from '../../../../common/utils/prettify';

import '../../styles.scss';
import {getPlanCarrierDisplay} from "../../../../utils/general";

function Confirmation ({}) {

  const { email } = useSelector(store => store.shopper);
  const {
    plan: {
      cpsID,
      plan: {
        TotalPlanPremium,
      },
      planName,
      planType,
    },
    quote,
    quote: { effectiveDate },
    confirmationNumber,
    customMaterialID,
    customLastUpdate,
    hideTPMODisclaimer,
  } = useSelector(store => store.enrollment);
  const {
    firstName = '[first name not available]',
    lastName = '[last name not available]',
    mobilePhone,
    homePhone,
    email: agentEmail = '[email not available]',
  } = useSelector(store => store.agent);

  const phone = (mobilePhone || homePhone);
  const agentName = `${firstName} ${lastName}`;
  const agentNumber = phone ? prettifyPartialPhone(phone) : '[phone not available]';
  const displayName = getPlanCarrierDisplay({ quote });

  return (
    <div className="container-fluid">
      <div
        className="row justify-content-center pb-5"
        style={{ backgroundColor: 'var(--bs-gray-100)' }}
      >
        <div
          className="d-flex mt-5 justify-content-center text-center"
        >
          <div className="col-md-6" >
            <FontAwesomeIcon icon={solid('check')} size="2x" className="text-success" />
            <h4 className="fw-bold mt-2">Your application has been submitted</h4>
            <p>You can now close this page</p>
            <p className="text-center">
              Please look for a confirmation email sent to <b>{email}</b>.
              The healthcare company will also follow up with you shortly.
            </p>
            <p className="text-center">
              Your confirmation number is: <b>{confirmationNumber}</b>
            </p>
          </div>
        </div>
        <div className="d-flex col-md-6 confirmation-box p-4 shadow">
          <div className="row row-cols-1">
            <div className="d-flex col justify-content-between">
              <b style={{ color: 'var(--bs-gray-700)' }}>
                {displayName}
              </b>
            </div>
            <div className="d-flex col justify-content-between fw-bold mt-2">
              {planName}, {planType}, {cpsID}
            </div>
            <div className="d-flex col justify-content-between text-muted">
              Effective date: {moment(effectiveDate).format('MMMM D, YYYY')}
            </div>
            <div className="d-flex col justify-content-between mt-4">
              <span>Monthly premium:</span> 
              <span>{toCurrency(TotalPlanPremium)}</span>
            </div>
            <div className="d-flex col justify-content-between mt-3">
              <h4 className="fw-bold">Total monthly cost:</h4> 
              <h4 className="fw-bold">{toCurrency(TotalPlanPremium)}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div
          className="d-flex mt-5 justify-content-center text-center"
        >
          <div className="col-md-6" >
            If you have additional questions, you can reach your Medicare Advantage agent <b className='text-capitalize'>{agentName}</b> by calling <b>{agentNumber}</b> or emailing <b>{agentEmail}</b>.
          </div>
        </div>
        <DisclaimerFooter
          className="mt-5"
          style={{ justifyContent: 'center' }}
          customMaterialID={customMaterialID}
          customLastUpdate={customLastUpdate}
          showTPMODisclaimer={!hideTPMODisclaimer}
        />
      </div>
    </div>
  );  
}

export default Confirmation;
