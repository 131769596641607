import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getAllFields, isRequired } from '../../../../utils/enroll';

import '../../styles.scss';

function Header ({}) {
  const { form, plan: { planName, cpsID } } = useSelector(store => store.enrollment);
  const { evalid, evalues } = useSelector(store => store);

  // const [ requiredCount, setRequiredCount ] = useState(0);
  // const [ validCount, setValidCount ] = useState(0);
  const [ progressPercent, setProgressPercent ] = useState(0);
  let requiredCount = 0;
  let validCount = 0; 

  // Only recalculate progress when evalid list changes.
  //   It would be a waste of resources to calculate on
  //   every evalues change.
  useEffect(() => {
    const allFields = getAllFields(form);

    allFields.forEach(field => {
      const required = isRequired({
        form,
        name: field.name,
        currentValues: evalues,
      });

      if (required) {
        // setRequiredCount(requiredCount + 1);
        requiredCount += 1;

        // only count validity of required fields
        const valid = evalid[field.name];
        if (valid) {
          // setValidCount(validCount + 1);
          validCount += 1;
        }
      }
    });

    const percent = Math.round(100 * (validCount / requiredCount));
    let weightedPercent;

    if (percent < 10) {
      weightedPercent = percent * 3;
    } else if (percent < 24) {
      weightedPercent = percent * 2;
    } else if (percent < 50) {
      weightedPercent = percent * 1.75;
    } else if (percent < 75) {
      weightedPercent = percent * 1.25;
    } else {
      weightedPercent = percent;
    }

    setProgressPercent(weightedPercent);

  }, [evalid]);

  return (
    <div className="col bg-gray-100 shadow-sm p-3 pb-4">
      <div className="row justify-content-center">
        <h3 className="text-center text-serif fw-bold mb-1">
          You are enrolling in: 
        </h3>
      </div>
      <div className="row justify-content-center">
        <span className="text-center mb-0 fs-5 me-3">
          {planName}
        </span>
        <span className="text-center mb-3 fs-6 ms-3">
          {cpsID}
        </span>
      </div>
      <div className="row justify-content-center">
        <div className="progress px-0" style={{ maxWidth: '650px' }}>
          <div className="progress-bar pbar-bg" style={{ width: `${progressPercent}%` }} />
        </div>
      </div>
    </div>
  );  
}

export default Header;
