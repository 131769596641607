import React from 'react';

import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm me-1" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
}

function LoaderButton({
  className = '',
  style = {},
  text = 'Text',
  loadingText = 'Loading',
  loading,
  onClick = () => {},
  disabled,
  errors = '',
}) {
  const longerText = (text.length > loadingText.length) ? text : loadingText;
  const visibleStyle = {
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '100%',
  };

  // loading state
  if (loading) {
    return (
      <button
        type="button"
        className={className}
        style={{ position: 'relative', ...style }}
        disabled
      >
        <div style={{ position: 'relative' }}>
          <div style={{ visibility: 'hidden' }}>
            <Spinner /> {longerText}
          </div>
          <div style={visibleStyle}>
            <Spinner /> {loadingText}
          </div>
        </div>
      </button>
    );
  }

  // non loading state
  return (
    <>
      <button
        type="button"
        className={className}
        style={style}
        onClick={onClick}
        disabled={disabled}
      >
        <div style={{ position: 'relative' }}>
          <div style={{ visibility: 'hidden' }}>
            <Spinner /> {longerText}
          </div>
          <div style={visibleStyle}>
            {text}
          </div>
        </div>
      </button>
      <div className={`fs-6 mt-1 mb-1 ${errors ? 'd-flex' : 'd-none'}`}>
        <div className={`d-flex align-items-center w-auto ${errors ? 'text-danger' : 'text-success'}`}>
          <FontAwesomeIcon className="fs-5 me-1" icon={faTimes}  style={{ minWidth: '18px' }} />
          {errors}
        </div>
      </div>
    </>
  );
}

export default LoaderButton;
