import React from 'react';
import { useSelector } from 'react-redux';

import {
  handleChange,
  handleValidation,
  getValidClass,
  isRequired,
  getOptions,
  getFieldKey,
  fieldExistsInConfig,
} from '../../../../utils/enroll';

import FormLabel from './FormLabel';

function Select ({
  clone,
  children,
  disabled,
  label,
  name,
}) {

  const form = useSelector(store => store.enrollment.form);
  const evalues = useSelector(store => store.evalues);
  const value = evalues[name];
  const required = isRequired({ form, name, currentValues: evalues });

  const validationRegex = useSelector(store => store.enrollment.validation[name]);
  const valid = useSelector(store => store.evalid[name]);
  const validClass = getValidClass({ valid, required, value });

  const { submittedFlag } = useSelector(store => store.enrollment);
  const isDisabled = (disabled || clone || submittedFlag);

  const options = getOptions(form, name);

  const customLabel = getFieldKey(form, name, 'label');

  // if field doesn't exist, don't show it.
  if (!fieldExistsInConfig(form, name)) return null;

  return (
    <>
      <FormLabel name={name} label={customLabel || label} required={required} />
      <div className="col-md-6">
        <select
          className={['form-select', (!isDisabled ? validClass : '')].join(' ')}
          id={name}
          onChange={(e) => handleChange({ value: e.target.value, name, valid, validationRegex, required })}
          value={evalues[clone || name] || ''}
          disabled={isDisabled}
          onBlur={() => handleValidation({ value, validationRegex, name, required })}
        >
          {options ? options.map(option => (
            <option disabled={option.disabled} key={option.value} value={option.value}>{option.label}</option>
          )) : children}
        </select>
      </div>
    </>
  );  
}

export default Select;
