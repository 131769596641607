export const getAllParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const allParams = Object.fromEntries(urlSearchParams.entries());
  return allParams;
};

export const getParam = (param) => {
  const params = getAllParams();
  const p = params[param];
  return p;
};
