import React from 'react';

function Spinner ({
  pixelSize = '25',
  className,
  style,
}) {
  return (
    <div
      className={`loading-indicator-spinner ${className}`}
      style={{
        height: `${pixelSize}px`,
        width: `${pixelSize}px`,
        display: 'inline-block',
        verticalAlign: 'text-bottom',
        border: '0.3em solid var(--bs-primaryFocusShadow)',
        borderRightColor: 'var(--bs-primaryFocus)',
        borderRadius: '50%',
        animation: 'spinner-border .75s linear infinite',
        ...style,
      }}
    />
  );
}

export default Spinner;
