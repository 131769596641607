const initialState = {
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_ESIGN_FIELDS':
      return { ...state, ...action.payload };
    case 'CLEAR_ESIGN':
      return { ...initialState };
    default:
      return state;
  }
}
