import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { TextInput, YesNo, DatePicker } from '../../components/inputs';
import { Collapse } from '../../../../common/components';

import '../../styles.scss';

function LTCF () {
  const { ltcf } = useSelector(store => store.evalues);

  return (
    <>
      <FormRow>
        <YesNo
          label="Are you currently in a long term care facility?"
          name="ltcf"
        />
      </FormRow>
      <Collapse indent isOpen={ltcf}>
        <FormRow>
          <TextInput
            label="Facility name"
            name="ltcf_name"
          />
        </FormRow>
        <FormRow>
          <DatePicker
            label="Date entered the facility"
            name="ltcf_date"
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="Institution phone number"
            name="ltcf_tel"
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="Street Address Line 1"
            name="ltcf_addr_street1"
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="Street Address Line 2"
            name="ltcf_addr_street2"
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="City"
            name="ltcf_addr_city"
          />
        </FormRow>
        <FormRow className="col-md-6">
          <TextInput
            label="State"
            name="ltcf_addr_state"
          />
        </FormRow>
        <FormRow className="col-md-6">
          <TextInput
            label="ZIP code"
            name="ltcf_addr_zip"
          />
        </FormRow>
      </Collapse>
    </>
  );  
}

export default LTCF;
