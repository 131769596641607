/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { getParam } from '../../common/utils/qs';

// Components
import Topbar from '../../components/Topbar';
import DisclaimerFooter from '../../components/DisclaimerFooter';

function EsignStart(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const quoteID = getParam('quoteID');

  const { esignMaterialID, esignDisclaimerLastUpdate } = useSelector(store => store.theme);

  function handleNext() {
  }

  const blockStyles = {
    maxWidth: '600px',
  };

  return (
    <>
      <Topbar />
      <div className='mmb-container'>
        <div className="text-serif fw-bold fs-1 m-3">
          Congratulations!
        </div>

        <div className="d-flex fw-bold fs-4 m-3" style={blockStyles}>
          You are only one step away from completing your Medicare Advantage
          or Medicare Prescription Drug enrollment application.
        </div>

        <div className="d-flex fs-4 m-3" style={blockStyles}>
          Please review your information on the following pages by clicking 'Next'
          at the bottom of each page.
        </div>

        <div className="d-flex fs-4 m-3" style={blockStyles}>
          If you need to make changes to your information click on the field, highlight
          the information that needs to be updated and replace with the corrected
          information.
        </div>

        <div className="d-flex fs-4 m-3 mb-4" style={blockStyles}>
          Continue until you reach the 'Submit Your Application' page where you will
          review, sign, and submit to complete your enrollment application.
        </div>

        <div className="d-flex fs-4 m-3 mb-5" style={blockStyles}>
          <button
            type="button"
            className="btn btn-primary fw-bold"
            onClick={() => navigate(`/enroll/load?quoteID=${quoteID}`)}
          >
            Continue
          </button>
        </div>

        <DisclaimerFooter
          customMaterialID={esignMaterialID}
          customLastUpdate={esignDisclaimerLastUpdate}
        />
      </div>
    </>
  );
}

export default EsignStart;
