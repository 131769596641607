import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput } from './VerifyIdentity';
import { LoaderButton } from '../../common/components';

// Actions
import { createShopper } from '../../actions/shopper';
import { getParam } from '../../common/utils/qs';
import handleLogin from '../../utils/handleLogin';

// Components
import Topbar from '../../components/Topbar';
import DisclaimerFooter from '../../components/DisclaimerFooter';

function CreateAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flow = getParam('flow');

  const { shopper } = useSelector(store => store);

  const [ name, setName ] = useState();
  const [ email, setEmail ] = useState();
  const [ password, setPassword ] = useState();
  const [ submitLoading, setSubmitLoading ] = useState();

  const [ nameErrors, setNameErrors ] = useState();
  const [ emailErrors, setEmailErrors ] = useState();
  const [ passwordErrors, setPasswordErrors ] = useState();
  const [ submitErrors, setSubmitErrors ] = useState();
  const [ fadeMessage, setFadeMessage ] = useState();

  useEffect(() => {
    if ((!shopper.username || !shopper.quoteID) && !submitLoading) {
      setSubmitErrors('Please follow the link from your agent.');
    }
  }, [ shopper ]);

  const inputs = [
    {
      label: 'First name',
      value:  name,
      onEnter: (e => handleSubmit()),
      errors: nameErrors,
      onChange: (e => {
        setName(e.target.value);
        setNameErrors();
        setFadeMessage(nameErrors);
        setTimeout(() => { setFadeMessage() }, 1000);
      }),
      fadeMessage,
    },
    {
      label: 'Email',
      value: email,
      onEnter: (e => handleSubmit()),
      errors: emailErrors,
      onChange: (e => {
        setEmail(e.target.value);
        setEmailErrors();
        setFadeMessage(emailErrors);
        setTimeout(() => { setFadeMessage() }, 1000);
      }),
      fadeMessage,
    },
    {
      label: 'Password',
      value: password,
      onEnter: (() => handleSubmit()),
      errors: passwordErrors,
      onChange: (e => {
        setPassword(e.target.value);
        setPasswordErrors();
        setFadeMessage(passwordErrors);
        setTimeout(() => { setFadeMessage() }, 1000);
      }),
      toggleVisibility: true,
      fadeMessage,
    }
  ];

  function handleSubmit(e) {
    const hasErrors = nameErrors?.length || emailErrors?.length || passwordErrors?.length;
    if (!name || !email || !password) {
      if (!name) setNameErrors('First name is required');
      if (!email) setEmailErrors('Email is required');
      if (!password) setPasswordErrors('Password is required');
    } else if (!hasErrors) {
      setSubmitLoading(true);
      createShopper({ name, email, password, username: shopper.username }).then(() => {
        handleLogin({ email, password, dispatch }).then(() => {
          let redirectPath = '/quotes';
          if (flow === 'esign') redirectPath = `/esign/start?quoteID=${shopper.quoteID}`;
          navigate(redirectPath);
        }).catch(() => navigate('/login'));
      }).catch(err => {
        const { response } = err;
        setSubmitLoading(false);
        let errMsg = 'There was a problem creating your account.';
        if (response) errMsg = Object.keys(response.data).reduce((msg, key) => msg += response.data[key], ' ');
        setSubmitErrors(errMsg);
      });
    }
  };

  return (
    <>
      <Topbar />
      <div className='mmb-container'>
        <div className="text-serif fw-bold fs-1 m-3 mb-0">
          Create account
        </div>
        <div className="text-center m-4 d-flex flex-column" style={{ maxWidth: '600px' }}>
          <span>
            Creating an account allows us to securely
            store your information so you can pick up
            where you left off at any time.
          </span>
        </div>
        <div className="d-flex flex-column align-items-left" style={{ width: '300px' }}>
          {inputs.map((i) => (
            <TextInput key={i.label} className="mb-1" {...i} />
          ))}
          <div className="mb-3" style={{ whiteSpace: 'pre' }}>
            <LoaderButton
              text="Create account"
              loading={submitLoading}
              loadingText="Creating account.."
              className="btn btn-primary fw-bold w-100 mt-2"
              onClick={handleSubmit}
              errors={submitErrors}
            />
          </div>
        </div>
        <div
          className="text-center m-3 d-flex flex-column p-2 h-link"
          style={{ maxWidth: '600px', cursor: 'pointer' }}
          onClick={() => navigate('/login') }
        >
          Have an account?
          <span className="ms-1 text-decoration-underline text-blue">
            Sign in
          </span>
        </div>
        <DisclaimerFooter />
      </div>
    </>
  );
}

export default CreateAccount;
