import * as moment from 'moment';
import { yesNo, yesNoBlank, existsOrBlank } from './mapping-tools';
import { stripPhone } from './formatting';
import getAgentID from './getAgentID';

export default ({
  agent,
  quote,
  fields: res,
  plan,
  theme,
  confirmationNumber,
}) => {
  const { useCarrierPerms } = theme;

  let agentID;
  if (useCarrierPerms) {
    agentID = getAgentID({ agent, quote }).agentID;
  } else {
    agentID = agent.npn;
  }

  const isSEP = res.enrolltype === 'SEP';

  const premiumDeducted = yesNo(['rrb', 'ssi'].includes(res.paymeth));
  const premiumWithhold = ['rrb', 'ssi'].includes(res.paymeth) ? res.paymeth.toUpperCase() : '';
  const premiumDirectPay = yesNo((res.paymeth === 'direct') || !res.paymeth); // default to coupon book if paymeth not specified

  const planYear = plan.PlanYear;
  const effectiveDate = moment(res.effective_date).format('MMDDYYYY');
  const ltcfAddress = res.ltcf ? `${res.ltcf_addr_street1}${res.ltcf_addr_street2 ? ` ${res.ltcf_addr_street2}` : ''}` : '';

  const isSepOther = res.sep_code === 'OTH';
  // format sep date as mmddyyyy
  const sepDate = res.sep_date ? `${res.sep_date.slice(5, 7)}${res.sep_date.slice(8, 10)}${res.sep_date.slice(0, 4)}` : '';
  const sepConcat = `${res.sep_code} ${sepDate}`.trim();

  const gender = res.gender === 'male' ? 'M' : 'F';

  // formats date string 'YYYY-MM-DD' to 'MMYYYY'
  function dateToMonthAndYear(dateString) {
    const d = moment(dateString);
    if (d.isValid()) {
      return d.format('MMYYYY');
    }
    return '';
  }

  let race = '';
  let ethnicity = '';

  if (Array.isArray(res.race) && res.race.length) {
    race = res.race.join(',');
  }
  if (Array.isArray(res.ethnicity) && res.ethnicity.length) {
    ethnicity = res.ethnicity.join(',');
  }

  const formattedData = [
    { col: 'ConfirmationNumber', val: confirmationNumber },
    { col: 'SubmitDate', val: moment().format('MMDDYYYY') },
    { col: 'ContractID', val: plan.ExternalContractID },
    { col: 'PlanID', val: plan.ExternalPlanID },
    { col: 'SegmentID', val: plan.ExternalSegmentID },
    { col: 'ApplicantTitle', val: '' },
    { col: 'ApplicantFirstName', val: res.first_name },
    { col: 'ApplicantMiddleInitial', val: res.middle_name },
    { col: 'ApplicantLastName', val: res.last_name },
    { col: 'ApplicantBirthDate', val: moment(res.birthdate).format('MMDDYYYY') },
    { col: 'ApplicantGender', val: gender },
    { col: 'ApplicantAddress1', val: res.street1 },
    { col: 'ApplicantAddress2', val: res.street2 },
    { col: 'ApplicantAddress3', val: '' }, // we don't capture an address 3
    { col: 'ApplicantCity', val: (res.city || '').slice(0, 30) },
    { col: 'ApplicantCounty', val: res.county_name },
    { col: 'ApplicantState', val: res.state.toUpperCase() },
    { col: 'ApplicantZip', val: res.zipcode },
    { col: 'ApplicantPhone', val: stripPhone(res.home_phone) },
    { col: 'ApplicantEmailAddress', val: res.email },
    { col: 'ApplicantHICN', val: res.medicare_id },
    { col: 'ApplicantSSN', val: '' }, // UHC spec says only populated for DSNP plans
    { col: 'MailingAddress1', val: res.samemail ? '' : res.mailstreet1 }, // UHC spec says leave blank unless different
    { col: 'MailingAddress2', val: res.samemail ? '' : res.mailstreet2 }, // UHC spec says leave blank unless different
    { col: 'MailingAddress3', val: '' }, // we don't capture an address 3
    { col: 'MailingCity', val: res.samemail ? '' : res.mailcity }, // UHC spec says leave blank unless different
    { col: 'MailingState', val: res.samemail ? '' : res.mailstate }, // UHC spec says leave blank unless different
    { col: 'MailingZip', val: res.samemail ? '' : res.mailzip }, // UHC spec says leave blank unless different
    { col: 'MedicarePartA', val: dateToMonthAndYear(res.part_a_effective_date) }, // UHC spec says to format as MMYYYY
    { col: 'MedicarePartB', val: dateToMonthAndYear(res.part_b_effective_date) }, // UHC spec says to format as MMYYYY
    { col: 'EmergencyContact', val: res.hasemer ? `${res.emerfname} ${res.emerlname}` : '' },
    { col: 'EmergencyPhone', val: res.hasemer ? stripPhone(res.emertel) : '' },
    { col: 'EmergencyRelationship', val: res.hasemer ? res.emerrel : '' },
    { col: 'PremiumDeducted', val: premiumDeducted },
    { col: 'PremiumSource', val: '' }, // UHC spec says not used
    { col: 'OtherCoverage', val: yesNo(res.othercoverage) }, // required for MA/PD but not PDP
    { col: 'OtherCoverageName', val: res.othercoverage ? res.othercoverage_name : '' },
    { col: 'OtherCoverageID', val: res.othercoverage ? res.othercoverage_num : '' },
    { col: 'LongTerm', val: yesNo(res.ltcf) },
    { col: 'LongTermName', val: res.ltcf ? res.ltcf_name : '' },
    { col: 'LongTermAddress', val: ltcfAddress },
    { col: 'LongTermPhone', val: res.ltcf ? stripPhone(res.ltcf_tel) : '' },
    { col: 'AuthorizedRepName', val: res.ispoa ? `${res.poa_fname} ${res.poa_lname}` : '' },
    { col: 'AuthorizedRepAddress', val: res.ispoa ? `${res.poa_addr_street1} ${res.poa_addr_street2 || ''}`.trim() : '' },
    { col: 'AuthorizedRepCity', val: res.ispoa ? res.poa_addr_city : '' },
    { col: 'AuthorizedRepState', val: res.ispoa ? res.poa_addr_state : '' },
    { col: 'AuthorizedRepZip', val: res.ispoa ? res.poa_addr_zip : '' },
    { col: 'AuthorizedRepPhone', val: res.ispoa ? res.poa_tel : '' },
    { col: 'AuthorizedRepRelationship', val: res.ispoa ? res.poa_rel : '' },
    { col: 'Language', val: res.preflang ? res.preflang : '' },
    { col: 'ESRD', val: '' }, // Per CMS as of 12/31/2020 ESRD no longer applicable (End-Stage Renal Disease)
    { col: 'StateMedicaid', val: yesNo(res.onmedicaid) },
    { col: 'WorkStatus', val: yesNo(res.willwork || res.willworkspouse) },
    { col: 'PrimaryCarePhysician', val: res.pcp_num || '' },
    { col: 'OtherCoverageGroup', val: res.othercoverage_group },
    { col: 'AgentID', val: agentID },
    { col: 'SubmitTime', val: moment().format('YYYY-MM-DD HH:mm:ss') },
    { col: 'PartDSubAppInd', val: '' }, // UHC spec says 'not used'
    { col: 'DeemedInd', val: '' }, // UHC spec says 'not used'
    { col: 'SubsidyPercentage', val: '' }, // UHC spec says 'not used'
    { col: 'DeemedReasonCode', val: '' }, // UHC spec says 'not used'
    { col: 'LISCopayLevelID', val: '' }, // UHC spec says 'not used'
    { col: 'DeemedCopayLevelID', val: '' }, // UHC spec says 'not used'
    { col: 'PartDOptOutSwitch', val: '' }, // UHC spec says 'not used'
    // formatted as `SEPCODE mmddyyyy` or `SEPCODE`
    { col: 'SEPReasonCode', val: isSEP ? sepConcat : '' },
    // Only used when indicating OTH as the SEP Reason Code in order to explain the reason the individual is eligible to enroll
    { col: 'SEPCMSReasonCode', val: isSepOther ? res.sep_other : '' },
    { col: 'PremiumDirectPay', val: premiumDirectPay },
    { col: 'EnrollmentPlanYear', val: planYear },
    { col: 'PremiumWithhold', val: premiumWithhold },
    { col: 'SpouseWorkStatus', val: yesNo(res.willwork || res.willworkspouse) }, // opt
    { col: 'AccessibilityFormat', val: res.altwritten },
    { col: 'EmailOptIn', val: yesNo(res.okemail) },
    { col: 'Race', val: race },
    { col: 'Ethnicity', val: ethnicity },
    { col: 'Riders', val: '' }, // opt (and we don't do riders in enrollment as of 03/23/22)
    { col: 'MedicaidID', val: res.onmedicaid ? res.medicaidnum : '' }, // opt
    { col: 'GoPaperless', val: '' }, // opt
    { col: 'ProposedEffectiveDate', val: effectiveDate },
    { col: 'MaterialFormat', val: res.altwritten ? res.altwritten : 'STANDARD PRINT' },
    { col: 'PCPPatientInd', val: yesNo(res.pcp_established) },
    { col: 'ChronicPhyName', val: existsOrBlank(res.snp_physician_name) }, // required for DSNP
    { col: 'ChronicPhyPhone', val: existsOrBlank(res.snp_physician_tel) }, // required for DSNP
    { col: 'ChronicReleaseAuthorized', val: '' }, // opt (we don't capture this info)
    { col: 'Diabetes1', val: yesNoBlank(res.snp_diabetes) },
    { col: 'Diabetes2', val: yesNoBlank(res.snp_diabetes_insulin) },
    { col: 'CHF1', val: yesNoBlank(res.snp_chf) },
    { col: 'CHF2', val: yesNoBlank(res.snp_chf_fluid) },
    { col: 'CHF3', val: yesNoBlank(res.snp_chf_weight) },
    { col: 'CARDIO1', val: yesNoBlank(res.snp_cvd) },
    { col: 'CARDIO2', val: yesNoBlank(res.snp_cvd_circulation) },
    { col: 'CARDIO3', val: yesNoBlank(res.snp_cvd_pain) },
    { col: 'CARDIO4', val: yesNoBlank(res.snp_cvd_rx) },
    { col: 'CARDIO5', val: yesNoBlank(res.snp_cvd_defib_or_pacemaker) },
    { col: 'CARDIO6', val: yesNoBlank(res.snp_cvd_implants) },
  ];

  return { formattedData, agentID };
};
