import * as moment from 'moment';
import { yesNo } from './mapping-tools';
import { stripPhone, cleanDate } from './formatting';
import getAgentID from './getAgentID';

export default ({
  agent,
  plan,
  quote,
  fields: res,
  confirmationNumber,
  theme,
}) => {
  const { useCarrierPerms } = theme;

  let agentNPN;
  if (useCarrierPerms) {
    const ids = getAgentID({ agent, quote });
    agentNPN = ids.agentNPN;
  } else {
    agentNPN = agent.npn;
  }

  const paymentMethod = res.paymeth || '';
  const premiumDeducted = yesNo(['rrb', 'ssi'].includes(paymentMethod)).toUpperCase();
  const premiumDirectPay = yesNo(!['rrb', 'ssi'].includes(paymentMethod)).toUpperCase();
  const premiumWithhold = ['rrb', 'ssi'].includes(paymentMethod) ? paymentMethod.toUpperCase() : '';
  let premiumSource = '';
  // if payment method is coupon book, set premium source to STMT
  if (paymentMethod === 'cb' || !paymentMethod) {
    premiumSource = 'STMT';
  } else if (paymentMethod === 'eft') {
    premiumSource = 'ACH';
  }
  const planYear = plan.PlanYear;
  const isEFT = paymentMethod === 'eft';
  const eftActType = (res.eft_acttype === 'checking') ? 'Checking' : 'Savings';
  const ltcfAddress = res.ltcf
    ? `${res.ltcf_addr_street1}${res.ltcf_addr_street2 ? ` ${res.ltcf_addr_street2}` : ''}, ${res.ltcf_addr_city}, ${res.ltcf_addr_state} ${res.ltcf_addr_zip}`
    : '';

  let sepReasonCode = '';
  if (res.enrolltype === 'SEP') {
    // format sep date as mmddyyyy
    const sepDate = res.sep_date ? `${res.sep_date.slice(5, 7)}${res.sep_date.slice(8, 10)}${res.sep_date.slice(0, 4)}` : '';
    sepReasonCode = `${res.sep_code} ${sepDate}`.trim();
  } else {
    sepReasonCode = res.enrolltype;
  }

  const effectiveDate = moment(res.effective_date).format('MMDDYYYY');
  const gender = (res.gender === 'male') ? 'M' : 'F';
  const submitTime = moment.utc().format('YYYY-MM-DD hh:mm:ss.SSS');

  let race = '';
  let ethnicity = '';

  if (Array.isArray(res.race) && res.race.length) {
    race = res.race.join(',');
  }
  if (Array.isArray(res.ethnicity) && res.ethnicity.length) {
    ethnicity = res.ethnicity.join(',');
  }

  const formattedData = [
    { col: 'ConfirmationNumber', val: confirmationNumber },
    { col: 'SubmitDate', val: moment().format('MMDDYYYY') },
    { col: 'ContractID', val: plan.ExternalContractID },
    { col: 'PlanID', val: plan.ExternalPlanID },
    { col: 'SegmentID', val: plan.ExternalSegmentID },
    { col: 'ApplicantTitle', val: '' },
    { col: 'ApplicantFirstName', val: res.first_name },
    { col: 'ApplicantMiddleInitial', val: res.middle_name },
    { col: 'ApplicantLastName', val: res.last_name },
    { col: 'ApplicantBirthDate', val: moment(res.birthdate).format('MMDDYYYY') },
    { col: 'ApplicantGender', val: gender },
    { col: 'ApplicantAddress1', val: res.street1 },
    { col: 'ApplicantAddress2', val: res.street2 },
    { col: 'ApplicantAddress3', val: '' },
    { col: 'ApplicantCity', val: (res.city || '').slice(0, 30) },
    { col: 'ApplicantCounty', val: res.county_name },
    { col: 'ApplicantState', val: res.state.toUpperCase() },
    { col: 'ApplicantZip', val: res.zipcode },
    { col: 'ApplicantPhone', val: stripPhone(res.home_phone) },
    { col: 'ApplicantEmailAddress', val: res.email },
    { col: 'ApplicantMBI', val: res.medicare_id },
    { col: 'ApplicantSSN', val: '' }, // we do not collect ssn
    { col: 'MailingAddress1', val: res.samemail ? res.street1 : res.mailstreet1 },
    { col: 'MailingAddress2', val: res.samemail ? res.street2 : res.mailstreet2 },
    { col: 'MailingAddress3', val: '' },
    { col: 'MailingCity', val: res.samemail ? res.city : res.mailcity },
    { col: 'MailingState', val: res.samemail ? res.state : res.mailstate },
    { col: 'MailingZip', val: res.samemail ? res.zipcode : res.mailzip },
    { col: 'MedicarePartA', val: cleanDate(res.part_a_effective_date) },
    { col: 'MedicarePartB', val: cleanDate(res.part_b_effective_date) },
    { col: 'EmergencyContact', val: res.hasemer ? `${res.emerfname} ${res.emerlname}` : '' },
    { col: 'EmergencyPhone', val: res.hasemer ? stripPhone(res.emertel) : '' },
    { col: 'EmergencyRelationship', val: res.hasemer ? res.emerrel : '' },
    { col: 'PremiumDeducted', val: premiumDeducted },
    { col: 'PremiumSource', val: premiumSource },
    { col: 'OtherCoverage', val: yesNo(res.othercoverage).toUpperCase() }, // applies to PDP, MAPD, SNP DE, PFFS-PD, CP-PD
    { col: 'OtherCoverageName', val: res.othercoverage ? res.othercoverage_name : '' },
    { col: 'OtherCoverageID', val: res.othercoverage ? res.othercoverage_num : '' },
    { col: 'LongTerm', val: yesNo(res.ltcf).toUpperCase() },
    { col: 'LongTermName', val: res.ltcf ? res.ltcf_name : '' },
    { col: 'LongTermAddress', val: ltcfAddress },
    { col: 'LongTermPhone', val: res.ltcf ? stripPhone(res.ltcf_tel) : '' },
    { col: 'AuthorizedRepName', val: res.ispoa ? `${res.poa_fname} ${res.poa_lname}` : '' },
    { col: 'AuthorizedRepAddress', val: res.ispoa ? `${res.poa_addr_street1} ${res.poa_addr_street2 || ''}`.trim() : '' },
    { col: 'AuthorizedRepCity', val: res.ispoa ? res.poa_addr_city : '' },
    { col: 'AuthorizedRepState', val: res.ispoa ? res.poa_addr_state : '' },
    { col: 'AuthorizedRepZip', val: res.ispoa ? res.poa_addr_zip : '' },
    { col: 'AuthorizedRepPhone', val: res.ispoa ? res.poa_tel : '' },
    { col: 'AuthorizedRepRelationship', val: res.ispoa ? res.poa_rel : '' },
    { col: 'Language', val: res.preflang ? res.preflang : '' },
    { col: 'ESRD', val: '' }, // Per CMS as of 12/31/2020 ESRD no longer applicable (End-Stage Renal Disease)
    { col: 'StateMedicaid', val: yesNo(res.onmedicaid).toUpperCase() },
    { col: 'WorkStatus', val: yesNo(res.willwork).toUpperCase() },
    { col: 'PrimaryCarePhysician', val: res.pcp_name || '' }, // per Clover spec this is the name of the PCP (not id #)
    { col: 'OtherCoverageGroup', val: res.othercoverage_group },
    { col: 'AgentID', val: agentNPN },
    { col: 'SubmitTime', val: submitTime },
    { col: 'PartDSubAppInd', val: '' }, // ignoring because opt (and we don't collect subsidy info on quotes)
    { col: 'DeemedInd', val: '' }, // ignoring because opt (and we don't collect subsidy info on quotes)
    { col: 'SubsidyPercentage', val: '' }, // ignoring because opt (and we don't collect subsidy info on quotes)
    { col: 'DeemedReasonCode', val: '' }, // ignoring because opt (and we don't collect subsidy info on quotes)
    { col: 'LISCopayLevelID', val: '' }, // ignoring because opt (and we don't collect subsidy info on quotes)
    { col: 'DeemedCopayLevelID', val: '' }, // ignoring because opt (and we don't collect subsidy info on quotes)
    { col: 'PartDOptOutSwitch', val: '' }, // ignoring because opt (and we don't collect subsidy info on quotes)
    // formatted as `SEPCODE mmddyyyy` or `SEPCODE`
    { col: 'SEPReasonCode', val: sepReasonCode },
    { col: 'SEPCMSReasonCode', val: '' }, // not used by clover
    { col: 'PremiumDirectPay', val: premiumDirectPay },
    { col: 'EnrollmentPlanYear', val: planYear },
    { col: 'PremiumWithhold', val: premiumWithhold },
    { col: 'SpouseWorkStatus', val: yesNo(res.willworkspouse).toUpperCase() },
    { col: 'AccessibilityFormat', val: res.altwritten },
    { col: 'EmailOptIn', val: yesNo(res.okemail).toUpperCase() },
    { col: 'PlanDocs', val: '' }, // ignoring because not required
    { col: 'AccountHolderName', val: isEFT ? res.eft_actname : '' },
    { col: 'AccountNumber', val: isEFT ? res.eft_actnum : '' },
    { col: 'BankRoutingNumber', val: isEFT ? res.eft_routing : '' },
    { col: 'AccountType', val: isEFT ? eftActType : '' },
    { col: 'SourceID', val: 'MyMedicareBot' },
    { col: 'EnrollmentChannel', val: 'OEC' }, // static value of 'OEC' per Clover docs
    { col: 'EffectiveDate', val: effectiveDate },
    { col: 'BrokerSignatureDate', val: moment().format('MMDDYYYY') },
    { col: 'Race', val: race || '' },
    { col: 'Ethnicity', val: ethnicity || '' },
    { col: 'Filler 1', val: '' },
    { col: 'Filler 2', val: '' },
    { col: 'Filler 3', val: '' },
    { col: 'Filler 4', val: '' },
    { col: 'Filler 5', val: '' },
    { col: 'Filler 6', val: '' },
    { col: 'Filler 7', val: '' },
    { col: 'Filler 8', val: '' },
  ];

  return { formattedData, agentID: agentNPN };
};
