import React from 'react';
import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';

import PaymentPage from './Payment';
import SubmitPage from './Submit';

function Review ({ pageTitle }) {
  return (
    <Routes>
      <Route path="payment" element={<PaymentPage pageTitle={pageTitle} />} />
      <Route path="submit" element={<SubmitPage pageTitle={pageTitle} />} />
    </Routes>
  );  
}

export default Review;
