/* eslint-disable */
import * as moment from 'moment';
import { store } from '../../store';
import { fallback } from './mapping-tools';
import { stripPhone } from './formatting';
import getAgentID from './getAgentID';

export default ({
  agent,
  quote,
  fields: responses,
  plan,
  theme,
  confirmationNumber,
}) => {

  const useCarrierPerms = theme.useCarrierPerms;
  const useTestingAgentSan = theme.useTestingAgentSan;

  let agentSan;
  if (useTestingAgentSan) {
    agentSan = Number(useTestingAgentSan);
  } else if (useCarrierPerms) {
    const { agentID } = getAgentID({ agent, quote });
    agentSan = Number(agentID);
  } else {
    agentSan = Number(agent.npn);
  }

  const { humanaPartnerInfo: {
    partnerID,
    partnerName,
    partnerAEID,
  }} = theme;

  const payMethMap = {
    cb: 'coupon book',
    rrb: 'railroad retirement withholding',
    ssi: 'social security check withholding',
    eft: 'ach',
  };
  const paymentMethod = (responses.paymeth ? payMethMap[responses.paymeth] : 'coupon book');

  const isCSNP = plan.SNPIND === 1; // 1 = CSNP, 2 = DSNP, 3 = ISNP
  // const snpCVD = (responses.snp_cvd || responses.snp_cvd_circulation || responses.snp_cvd_pain);
  // const snpCHF = (responses.snp_chf || responses.snp_chf_fluid || responses.snp_chf_swelling);
  // const snpLung = (responses.snp_lung || responses.snp_lung_told || responses.snp_lung_inhaler);
  // const snpDiabetes = (responses.snp_diabetes || responses.snp_diabetes_monitor || responses.snp_diabetes_insulin);
  // const snpESRD = (responses.snp_esrd || responses.snp_esrd_dialysis || responses.snp_esrd_transplant);

  let enrollType = responses.enrolltype
  let sepCode = responses.sep_code;
  let sepDate = responses.sep_date;
  let sepReason = responses.sep_reason;

  const remapSEPKey = {
    'MYT': 'EOC',
    'CSN': 'CHR',
    'IEP': 'MRD',
    'LPI': 'OTH',
    'IND': 'LT2',
    'OSD': 'COS', // "should be using MCP or COS"... so... which one and why? Flip a coin I guess...
    'PRE': 'GEP',
    'CSP': 'OTH',
    'DSP': 'OTH',
  };
  const remapEnrollTypeKey = {
    'NEW': 'IEP',
    'ICE': 'ICEP',
  };

  if (Object.keys(remapSEPKey).includes(sepCode)) {
    // this (LPI) is the SEP for leaving a low-performing plan (CMS designation); for 2022 there were
    // none so it wasn’t valid and we didn’t add it to the API, for 2023 there is only one plan
    // in the entire country, so we preferred partners use OTH and indicate "leaving low-performing plan"
    // rather than the risk of adding the SEP for open usage, since valid usage should be extremely low
    if (sepCode === 'LPI') sepReason = 'leaving low-performing plan';
    if (sepCode === 'CSP') sepReason = 'CSP';
    if (sepCode === 'DSP') sepReason = 'DSP';

    sepCode = remapSEPKey[sepCode];
  }

  // if sep is in remapEnrollTypeKey we need to set a different enrollment type than SEP
  // and we need to unset the sep code
  if (Object.keys(remapEnrollTypeKey).includes(sepCode)) {
    enrollType = remapEnrollTypeKey[sepCode];
    sepCode = undefined;
  }

  const formattedData = {
    agent: {
      agent_name: `${agent.firstName} ${agent.lastName}`,
      agent_san: agentSan,
      agent_email_address: agent.email,
    },
    applicant: {
      date_of_birth: responses.birthdate,
      first_name: responses.first_name,
      middle_name: responses.middle_name,
      gender: responses.gender,
      hospital_insurance_parta: responses.part_a_effective_date,
      last_name: responses.last_name,
      medical_insurance_partb: responses.part_b_effective_date,
      medicare_claim_number: responses.medicare_id,
      permanent_address: {
        address_line_one: responses.street1,
        address_line_two: responses.street2,
        city: (responses.city || '').slice(0, 20),
        county: responses.county_name,
        state: responses.state.toUpperCase(),
        zip_code: responses.zipcode,
      },
      mailing_address: fallback(!responses.samemail, {
        address_line_one: responses.mailstreet1,
        address_line_two: responses.mailstreet2,
        city: responses.mailcity,
        state: (responses.mailstate || '').toUpperCase(),
        zip_code: responses.mailzip,
      }),
      email_address: responses.email ?? undefined,
      phone_type: 'Home',
      phone_number: stripPhone(responses.home_phone),
      chronic_conditions: fallback(isCSNP, {
        cardiovascular_disease: {
          heart_problem: responses.snp_cvd,
          circulation: responses.snp_cvd_circulation,
          leg_pain: responses.snp_cvd_pain,
          medications: responses.snp_cvd_medicines || '',
        },
        chronic_heart_failure: {
          heart_failure: responses.snp_chf,
          fluid: responses.snp_chf_fluid,
          leg_swelling: responses.snp_chf_swelling,
          medications: responses.snp_chf_medicines || '',
        },
        chronic_lung_disease: {
          breathing_problem: responses.snp_lung,
          lung_problem: responses.snp_lung_told,
          using_inhaler: responses.snp_lung_inhaler,
          medications: responses.snp_lung_medicines || '',
        },
        diabetes: {
          high_blood_sugar: responses.snp_diabetes,
          monitor_blood_sugar: responses.snp_diabetes_monitor,
          insulin_oral_medication: responses.snp_diabetes_insulin,
          medications: responses.snp_diabetes_medicines || '',
        },
        end_stage_renal_disease: {
          have_esrd: responses.snp_esrd,
          undergoing_dialysis: responses.snp_esrd_dialysis,
          kidney_transplant: responses.snp_esrd_transplant,
          medications: responses.snp_esrd_medicines || '',
        },
      }),
    },
    application_date: moment().format('YYYY-MM-DD'),
    do_you_or_your_spouse_work: responses.willwork || responses.willworkspouse,
    emergency_contact: fallback(responses.hasemer, {
      first_name: responses.emerfname,
      last_name: responses.emerlname,
      phone_number: stripPhone(responses.emertel),
      relationship: responses.emerrel,
    }),
    end_stage_renal_disease: {
      diagnosed_positive: responses.esrd,
      enrolled_in_another_humana_plan: responses.esrd_anotherplan,
      previously_enrolled_in_medicare_advantage: responses.esrd_prevmap,
      require_regular_dialysis: responses.esrd_dialysis,
      successful_kidney_transplant: responses.esrd_kidneytransplant,
    },
    enrollment_type: enrollType,
    marketing: {
      veteran: responses.veteran,
    },
    medicaid: {
      currently_on_medicaid: responses.onmedicaid,
      medicaid_number: responses.medicaidnum ? responses.medicaidnum : undefined,
    },
    ok_to_email: responses.okemail ?? false,
    /* osbs: fallback(responses.osbs_id, {
      ben_seq_number: '????',
      cust_number: '????',
      dental_code: '????',
      vision_code: '????',
      hearing_code: '????',
      international_code: '????',
      other_code: '????',
      package_key: '????',
      package_description: '????',
      pdf_name: '????',
      plan_year: '????',
      premium: '????',
    }), */
    other_drug_coverage: {
      other_rx_coverage: responses.othercoverage,
      ...fallback(responses.othercoverage, {
        member_id: responses.otherrxcov_id,
        name_of_drug_coverage: responses.otherrxcov_name,
        policy_number: responses.otherrxcov_num,
      }, {}),
    },
    other_group_coverage: {
      medical_coverage: responses.othercoverage,
      ...fallback(responses.othercoverage, {
        carrier: {
          carrier_name: responses.othercoverage_name,
        },
        does_medical_coverage_include_rx: responses.othercoverage_rx,
        policy_number: responses.othercoverage_num,
      }, {}),
    },
    partner_application_id: confirmationNumber,
    partner: {
      id: partnerID,
      name: partnerName,
      ae_id: partnerAEID,
    },
    payments: [{
      payment_amount: quote.premium,
      payment_type: paymentMethod,
      bank: fallback(responses.paymeth === 'eft', {
        account_number: responses.eft_actnum,
        account_type: responses.eft_acttype,
        name: responses.eft_actname,
        routing_number: responses.eft_routing,
      }),
    }],
    plan: {
      contract_number: plan.ExternalContractID || 'H6622',
      pbp_number: plan.ExternalPlanID || '028',
      primary_care_physician: {
        do_you_wish_to_identify_a_pcp: Boolean(responses.pcp_name),
        provider: fallback(Boolean(responses.pcp_name), {
          established_patient: responses.pcp_established,
          provider_name: responses.pcp_name,
          provider_number: responses.pcp_num,
        }),
      },
    },
    power_of_attorney: fallback(responses.ispoa, {
      address: {
        address_line_one: responses.poa_addr_street1,
        address_line_two: responses.poa_addr_street2,
        city: responses.poa_addr_city,
        state: responses.poa_addr_state,
        zip_code: responses.poa_addr_zip,
      },
      first_name: responses.poa_fname,
      last_name: responses.poa_lname,
      phone_number: stripPhone(responses.poa_tel),
      relationship: responses.poa_rel,
    }),
    proposed_effective_date: moment(quote.effectiveDate).format('YYYY-MM-DD'),
    receive_plan_coverage_material_online: responses.okemail,
    signature_type: responses.ispoa ? 'POA' : 'Client',
    ...fallback(sepCode, {
      sep_code: sepCode,
      sep_date: sepDate,
      sep_reason_other: sepReason,
    }, {}),
    long_term_care: fallback(responses.ltcf, {
      currently_in_long_term_care: true,
      date_entered_long_term_care: responses.ltcf_date,
      facility: {
        institution_name: responses.ltcf_name,
        phone_number: stripPhone(responses.ltcf_tel),
        address: {
          address_line_one: responses.ltcf_addr_street1,
          address_line_two: responses.ltcf_addr_street2,
          city: responses.ltcf_addr_city,
          state: responses.ltcf_addr_state,
          zip_code: responses.ltcf_addr_zip,
        },
      },
    }),
    alternate_language_format: responses.altwritten,
  };

  return { formattedData, agentID: agentSan };
};
