import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { TextInput, YesNo, DatePicker } from '../../components/inputs';
import { Collapse } from '../../../../common/components';
import LTCF from './LTCF';

import '../../styles.scss';

function Miscellaneous ({ pageTitle }) {
  const { ltcf } = useSelector(store => store.evalues);

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <LTCF />
      </div>
    </div>
  );  
}

export default Miscellaneous;
