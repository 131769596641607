import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

// Components
import Topbar from '../../components/Topbar';

// Util
import { getTPMODisclaimerText } from '../../common/utils/tpmo';

// Design ticket: https://mymedicarebot.atlassian.net/browse/MYR-4919
function DisclaimerPage(props) {
  let { disclaimerLastUpdated  } = useSelector(store => store.theme);
  const { carrierNonDiscriminationNotices = [], cmsID, siteAgency } = useSelector(store => store.theme);
  const { effectiveDate, county: countyFips } = useSelector(store => store.shopper);
  const { carrierPermissions = [] } = useSelector(store => store.agent);
  const [tpmoDisclaimerText, setTpmoDisclaimerText] = useState('');

  useEffect(() => {
    const planYear = moment(effectiveDate).year();
    getTPMODisclaimerText({ carrierPermissions, planYear, countyFips })
      .then(text => setTpmoDisclaimerText(text));
  }, []);

  // const lastBuildDate = Number(version) ? toDateString(Date(version)) : 'DEV';
  // const lastBuildDate = version;

  return (
    <>
      <Topbar />
      <div className='mmb-container'>
        <div className='bg-white w-100 d-flex h-100 align-items-center flex-column'>
          <div className='d-flex flex-column align-items-start ms-4 me-4' style={{ maxWidth: '700px' }}>
            <div className="text-serif fw-bold fs-1 mt-4">Medicare Disclaimers</div>
            <div className="fs-3 mb-3">Health Plan Disclaimers</div>
            <div className="mb-3">
              {tpmoDisclaimerText}
            </div>
            <div className="mb-3">
              <span className="fw-bold me-1">{siteAgency}</span> represents Medicare Advantage (HMO, PPO, PFFS, and PDP) organizations
              that have a Medicare contract. Enrollment depends on the plan’s contract renewal.
            </div>
            <div className="mb-3">
              Every year, Medicare evaluates plans based on a 5-star rating system.
            </div>
            <div className="mb-3">
              The plans we represent do not discriminate on the basis or race, color, national origin, age,
              disability or sex. To learn more about a plan’s non-discrimination policy, please select the
              insurance carrier below.
            </div>
            <div className="row mb-3">
                {carrierNonDiscriminationNotices.map(notice => (
                  <div className="col-6">
                    <a
                      href={notice.link}
                      className="text-blue ms-1 me-1"
                      target="_blank"
                      alt={`${notice.carrier} non-discrimination notice`}
                    >
                      {notice.carrier}
                    </a>
                  </div>
                ))}
            </div>
            <div className="mb-3">
              This information is not a complete description of benefits. Contact the plan for more information. 
              Not all plans offer all benefits. Benefits may vary by carrier and location. 
              Limitations and exclusions may apply.
            </div>
            <div className="mb-3">
              You must have both Part A and B to enroll in a Medicare Advantage plan. Members may enroll 
              in the plan only during specific times of the year. Contact the plan for more information.
            </div>
            <div className="mb-3">
              For plans with Part D Coverage: You may be able to get Extra Help to pay for your prescription 
              drug premiums and costs. To see if you qualify for Extra Help, call: 1-800-MEDICARE (1-800-
              633-4227). TTY users should call 1-877-486-2048, 24 hours a day/7 days a week or consult 
              <a href="https://www.medicare.gov" className="text-blue ms-1" target="_blank" alt="medicare government site">
                www.medicare.gov
              </a> site. the Social Security Office at 1-800-772-1213 between 7 a.m. and 7 p.m., 
              Monday through Friday. TTY users should call, 1-800-325-0778 or consult 
              <a href="https://www.socialsecurity.gov" className="text-blue ms-1" target="_blank" alt="social security government site link">
                www.socialsecurity.gov
              </a>; or your Medicaid Office.
            </div>
            <div className="mb-3">
              Medicare beneficiaries can file a complaint with the Centers for Medicare & Medicaid Services 
              by calling 1-800-MEDICARE 24 hours a day/7 days a week or using the
              <a href="https://www.medicare.gov" className="text-blue ms-1" target="_blank" alt="medicare government site">
                medicare.gov
              </a> site. Beneficiaries can appoint a representative by submitting CMS Form-1696 (or equivalent written notice).
            </div>
            <div className="mb-3">
              Other Pharmacies, Physicians, and Providers are available in the network.
            </div>
            <div className="mb-3">
              Pharmacies, Physicians, and Providers may also contract with other Plan Sponsors.
            </div>
            <div className="mb-3">
              Out-of-network/non-contracted providers are under no obligation to treat Plan members, except 
              in emergency situations. Please call the Plan’s customer service number or see your Evidence of 
              Coverage for more information, including the cost-sharing that applies to out-of-network services.
            </div>
            <div className="mb-3">
              The Insulin Savings Program in not included on all plans, nor is it available to members who 
              receive Extra Help.
            </div>
            <div className="mb-3">Humana:</div>
            <div className="mb-3">
              The Humana Prescription Drug Plan (PDP) pharmacy network includes limited lower-cost, 
              preferred pharmacies in urban areas of AR, CT, DE, IA, IN, KY, ME, MI, MN, MO, MS, ND,  
              NY, OH, PR, RI, SD, TN, VT, Wi, WV; suburban areas of CT, HI, MA, ME, MI, MT, ND, NJ, 
              NY, PA, PR, RI, WV; and rural areas of IA, MN, MT, ND, NE, SD, VT, WY. There are an 
              extremely limited number of preferred cost share pharmacies in urban areas in the following 
              states: AR, DE, ME, MI, MN, MS, ND, NY, OH, RI and SD; suburban areas of: MT and ND; 
              and rural areas of: ND. The lower costs advertised in our plan materials for these 
              pharmacies may not be available at the pharmacy you use. For up- to date information about 
              our network pharmacies, including whether there are any lower-cost preferred pharmacies in 
              your area, please call Customer Care at 1-800-281-6918 (TTY: 711) or consult the online 
              pharmacy directory at
              <a
                href="https://www.humana.com"
                className="text-blue ms-1"
                target="_blank"
                alt="humana website"
                >
                Humana.com
              </a>.
            </div>
            <div className="mb-3">
              Humana Inc. and its subsidiaries comply with applicable Federal civil rights laws and do 
              not discriminate on the basis of race, color, national origin, ancestry, ethnicity, sex, 
              sexual orientation, gender, gender identity, disability, age, marital status, religion, or 
              language in their programs and activities, including in admission or access to, or 
              treatment or employment in, their programs and activities. 
            </div>
            <div className="mb-3">
              The following department has been designated to handle inquiries regarding 
              Humana’s nondiscrimination policies: Discrimination Grievances, P.O. Box 14618, 
              Lexington, KY 40512-4618, 877-320-1235 (TTY: 711).
            </div>
            <div className="mb-3">
              Auxiliary aids and services, free of charge, are available to you. 877-320-1235 (TTY: 
              711)
            </div>
            <div className="mb-3">
              Humana provides free auxiliary aids and services, such as qualified sign 
              language interpreters, video remote interpretation, and written information 
              in other formats to people with disabilities when such auxiliary aids and 
              services are necessary to ensure an equal opportunity to participate. 
            </div>
            <div className="mb-3">
              This information is available for free in other languages. Please call our customer 
              service number at 877-320-1235 (TTY: 711). Hours of operation: 8 a.m. – 8 p.m. 
              Eastern time. 
            </div>
            <div className="mb-3">
              Español (Spanish): Llame al número indicado para recibir servicios gratuitos de 
              asistencia lingüística. 877-320-1235 (TTY: 711). Horas de operación: 8 a.m. a 8 p.m. 
              hora del este.
            </div>
            <div className="mb-3">
              繁體中文 (Chinese): 本資訊也有其他語言版本可供免費索取。請致電客戶服務部：877-320-1235（聽障專線： 711）。辦公時間：東部時間上午 8 時至晚上 8 時。
            </div>
            <div className="mb-3">
              
            </div>
            <div className="mb-3">
              <a
                href="https://www.aetnamedicare.com/en/footers/disclaimers.html"
                className="text-blue"
                target="_blank"
                alt="aetna disclaimers"
              >
                Aetna Disclaimers
              </a>
            </div>
            <div className="mb-3">
              <a
                href="https://www.allinahealthaetnamedicare.com/en/footers/disclaimers.html"
                className="text-blue"
                target="_blank"
                alt="allina and aetna disclaimers"
              >                
                Allina Health | Aetna Medicare's Disclaimers
              </a>
            </div>
            <div className="mb-3">
              WellCare:
            </div>
            <div className="mb-3">
              WellCare’s pharmacy network includes limited lower-cost, preferred pharmacies in rural 
              areas of MO and NE. The lower costs advertised in our plan materials for these 
              pharmacies may not be available at the pharmacy you use. For up-to-date information 
              about our network pharmacies, including whether there are any lower-cost preferred 
              pharmacies in your area, please call 1-833-444-9088 (TTY 711) for Wellcare No 
              Premium (HMO) and Wellcare Giveback (HMO) in MO or consult the online pharmacy 
              directory at
              <a
                href="https://www.wellcare.com/medicare"
                className="text-blue ms-1"
                target="_blank"
                alt="missouri and nebraska online pharmacy directory"
                >
                www.wellcare.com/medicare
              </a>, and 1-833-542-0693 (TTY 711) for Wellcare 
              No Premium (HMO), Wellcare Giveback (HMO), and Wellcare No Premium Open 
              (PPO) in NE or consult the online pharmacy directory at
              <a
                href="https://www.wellcare.com/NE"
                className="text-blue ms-1"
                target="_blank"
                alt="nebraska online pharmacy directory"
                >
                www.wellcare.com/NE
              </a>.
            </div>
            <div className="mb-3">
              <b>For Allwell Arizona D-SNP plans:</b> Contract services are funded in part under contract 
              with the State of Arizona.
            </div>
            <div className="mb-3">
              <b>For Allwell New Mexico D-SNP plans:</b> Such services are funded in part with the state 
              of New Mexico.
            </div>
            <div className="mb-3">
              <b>For Louisiana D-SNP members:</b> As a WellCare HMO D-SNP member, you have 
              coverage from both Medicare and Medicaid. You receive your Medicare health care and 
              prescription drug coverage through WellCare and are also eligible to receive additional 
              health care services and coverage through Louisiana Medicaid. Learn more about 
              providers who participate in Louisiana Medicaid by visiting 
              <a
                href="https://www.myplan.healthy.la.gov/en/find-provider"
                className="text-blue ms-1"
                target="_blank"
                alt="louisiana medicare and medicaid providers"
              >
                https://www.myplan.healthy.la.gov/en/find-provider
              </a>
              . For detailed information about Louisiana Medicaid benefits, please visit the Medicaid website at 
              <a
                href="https://www.ldh.la.gov/medicaid"
                className="text-blue ms-1 me-1"
                target="_blank"
                alt="louisiana medicaid benefit details"
                >
                https://ldh.la.gov/medicaid
              </a>
              and select the "Learn about Medicaid Services" link.
            </div>
            <div className="mb-3">
              <b>For Louisiana D-SNP prospective enrollees:</b> For detailed information about Louisiana 
              Medicaid benefits, please visit the Medicaid website at               
              <a
                href="https://www.ldh.la.gov/medicaid"
                className="text-blue ms-1"
                target="_blank"
                alt="louisiana d-snp medicaid benefits"
                >
                https://ldh.la.gov/medicaid.
              </a>
            </div>
            <div className="mb-5">
              <b>For Tennessee D-SNP plans:</b> Notice: TennCare is not responsible for payment for these 
              benefits, except for appropriate cost sharing amounts. TennCare is not responsible for 
              guaranteeing the availability or quality of these benefits.
            </div>
            <div className="mb-6">
              <div>CMS ID: {cmsID}</div>
              <div>Last update: {disclaimerLastUpdated}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DisclaimerPage;

