import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import Topbar from '../../components//Topbar';
import { LoaderButton } from '../../common/components';

function RecPlans() {
  const navigate = useNavigate();

  return (
    <>
      <Topbar showAgent />
      <div className='mmb-container show-agent bg-gray-200'>
        <div className='d-flex flex-column w-100 align-items-center bg-white pt-2 shadow-sm'>
          <div className="text-center m-4 mb-0 d-flex" style={{ maxWidth: '600px' }}>
            Thank you! Based on your answers, we recommend the plans below.
          </div>
          <div className="text-center fw-bold m-2 d-flex" style={{ maxWidth: '600px' }}>
            Need help? Our licensed Medicare agents would be happy to assist.
          </div>
          <LoaderButton
            text="Request help"
            loadingText="Loading.."
            className="btn btn-primary fw-bold mt-3 mb-3 fs-5 ps-6 pe-6"
            onClick={() => { navigate('/contact') }}
          />
        </div>
        <div className="bg-white p-2 mt-4" style={{ fontSize: '64px', width: '128px', height: '128px', borderRadius: '50%'  }}>
          ☃️
        </div>
      </div>
    </>
  );
}

export default RecPlans;
