import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spinner } from '../../components';

import { checkIfLoggedIn } from '../../actions/session';

function ContinueEnrollment() {
  const navigate = useNavigate();
  const [ errMsg, setErrMsg ] = useState(false);
  const [ searchParams ] = useSearchParams();
  const quoteID = searchParams.get('q');

  useEffect(() => {
    if (quoteID) {
      navigate(`/enroll/load?quoteID=${quoteID}`);
    } else {
      setErrMsg('Did not receive a quote to open');
    }
  }, []);

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="d-flex col-md-12 justify-content-center mt-5">
          <h3 className="text-muted">One moment while we load your enrollment...</h3>
        </div>
        {errMsg ? (
          <div className="d-flex col-md-12 justify-content-center mt-5">
            <div className="alert alert-warning">
              {errMsg}
            </div>
          </div>
        ) : (
          <div className="d-flex col-md-12 justify-content-center mt-5">
            <Spinner pixelSize="75" />
          </div>
        )}
      </div>
    </div>
  );
}


export default ContinueEnrollment;
