import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { YesNo } from '../../components/inputs';

import '../../styles.scss';

function OSB ({ pageTitle }) {
  const rowClasses = 'input-row';

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <FormRow>
          <YesNo
            label="Are you aware that there are Optional Supplemental Benefits available with this plan?"
            name="osbconf"
          />
        </FormRow>
      </div>
    </div>
  );  
}

export default OSB;
