import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { composeWithDevTools } from '@redux-devtools/extension';

// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import loggerMiddleware from './middleware/logger'
import sessionMiddleware from './middleware/session';
import createRootReducer from './reducers'

const initialState = {};

// const middlewares = [loggerMiddleware, thunkMiddleware]
const middlewares = [thunkMiddleware, sessionMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares)

// const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
const enhancers = [middlewareEnhancer]
const composedEnhancers = composeWithDevTools(...enhancers)

const rootReducer = createRootReducer();
const persistConfig = { key: 'root', storage };
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, initialState, composedEnhancers)
export const persistor = persistStore(store);
