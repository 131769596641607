import React from 'react';

function CarrierAndYearsTable({ theme, name, desc }) {
  const config = theme[name] || [];

  return (
    <div className="col-md-6">
      <h4 className="fw-bold text-center">{name}</h4>
      <p className="text-muted"> {desc} </p>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Carrier</th>
            <th>Years Enabled</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(config).map((carrier) => (
            <tr key={carrier}>
              <td>{carrier}</td>
              <td>
                {config[carrier].join(', ')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CarrierAndYearsTable;
