import React from 'react';

export function parseLine(line) {
  let modifiedLine = line;
  let boldClass = '';
  let italicClass = '';
  let underlineClass = '';
  let strikeClass = '';
  if (line.search('<b>') !== -1) {
    boldClass = 'fw-bold';
    modifiedLine = modifiedLine.replace('<b>', '');
  }
  if (line.search('<i>') !== -1) {
    italicClass = 'fst-italic';
    modifiedLine = modifiedLine.replace('<i>', '');
  }
  if (line.search('<u>') !== -1) {
    underlineClass = 'text-decoration-underline';
    modifiedLine = modifiedLine.replace('<u>', '');
  }
  if (line.search('<s>') !== -1) {
    strikeClass = 'text-decoration-line-through';
    modifiedLine = modifiedLine.replace('<s>', '');
  }

  return (
    <span className={`${boldClass} ${italicClass} ${underlineClass} ${strikeClass}`}>
      {modifiedLine}
    </span>
  );
}

export function splitDoctorName(nameString) {
  const parsed = {
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
  };
  const prefixesRE = /(dr|mr|mrs|ms|mz).?/gi;
  const namesArray = nameString.split(' ');

  // if length 2, should always be first and last name
  if (namesArray.length === 2) {
    [ parsed.firstName, parsed.lastName ] = namesArray;
  } else {
    // if the first 'name' is a prefix of some sort, ignore it
    if (namesArray[0].search(prefixesRE) >= 0) namesArray.shift(); // remove prefix
    parsed.firstName = namesArray.shift(); // first element is first name
    parsed.lastName = namesArray.pop(); // last element is last name
    parsed.middleName = namesArray.join(' '); // remaining elements are middle name(s)
  }

  return parsed;
}
