import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { Collapse } from '../../../../common/components';
import {
  Checkbox,
  TextBlock,
  TextInput,
  YesNo,
  Select,
} from '../../components/inputs';
import EmergencyContact from './Emergency';

import '../../styles.scss';

function Email ({ pageTitle }) {
  const { carrierName } = useSelector(store => store.enrollment);
  const { hasemer } = useSelector(store => store.evalues);

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>

        <div className="mb-3 text-muted">
          There may be times when the plan will use an automated system to call or text you.
          When that happens we will be sure to use the telephone number you provided.
        </div>

        <FormRow>
          <TextInput
            autoFocus
            label="Phone number (only numbers, no spaces)"
            name="home_phone"
          />
        </FormRow>

        <TextBlock
          name="emaildisclaimer"
        />

        <FormRow>
          <TextInput
            label="Email Address"
            name="email"
          />
        </FormRow>

        <FormRow
          showIfFieldsExist={[
            'eobbymail',
            'plandocsbymail',
            'billbymail',
            'okemail',
          ]}
        >
          <Checkbox
            label="You can receive the plan materials electronically. If you choose to receive plan materials by e-mail/online, please check this 
box."
            name="okemail"
          />
          <Checkbox
            label="Do you want to receive your explanation of benefits by mail?"
            name="eobbymail"
          />
          <Checkbox
            label="Do you want to receive your monthly bill by mail?"
            name="billbymail"
          />
          <Checkbox
            label="Do you want to receive your plan documents by mail?"
            name="plandocsbymail"
          />
        </FormRow>

        <FormRow >
        </FormRow>

        <FormRow showIfFieldExists="prefcontactmethod">
          <Select
            label="What is your preferred method of contact?"
            name="prefcontactmethod"
          />
        </FormRow>
        
        <EmergencyContact />

      </div>
    </div>
  );  
}

export default Email;
