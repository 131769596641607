import axios from 'axios';
import { fmtQuote } from '../common/utils/format';

export function getQuote(quoteID) {
  const apiBaseUrl = process.env.REACT_APP_MMB_API_URL;
  const token = localStorage.getItem('SessionToken');

  return axios({
    url: `${apiBaseUrl}/api/shopper/quote/${quoteID}/`,
    method: 'get',
    headers: { Authorization: `Token ${token}` },
    // this action is called before the app mounts and therefore doesn't
    // have the axios defaults set when it is called. Specify them explicitly.
    params: { site: process.env.REACT_APP_HOSTNAME || window.location.hostname },
  }).then((res) => fmtQuote(res.data));
};

export function loadQuotes(username) {

};

export function retrieveAndGetQuote({
  birthdate,
  username,
  quote_id,
  quote_code,
  phone,
}) {
  const apiBaseUrl = process.env.REACT_APP_MMB_API_URL;

  return axios({
      method: 'post',
      url: `${apiBaseUrl}/api/shopper/getquote/`,
      data: {
        birthdate,
        username,
        quote_id,
        quote_code,
        phone
      }
    }).then(res => {
        const quote = res.data ? fmtQuote(res.data) : null;
        return quote;
    });
};
