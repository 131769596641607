/* eslint-disable */
import * as moment from 'moment';
import { fallback, yesNo, yn } from './mapping-tools';
import { stripPhone, cleanDate } from './formatting';
import getAgentID from './getAgentID';

export default ({
  agent,
  quote,
  fields: res,
  plan,
  theme,
  confirmationNumber: confirmation,
}) => {

  const useCarrierPerms = theme.useCarrierPerms;

  let agentID;
  if (useCarrierPerms) {
    agentID = getAgentID({ agent, quote }).agentID;
  } else {
    agentID = agent.npn;
  }

  const isSEP = res.enrolltype === 'SEP';

  const premiumDeducted = {
    'rrb': 'Yes',
    'ssi': 'Yes',
    'cb': 'No',
    'eft': 'EFT',
  }[res.paymeth] || '';
  const premiumWithold = ['rrb', 'ssi'].includes(res.paymeth) ? res.paymeth.toUpperCase() : '';

  const planYear = plan.PlanYear;
  const isEFT = res.paymeth === 'eft';
  const eftActType = (res.eft_acttype === 'checking') ? 'Checking' : 'Savings';
  const effectiveDate = moment(res.effective_date).format('MMDDYYYY');
  const ltcfAddress = res.ltcf
    ? `${res.ltcf_addr_street1}${res.ltcf_addr_street2 ? ` ${res.ltcf_addr_street2}` : ''}, ${res.ltcf_addr_city}, ${res.ltcf_addr_state} ${res.ltcf_addr_zip}`
    : '';
  const creditableCoverage = typeof res.creditablecov === 'boolean' ? yn(res.creditablecov) : '';

  const affinityIDMap = theme.aetnaAffinityIDMap || {
    'ma': 'MyMedAgency_net',
    'mapd': 'MyMedAgency_net',
    'pdp': 'MyMedAgency_net',
  };
  const planType = plan.PlanTypeDesc.toLowerCase();
  const affinityID = affinityIDMap[planType];

  const sepMap = {
    ACC: 'S',
    CHR: 'S',
    NEW: 'E',
    ICE: 'I',
    RET: 'S',
    MRD: 'F',
    MOV: 'V',
    RUS: 'V',
    INC: 'S',
    LAW: 'S',
    LT2: 'T',
    LTC: (planType === 'pdp') ? 'S' : 'T',
    LEC: 'W',
    LCC: 'S',
    EOC: 'S',
    MYT: 'S',
    NON: 'S',
    PAC: 'S',
    SNP: 'S',
    MDE: 'L',
    MCD: 'U',
    NLS: 'U',
    LIS: 'U',
    DIF: 'U',
    PAP: 'S',
    '12G': 'S',
    DST: 'S',
    '12J': 'S',
    '5ST': 'R',
    LPI: 'S',
    REC: 'S',
    OTH: 'S',
    IEP: 'E',
    ICEP: 'I',
    OEP: (planType === 'mapd' || planType === 'ma') ? 'M' : 'S',
    // 'OEP-N': (planType === 'mapd' || planType === 'ma') ? 'M' : 'S',
    // 'OEP-I': planType === 'pdp' ? 'S' : 'T',
  };
  // Not in aetna docs, mapping election period to SEP map to use:
  // IEP = NEW
  // ICEP = ICE
  // OEP-I = LTC/LT2
  // OEP-N = OEP
  const electionPeriodMap = {
    'AEP': 'A',
    'SEP': sepMap[res.sep_code],
  };
  const electionType = electionPeriodMap[res.enrolltype];
  const sepDate = res.sep_date ? moment(res.sep_date).format('MMDDYYYY') : '';

  let race = '';
  let ethnicity = '';

  if (Array.isArray(res.race) && res.race.length) {
    race = res.race.join(',');
  }
  if (Array.isArray(res.ethnicity) && res.ethnicity.length) {
    ethnicity = res.ethnicity.join(',');
  }

  let appMethod = 'E' // Electronic
  if (res.meetType === 'inPerson') appMethod = 'T'; // Telephonic
  if (res.meetType === 'threeWayCall') appMethod = 'T'; // Telephonic

  const formattedData = [
    { col: 'ConfirmationNumber', val: confirmation },
    { col: 'SignatureDate', val: moment().format('MMDDYYYY') },
    { col: 'ContractID', val: plan.ExternalContractID },
    { col: 'PlanID', val: plan.ExternalPlanID },
    { col: 'SOA', val: yn(res.ack_soa) },
    { col: 'ApplicantTitle', val: '' },
    { col: 'ApplicantFirstName', val: res.first_name },
    { col: 'ApplicantMiddleInitial', val: res.middle_name },
    { col: 'ApplicantLastName', val: res.last_name },
    { col: 'ApplicantBirthDate', val: moment(res.birthdate).format('MMDDYYYY') },
    { col: 'ApplicantGender', val: res.gender },
    { col: 'ApplicantAddress1', val: res.street1 },
    { col: 'ApplicantAddress2', val: res.street2 },
    { col: 'RequestedCounty', val: res.county_name },
    { col: 'ApplicantCity', val: (res.city || '').slice(0, 30) },
    { col: 'ApplicantState', val: res.state.toUpperCase() },
    { col: 'ApplicantZip', val: res.zipcode},
    { col: 'ApplicantEmailAddress', val: res.email },
    { col: 'ApplicantPhone', val: stripPhone(res.home_phone) },
    { col: 'MedicareNumber', val: res.medicare_id },
    { col: 'ApplicationSSN', val: ''}, // Aetna spec says 'Should never be valued'
    { col: 'MailingAddress1', val: res.samemail ? res.street1 : res.mailstreet1 },
    { col: 'MailingAddress2', val: res.samemail ? res.street2 : res.mailstreet2 },
    { col: 'ExistingPatientIndicator', val: res.pcp_name ? yn(res.pcp_established) : '' },
    { col: 'MailingCity', val: res.samemail ? res.city : res.mailcity },
    { col: 'MailingState', val: res.samemail ? res.state : res.mailstate },
    { col: 'MailingZip', val: res.samemail ? res.zipcode : res.mailzip},
    { col: 'MedicarePartA', val: cleanDate(res.part_a_effective_date) },
    { col: 'MedicarePartB', val: cleanDate(res.part_b_effective_date) },
    { col: 'AccountHolderName', val: isEFT ? res.eft_actname : '' },
    { col: 'RoutingNumber', val: isEFT ? res.eft_routing : '' },
    { col: 'BankAccountNumber', val: isEFT ? res.eft_actnum : '' },
    { col: 'PremiumDeducted', val: premiumDeducted },
    { col: 'AccountType', val: isEFT ? eftActType : '' },
    { col: 'OtherCoverage', val: yn(res.othercoverage) }, // required for MA/PD but not PDP
    { col: 'OtherCoverageName', val: res.othercoverage ? res.othercoverage_name : '' },
    { col: 'OtherCoverageID', val: res.othercoverage ? res.othercoverage_num : '' },
    { col: 'LongTerm', val: yesNo(res.ltcf) },
    { col: 'LongTermName', val: res.ltcf ? res.ltcf_name : '' },
    { col: 'LongTermAddress', val: ltcfAddress },
    { col: 'LongTermPhone', val: res.ltcf ? stripPhone(res.ltcf_tel) : '' },
    { col: 'AuthorizedRepName', val: res.ispoa ? `${res.poa_fname} ${res.poa_lname}` : '' },
    { col: 'AuthorizedRepAddress', val: res.ispoa ? `${res.poa_addr_street1} ${res.poa_addr_street2 || ''}`.trim() : '' },
    { col: 'AuthorizedRepCity', val: res.ispoa ? res.poa_addr_city : '' },
    { col: 'AuthorizedRepState', val: res.ispoa ? res.poa_addr_state : '' },
    { col: 'AuthorizedRepZip', val: res.ispoa ? res.poa_addr_zip : '' },
    { col: 'AuthorizedRepPhone', val: res.ispoa ? res.poa_tel : '' },
    { col: 'AuthorizedRepRelationship', val: res.ispoa ? res.poa_rel : '' },
    { col: 'SpokenLanguage', val: res.preflang ? res.preflang : '' },
    { col: 'ESRD', val: '' }, // Per CMS as of 12/31/2020 ESRD no longer applicable (End-Stage Renal Disease)
    { col: 'StateMedicaid', val: res.onmedicaid ? res.medicaidnum : 'No' },
    { col: 'WorkStatus', val: yn(res.willwork || res.willworkspouse) },
    { col: 'PrimaryCarePhysician', val: res.pcp_name || '' },
    { col: 'OtherCoverageGroup', val: res.othercoverage_group },
    { col: 'FSR', val: '' }, // Aetna internal use only
    { col: 'SubmitTime', val: moment().format('YYYY-MM-DD HH:mm:ss.SSS') },
    { col: 'WrittenLanguage', val: res.preflang ? res.preflang : '' },
    { col: 'MobilePhoneIndicator', val: '' }, // we don't collect this info on our form and it shouldn't be required
    { col: 'Race', val: race },
    { col: 'Ethnicity', val: ethnicity },
    { col: 'LISCopayLevelID', val: '' }, // Aetna internal use only
    { col: 'DeemedCopayLevelID', val: '' }, // Aetna internal use only
    { col: 'ElectronicEOBStatements', val: '' }, // Aetna internal use only
    { col: 'PremiumWithhold', val: premiumWithold },
    { col: 'SEPCMSReason', val: isSEP ? res.sep_other : '' },
    { col: 'GeneralAgentTIN', val: '' },
    { col: 'BrokerTIN', val: '' },
    { col: 'Channel', val: affinityID },
    { col: 'AppMethod', val: appMethod }, // opt, skipping
    { col: 'ProviderMedicalGroup', val: '' }, // skipping, from spec: California and DSNP plans only
    { col: 'OSBCode', val: '' }, // skipping, we don't support OSB selection on enrollment
    { col: 'ProviderID', val: res.pcp_num || '' },
    { col: 'MedicaidNumber', val: '' }, // skipping, required only for DSNP plans
    { col: 'EnrPlanYear', val: planYear },
    { col: 'RequestedEffectiveDate', val: effectiveDate },
    { col: 'ElectionType', val: electionType },
    { col: 'BrokerNPN', val: agentID },
    { col: 'GroupName', val: '' }, // from spec: "Group Name the applicant previous belonged to." ???
    { col: 'WritingAgentTIN', val: '' }, // we don't store this
    { col: 'SEPCode1', val: isSEP ? res.sep_code : '' },
    { col: 'SEPEffDate1', val: isSEP ? sepDate : '' },
    { col: 'SEPCode2', val: '' }, // I don't know why there are multiple slots for SEP codes?
    { col: 'SEPEffDate2', val: '' },
    { col: 'SEPCode3', val: '' },
    { col: 'SEPEffDate3', val: '' },
    { col: 'SupplierID', val: '' }, // Aetna internal use only
    { col: 'ImageReferenceNumber', val: '' }, // Paper Application Reference # from keying vendor.
    { col: 'DateMarketReceived', val: '' }, // Date Agent rec'd Application
    { col: 'HomeOfficeRec Date', val: '' }, // Date Aetna Rec'd Application
    { col: 'PrimaryCareID', val: res.primarycare_id || '' },
    { col: 'ClientInternalID', val: '' }, // should not be valued
    { col: 'ClientInternalCode', val: '' }, // should not be valued
    { col: 'MediaType', val: '' }, // only applies to DSNP which we don't enroll
    { col: 'SignatureInd', val: '' }, // Internal Use for paper applications
    { col: 'DSNPInd', val: '' }, // Internal Use for paper applications
    { col: 'CreditCovInd', val: creditableCoverage },
    { col: 'CreditCovStartDt', val: (creditableCoverage === 'Y') ? cleanDate(res.creditablecov_start) : '' },
    { col: 'CreditCovEndDt', val: (creditableCoverage === 'Y') ? cleanDate(res.creditablecov_end) : '' },
    { col: 'CreditCovName', val: (creditableCoverage === 'Y') ? (res.creditablecov_name || '') : '' },
  ];

  return { formattedData, agentID };
};
