import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, light } from '@fortawesome/fontawesome-svg-core/import.macro';

function PlanCoverage({ coverage = [{ label: 'COVERAGE_ITEM', value: false }] }) {
  return (
    <>
      {coverage.length
         ? coverage.map(function(c) {
           return (
             <div
               className={[
                 'd-flex ps-1 pe-1 m-1 rounded-sm border fs-5',
                 c.value
                   ? 'border-primaryFocus bg-primaryFocusHover'
                   : 'border-gray-600 bg-gray-100 text-gray-600',
               ].join(' ')}
               style={{ }}
               key={c.label}
             >
               <FontAwesomeIcon
                 icon={c.value ? solid('check') : solid('times')}
                 className={`mt-auto mb-auto fs-4 ${c.value ? 'text-blue' : 'text-gray-500'}`}
               />
               <div className="mt-auto mb-0 ms-1">{c.label}</div>
             </div>
           );
         })
       : null}     
    </>
  );
};
export default PlanCoverage;
