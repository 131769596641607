import axios from 'axios';
import { store } from '../store';
import { fmtShopper } from '../common/utils/format';

export const isShopperRegistered = (username) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/shopper/registered/${username}`,
    }).then(({ data }) => {
      resolve(data);
    }).catch((err) => {
      reject(err);
    });
  });
}

export const logoutShopper = () => {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/token-auth/expire/`,
    headers: { Authorization: `Token ${token}` },
    data: { token },
  }).finally(() => {
    localStorage.removeItem('SessionToken');
    store.dispatch({ type: 'CLEAR_SESSION' });
    window.location = '/login';
  });
}

export function getShopper(username, options = {}) {
  const { formatted = true } = options;
  console.log('formatted: ', formatted);
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/shopper/shopper/${username}/`,
    headers: { Authorization: `Token ${token}` },
  }).then(({ data }) => formatted ? fmtShopper(data) : data);
}

export function createShopper({ name, email, password, username }) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/shopper/register/`,
    data: {
      first_name: name,
      email,
      password,
      username,
    },
  }).then(({ data }) => fmtShopper(data));
};

export function loginShopper({ email, password, verification_code, fingerprint }) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/token-auth/shopper/`,
    data: {
      email,
      password,
      verification_code,
      fingerprint,
    },
  }).then(({ data }) => data);
};
