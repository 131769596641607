import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import {
  Checkbox,
  TextBlock,
  TextInput,
} from '../../components/inputs';

import '../../styles.scss';

function Address ({ pageTitle }) {
  const sameMail = useSelector(store => store.evalues.samemail);
  const { city, state, zip, countyName } = useSelector(store => store.enrollment.quote);

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <FormRow />
        <FormRow>
          <TextBlock
            name="address_perm"
          />
        </FormRow>
        <FormRow>
          <p>
            <i className="text-muted">
              If you want to join a plan but have no permanent residence, a Post Office Box,
              an address of a shelter or clinic, or the address where you receive mail
              (e.g., social security checks) may be considered your permanent residence address.
            </i>
          </p>
          <TextInput
            autoFocus
            label="Street Address Line 1 (P.O. Box is not allowed)"
            name="street1"
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="Street Address Line 2"
            name="street2"
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="City"
            name="city"
            staticValue={city}
            disabled
          />
        </FormRow>
        <FormRow className="col-md-3">
          <TextInput
            label="State"
            name="state"
            staticValue={state}
            disabled
            small
          />
        </FormRow>
        <FormRow className="col-md-3">
          <TextInput
            label="ZIP code"
            name="zipcode"
            staticValue={zip}
            disabled
            small
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="County"
            name="county_name"
            disabled
            staticValue={countyName}
          />
        </FormRow>

        <FormRow>
          <TextBlock
            name="address_mail"
          />
          <Checkbox
            label="My mailing address is the same as my permanent resident address"
            name="samemail"
          />
        </FormRow>

        <FormRow>
          <TextInput
            clone={sameMail ? 'street1' : ''}
            label="Mailing Address Line 1"
            name="mailstreet1"
          />
        </FormRow>
        <FormRow>
          <TextInput
            clone={sameMail ? 'street2' : ''}
            label="Mailing Address Line 2"
            name="mailstreet2"
          />
        </FormRow>
        <FormRow>
          <TextInput
            clone={sameMail ? 'city' : ''}
            label="City"
            name="mailcity"
          />
        </FormRow>
        <FormRow className="col-md-3">
          <TextInput
            clone={sameMail ? 'state' : ''}
            label="State"
            name="mailstate"
            small
          />
        </FormRow>
        <FormRow className="col-md-3">
          <TextInput
            clone={sameMail ? 'zipcode' : ''}
            label="ZIP code"
            name="mailzip"
            small
          />
        </FormRow>
      </div>
    </div>
  );  
}

export default Address;
