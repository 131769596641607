import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getRoutes, getAllFields, isRequired } from '../../../../utils/enroll';

import SubmitButton from './SubmitButton';

import '../../styles.scss';

function Footer ({
}) {

  const navigate = useNavigate();
  const {
    form,
    baseRoute,
    errors: {
      message: errMsg,
      errors = [],
    } = {},
  } = useSelector(store => store.enrollment);
  const { evalues, evalid } = useSelector(store => store);
  const routes = getRoutes(form, baseRoute);
  const location = useLocation();
  const currentRoute = location.pathname;
  const nextRoute = routes[routes.indexOf(currentRoute) + 1]
  const previousRoute = routes[routes.indexOf(currentRoute) - 1]

  const [ allFieldsValid, setAllFieldsValid ] = useState(false);

  // check to see if all required fields are valid
  useEffect(() => {
    let allFieldsValid = true;
    const allFields = getAllFields(form);
    allFields.forEach(field => {
      const required = isRequired({ form, name: field.name, currentValues: evalues });
      const valid = evalid[field.name];
      if (required && !valid) allFieldsValid = false;
      if (!required && (valid === false)) allFieldsValid = false;
    });
    setAllFieldsValid(allFieldsValid);
  }, [evalid]);

  return (
    <>
      {!allFieldsValid && !Boolean(nextRoute) && (
        <span className="text-danger">
          You are missing required fields, or have entered invalid information
        </span>
      )}
      {errMsg && (
        <span title={errors.join(' ')} className="text-danger">
          {errMsg}
        </span>
      )}
      <div>
        {Boolean(nextRoute) ? (
          <button
            className="btn btn-primary custom-nav-button mt-3"
            onClick={() => navigate(nextRoute)}
          >
            Next
          </button>
        ) : (
          <SubmitButton disabled={!allFieldsValid} />
        )}
        <br />
        <button
          className="btn btn-link custom-link-button mt-2"
          onClick={() => navigate(previousRoute)}
          disabled={!Boolean(previousRoute)}
        >
          Previous
        </button>
      </div>
    </>
  );  
}

export default Footer;
