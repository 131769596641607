const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_AGENT':
      return { ...state, ...action.payload };
    case 'SET_AGENT_FIELD':
      return { ...state, ...action.payload };
    case 'CLEAR_AGENT':
      return initialState;
    default:
      return state;
  }
}
