import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { TextInput, YesNo } from '../../components/inputs';
import { Collapse } from '../../../../common/components';

import { getField } from '../../../../utils/enroll';

import '../../styles.scss';

function OtherCoverage ({ pageTitle }) {
  const dispatch = useDispatch();
  const rowClasses = 'input-row';
  const {
    othercoverage,
    onmedicaid,
    otherrxcov,
    otherrxcov_group_num,
  } = useSelector(store => store.evalues);
  const { plan: { planName, planYear } } = useSelector(store => store.enrollment);

  const { form } = useSelector(store => store.enrollment);
  const otherrxcovExists = getField(form, 'otherrxcov');
  const otherrxcovGroupNumExists = getField(form, 'otherrxcov_group_num');
  const othercoverageExists = getField(form, 'othercoverage');

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>

        <FormRow hide={!otherrxcovExists}>
          <YesNo
            label={`Will you have other prescription drug coverage (like VA, TRICARE) in addition to ${planName}?`}
            name="otherrxcov"
            unsetFieldsOnFalse={['otherrxcov_name', 'otherrxcov_id', 'otherrxcov_num', 'otherrxcov_group_num']}
          />
        </FormRow>
        <Collapse indent id="otherrxcov-collapse" isOpen={otherrxcov}>
          <FormRow>
            <TextInput
              label="Name of other coverage"
              name="otherrxcov_name"
            />
          </FormRow>
          <FormRow>
            <TextInput
              label="Member number for other coverage"
              name="otherrxcov_id"
            />
          </FormRow>
          <FormRow>
            <TextInput
              label="Policy number for other coverage"
              name="otherrxcov_num"
            />
          </FormRow>
          <FormRow hide={!otherrxcovGroupNumExists}>
            <TextInput
              label="Group number for other coverage"
              name="otherrxcov_group_num"
            />
          </FormRow>
        </Collapse>

        <FormRow hide={!othercoverageExists}>
          <YesNo
            label={`Will you have other prescription drug coverage (like VA, TRICARE) in addition to ${planName}?`}
            name="othercoverage"
            unsetFieldsOnFalse={[
              'othercoverage_name',
              'othercoverage_num',
              'othercoverage_id',
              'othercoverage_rx',
              'othercoverage_group',
            ]}
          />
        </FormRow>
        <Collapse indent id="othercoverage" isOpen={othercoverage}>
          <FormRow>
            <TextInput
              label="Name of other coverage"
              name="othercoverage_name"
            />
          </FormRow>
          <FormRow>
            <TextInput
              label="Member ID for other coverage"
              name="othercoverage_id"
            />
          </FormRow>
          <FormRow>
            <TextInput
              label="Policy number for other coverage"
              name="othercoverage_num"
            />
          </FormRow>
          <FormRow>
            <TextInput
              label="Group number for other coverage"
              name="othercoverage_group"
            />
          </FormRow>
          <FormRow>
            <YesNo
              label="Does your other coverage include RX coverage?"
              name="othercoverage_rx"
            />
          </FormRow>
        </Collapse>

        <FormRow>
          <YesNo
            label="Are you currently on state Medicaid?"
            name="onmedicaid"
            unsetFieldsOnFalse={['medicaidnum']}
          />
        </FormRow>
        <Collapse indent isOpen={onmedicaid}>
          <FormRow>
            <TextInput
              label="What is your state Medicaid number?"
              name="medicaidnum"
            />
          </FormRow>
        </Collapse>

        {/* Skilled Nursing */}
        <FormRow showIfFieldExists="skilled_nursing">
          <YesNo
            label="Skilled nursing"
            name="skilled_nursing"
          />
        </FormRow>

        {/* Continuity Care */}
        <FormRow showIfFieldExists="continuity_care">
          <YesNo
            label="Continuity of care"
            name="continuity_care"
          />
        </FormRow>

      </div>
    </div>
  );  
}

export default OtherCoverage;
