import React from 'react';
import { parseLine } from '../../../../common/utils/text';

function RequiredStar() {
  return <span className="me-1 fw-bold fs-4 text-danger">*</span>;
}

function parseLabelArray(labelArray, req) {
  // if the label doesn't have any break tags then we don't want
  // the additional padding that a <p> tag creates
  if (labelArray.length === 1) {
    if (req) return <><RequiredStar />{parseLine(labelArray[0])}</>;
    return parseLine(labelArray[0]);
  }
  return labelArray.map((line, i) => {
    // if field is required, add the start to the first paragraph
    // of the label
    if ((i === 0) && req) {
      return (
        <p>
          <RequiredStar />
          {parseLine(line)}
        </p>
      );
    }
    return (
      <p>
        {parseLine(line)}
      </p>
    );
  });
}

function FormLabel({
  className,
  label = '',
  name,
  required,
}) {
  const splitOnBreakTags = label.split('<br>');

  return (
    <label htmlFor={name} alt={label} className={['mb-2', className].join(' ')}>
      {parseLabelArray(splitOnBreakTags, required)}
    </label>
  );
}

export default FormLabel;
