/* eslint-disable import/prefer-default-export */
import axios from 'axios';

// Returns a site_config value
export function getAgentCountyPlanCount({ carrierIDs, countyFips, planYear }) {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/count/${planYear}/${countyFips}/`,
    headers: { Authorization: `Token ${token}` },
    data: carrierIDs,
  })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
      return '[NOT FOUND]';
    });
}
