/* eslint-disable consistent-return,object-curly-newline */
import moment from 'moment';
import humana from './formatHumana';
import aetna from './formatAetna';
import aetnaOld from './formatAetnaOld';
import cigna from './formatCigna';
import cignaOld from './formatCignaOld';
import united from './formatUnitedHealthcare';
import unitedOld from './formatUnitedHealthcareOld';
import wellcare from './formatWellCare';
import wellcareOld from './formatWellCareOld';
import clover2022 from './formatClover2022';
import clover2023 from './formatClover2023';
import clover2024 from './formatClover2024';
import anthem from './formatAnthem';
import molina from './formatMolina';
import zing from './formatZing';

export const formatHumana = humana;
export const formatAetna = aetna;
export const formatAetnaOld = aetnaOld;
export const formatCigna = cigna;
export const formatCignaOld = cignaOld;
export const formatUnitedHealthcare = united;
export const formatUnitedHealthcareOld = unitedOld;
export const formatWellCare = wellcare;
export const formatWellCareOld = wellcareOld;
export const formatClover2022 = clover2022;
export const formatClover2023 = clover2023;
export const formatClover2024 = clover2024;
export const formatZing = zing;


export const formatOEC = (carrier, {
  agent,
  quote,
  fields,
  plan,
  theme,
  confirmationNumber,
}) => {
  const { useOldWellcareFormat } = theme;
  switch (carrier.toLowerCase()) {
    case ('aetna'):
      return aetna({ agent, quote, fields, plan, theme, confirmationNumber });
    case ('cigna'):
      return cigna({ agent, quote, fields, plan, theme, confirmationNumber });
    case ('wellcare'):
      if (useOldWellcareFormat) {
        return wellcareOld({ agent, quote, fields, plan, theme, confirmationNumber });
      }
      return wellcare({ agent, quote, fields, plan, theme, confirmationNumber });
    case ('clover health'):
      if (moment(quote.effectiveDate).year() === 2022) {
        return clover2022({ agent, quote, fields, plan, theme, confirmationNumber });
      } if (moment(quote.effectiveDate).year() === 2023) {
        return clover2023({ agent, quote, fields, plan, theme, confirmationNumber });
      } if (moment(quote.effectiveDate).year() === 2024) {
        return clover2024({ agent, quote, fields, plan, theme, confirmationNumber });
      }
      break;
    case ('unitedhealthcare'):
      return united({ agent, quote, fields, plan, theme, confirmationNumber });
    case ('humana'):
      return humana({ agent, quote, fields, plan, theme, confirmationNumber });
    case ('anthem'):
      return anthem({ agent, quote, fields, plan, theme, confirmationNumber });
    case ('molina'):
      return molina({ agent, quote, fields, plan, theme, confirmationNumber });
    case ('zing health'):
      return zing({ agent, quote, fields, plan, theme, confirmationNumber });
    default:
      console.log(`OEC formatting not specified for ${carrier}`);
  }
};
