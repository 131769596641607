/* eslint-disable */
import React from 'react';

function AgreementsBox({ plan = {} }) {
  const { carrierName, planName, planType, plan: docurobotPlan = {} } = plan;
  const { MedicalPlanTypeCode } = docurobotPlan;
  const isPDP = planType.toLowerCase() === 'pdp';
  // MAPD plans need the "is prescription drug plan?" additional logic in PFFS verbiage
  const isPD = planType.toLowerCase().indexOf('pd') !== -1;
  const isPFFS = MedicalPlanTypeCode === 220 || MedicalPlanTypeCode === 240;

  // Non-PFFS plans can be MA/MAPD/PDP, but only MA/MAPD plans can have "multiple" plans. PDPs cannot.
  const PFFSAgreements = (
    <>
      <p>
        {planName} is a Medicare Private Fee-For-Service plan and has a contract with the Federal government.
      </p>

      <p>
        I must keep{isPD ? ' both' : ''} Hospital (Part A) {isPD ? 'and' : 'or'} Medical (Part B) to stay in {planName}
      </p>

      <p>
        I understand that this plan is a Medicare Advantage Private-Fee-For-Service plan and I can be in only one Medicare health plan at a time. I understand that my enrollment in this plan will automatically end my enrollment in another Medicare health plan{isPD ? ' or Medicare prescription drug plan' : ''}. It is my responsibility to inform you of any prescription drug coverage that I have or may get in the future.
      </p>

      {!isPD
        ? (<p>
        I understand that since this plan does not offer Medicare prescription drug coverage, I may get coverage from another Medicare prescription drug plan. If I don't have Medicare prescription drug coverage, or creditable prescription drug coverage (as good as Medicare's), I may have to pay a late enrollment penalty if I enroll in Medicare prescription drug coverage in the future.
           </p>
          ) : null}

      <p>
        Enrollment in this plan is generally for the entire year. Once I enroll, I may leave this plan or make changes only at certain times of the year if an enrollment period is available (Example: Annual Enrollment Period from October 15 - December 7 of every year), or under certain special circumstances.
      </p>

      <p>
        As a Medicare Private Fee-For-Service plan, works differently than a Medicare supplement plan as well as other Medicare Advantage plans. {planName} pays instead of Medicare, and I will be responsible for the amounts that {planName} doesn't cover, such as copayments and coinsurances. Original Medicare won't pay for my health care while I am enrolled in {planName}. 
      </p>

      <p>
        Before seeing a provider, I should verify that the provider will accept {planName}. I understand that my health care providers have the right to choose whether to accept's payment terms and conditions every time I see them. I understand that if my provider doesn't accept, I will need to find another provider that will. 
      </p>

      <p>
        {planName} serves a specific service area. If I move out of the area that {planName} serves, I need to  notify {planName} so I can disenroll and find a new plan in my new area. Once I am a member of {planName}, I have the right to appeal plan decisions about payment or services if I disagree. I will read the Evidence of Coverage document from {planName} when I get it to know which rules I must follow in order to get coverage with this Private Fee-For-Service plan. I understand that people with Medicare aren't usually covered under Medicare while out of the country except for limited coverage near the U.S. border. 
      </p>

      <p>
        I understand that if I am getting assistance from a sales agent, broker, or other individual employed by or contracted with {planName}, he/she may be paid based on my enrollment in {planName}.
      </p>
      
      <p>
        <b>Release of Information:</b>  By joining this Medicare health plan, I acknowledge that the Medicare health plan will release my information to Medicare and other plans as is necessary for treatment, payment and health care operations. I also acknowledge that {planName} will release my information{isPD ? ' including my prescription drug event data' : ''} to Medicare, who may release it for research and other purposes which follow all applicable Federal statutes and regulations. The information on this enrollment form is correct to the best of my knowledge. I understand that if I intentionally provide false information on this form, I will be disenrolled from the plan.
      </p>

      <p>
        I  understand that my signature (or the signature of the person authorized to act on my behalf under the laws of the State where I live) on this application means that I have read and understand the contents of this application. If signed by an authorized individual (as described above), this signature certifies that: 1) this person is authorized under State law to complete this enrollment and 2) documentation of this  authority is available upon request from Medicare.
      </p>

      <p>
        {`PRIVACY ACT STATEMENT The Centers for Medicare & Medicaid Services (CMS) collects information from Medicare plans to track beneficiary enrollment in Medicare Advantage (MA) Plans, improve care, and for the payment of Medicare benefits. Sections 1851 of the Social Security Act and 42 CFR §§ 422.50 and 422.60 authorize the collection of this information. CMS may use, disclose and exchange enrollment 
data from Medicare beneficiaries as specified in the System of Records Notice (SORN) "Medicare Advantage Prescription Drug (MARx)", System No. 09-70-0588. Your response to this form is voluntary. However, failure to respond may affect enrollment in the plan. `}
      </p>
    </>
  );

  const MAAgreements = (
    <>
      <p>
        I must keep both Hospital (Part A) and Medical (Part B) to stay in {planName}
      </p>

      <p>
        By joining this Medicare Advantage plan, I acknowledge that {planName} will share my information with Medicare,
        who may use it to track my enrollment, to make payments, and for other purposes allowed by Federal law that
        authorize the collection of this information (see Privacy Act Statement below). My response to this form is voluntary.
        However, failure to respond may affect enrollment in the plan.
      </p>

      <p>
        I understand that I can be enrolled in only one MA plan at a time – and that enrollment in this plan will automatically end my enrollment
        in another MA plan (exceptions apply for MA PFFS, MA MSA plans).
      </p>

      <p>
        {`I understand that when my coverage begins, I must get all of my medical benefits from ${planName}.
        Benefits and services provided by and contained in my "Evidence of Coverage" document (also known as a member contract
        or subscriber agreement) will be covered. Neither Medicare nor ${carrierName} will pay for benefits or services that are not covered.`}
      </p>

      <p>
        The information on this enrollment form is correct to the best of my knowledge. I understand that if I intentionally provide false
        information on this form, I will be disenrolled from the plan.
      </p>

      <p>
        I understand that my signature (or the signature of the person legally authorized to act on my behalf) on this application means that
        I have read and understand the contents of this application. If signed by an authorized representative (as described above),
        this signature certifies that: 1) This person is authorized under State law to complete this enrollment, and 2)
        Documentation of this authority is available upon request by Medicare.
      </p>

      <p>
        {`PRIVACY ACT STATEMENT The Centers for Medicare & Medicaid Services (CMS) collects information from Medicare plans to track beneficiary
        enrollment in Medicare Advantage (MA) Plans, improve care, and for the payment of Medicare benefits. Sections 1851 of the Social Security Act
        and 42 CFR and 422.50 and 422.60 authorize the collection of this information. CMS may use, disclose and exchange
        enrollment data from Medicare beneficiaries as specified in the System of Records Notice (SORN) "Medicare Advantage
        Prescription Drug (MARx)", System No. 09-70-0588. Your response to this form is voluntary.
        However, failure to respond may affect enrollment in the plan.`}
      </p>
    </>
  );

  const MAPDAgreements = (
    <>
      <p>
        I must keep both Hospital (Part A) and Medical (Part B) to stay in {planName}
      </p>

      <p>
        By joining this Medicare Advantage plan, I acknowledge that {planName} will share my information with Medicare,
        who may use it to track my enrollment, to make payments, and for other purposes allowed by Federal law that
        authorize the collection of this information (see Privacy Act Statement below). My response to this form is voluntary.
        However, failure to respond may affect enrollment in the plan.
      </p>

      <p>
        I understand that I can be enrolled in only one MA plan at a time – and that enrollment in this plan will automatically end my enrollment
        in another MA plan (exceptions apply for MA PFFS, MA MSA plans).
      </p>

      <p>
        {`I understand that when my coverage begins, I must get all of my medical and prescription drug benefits from ${planName}.
        Benefits and services provided by and contained in my "Evidence of Coverage" document (also known as a member contract
        or subscriber agreement) will be covered. Neither Medicare nor ${carrierName} will pay for benefits or services that are not covered.`}
      </p>

      <p>
        The information on this enrollment form is correct to the best of my knowledge. I understand that if I intentionally provide false
        information on this form, I will be disenrolled from the plan.
      </p>

      <p>
        I understand that my signature (or the signature of the person legally authorized to act on my behalf) on this application means that
        I have read and understand the contents of this application. If signed by an authorized representative (as described above),
        this signature certifies that: 1) This person is authorized under State law to complete this enrollment, and 2)
        Documentation of this authority is available upon request by Medicare.
      </p>

      <p>
        {`PRIVACY ACT STATEMENT The Centers for Medicare & Medicaid Services (CMS) collects information from Medicare plans to track beneficiary
        enrollment in Medicare Advantage (MA) Plans, improve care, and for the payment of Medicare benefits. Sections 1851 of the Social Security Act
        and 42 CFR and 422.50 and 422.60 authorize the collection of this information. CMS may use, disclose and exchange
        enrollment data from Medicare beneficiaries as specified in the System of Records Notice (SORN) "Medicare Advantage
        Prescription Drug (MARx)", System No. 09-70-0588. Your response to this form is voluntary.
        However, failure to respond may affect enrollment in the plan.`}
      </p>
    </>
  );

  const PDPAgreements = (
    <>
      <p>
        I must keep Hospital (Part A) or Medical (Part B) to stay in {planName}
      </p>

      <p>
        By joining this Medicare Prescription Drug Plan, I acknowledge that {planName} will share my information with Medicare,
        who may use it to track my enrollment, to make payments, and for other purposes allowed by Federal law that authorize the collection
        of this information (see Privacy Act Statement below). My response to this form is voluntary. However, failure to respond may affect
        enrollment in the plan.
      </p>

      <p>
        I understand that I can be enrolled in only one Part D plan at a time – and that enrollment in this plan will automatically end
        my enrollment in another Part D plan.
      </p>

      <p>
        The information on this enrollment form is correct to the best of my knowledge. I understand that if I intentionally provide
        false information on this form, I will be disenrolled from the plan.
      </p>

      <p>
        I understand that my signature (or the signature of the person legally authorized to act on my behalf) on this application means
        that I have read and understand the contents of this application. If signed by an authorized representative (as described above),
        this signature certifies that: 1) This person is authorized under State law to complete this enrollment, and 2) Documentation of this
        authority is available upon request by Medicare.
      </p>

      <p>
        {`PRIVACY ACT STATEMENT The Centers for Medicare & Medicaid Services (CMS) collects information from Medicare plans to track beneficiary
        enrollment in Medicare Advantage (MA) or Prescription Drug Plans (PDP), improve care, and for the payment of Medicare benefits. Sections
        1851 of the Social Security Act and 42 CFR and 422.50 and 422.60 authorize the collection of this information. CMS may use, disclose and
        exchange enrollment data from Medicare beneficiaries as specified in the System of Records Notice (SORN) "Medicare Advantage
        Prescription Drug (MARx)", System No. 09-70-0588. Your response to this form is voluntary. However, failure to respond may affect
        enrollment in the plan.`}
      </p>
    </>
  );

  return (
    <div
      className="row gx-0 px-3 pt-3"
      style={{
        backgroundColor: 'var(--bs-gray-100)',
        maxHeight: '275px',
        overflowX: 'hidden',
        overflowY: 'scroll',
      }}
    >
      {planType === 'PDP' ? PDPAgreements : null}
      {planType === 'MA' && !isPFFS ? MAAgreements : null}
      {planType === 'MAPD' && !isPFFS ? MAPDAgreements : null}
      {(planType === 'MA' || planType === 'MAPD') && isPFFS ? PFFSAgreements : null}
    </div>
  );
}

export default AgreementsBox;
