import React from 'react';
import { useSelector } from 'react-redux';

import { toCurrency } from '../../../common/utils/prettify';

function PaymentBox ({}) {
  const { TotalPlanPremium } = useSelector(store => store.enrollment.plan.plan);

  return (
    <div className="row payment-box gx-0">
      <div className="d-flex col-12 justify-content-between">
        <span>Monthly Premium</span>
        <span>{toCurrency(TotalPlanPremium)}</span>
      </div>
      <hr className="mt-3" />
      <div className="d-flex justify-content-end align-items-end">
        <span className="fs-4 fw-bold me-4">Total monthly cost:</span>
        <span className="fw-bold">{toCurrency(TotalPlanPremium)}</span>
      </div>
    </div>
  );  
}

export default PaymentBox;
