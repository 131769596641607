import axios from 'axios';
import { fmtAgent } from '../common/utils/format';

export function getAgent(username) {
  const apiBaseUrl = process.env.REACT_APP_MMB_API_URL;
  return axios({
    method: 'get',
    url: `${apiBaseUrl}/api/shopper/agent/${username}/`,
  }).then(({ data }) => fmtAgent(data)); 
};
