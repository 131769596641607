import React from 'react';
import moment from 'moment';
import { toCurrency } from '../utils/prettify';

function TableRow({ label, value }) {
  return (
    <>
      <div className="col-md-4 mb-md-3">
        <b>{label}</b>
      </div>
      <div className="col-md-8 mb-md-3">
        {value}
      </div>
    </>
  );
}

function SummaryBox({
  planName,
  contractID,
  planType,
  planPremium,
  effectiveDate,
  firstName,
  middleName,
  lastName,
}) {
  const fullName = `${firstName || ''}${middleName ? (` ${middleName}`) : ''} ${lastName || ''}`;
  const planInfo = (
    <span>
      {planName},
      <br />
      {planType}, {contractID}
    </span>
  );
  const premium = toCurrency(planPremium);
  const ed = moment(effectiveDate).format('MMMM D, YYYY');

  return (
    <div className="row gx-0 px-3 pt-3" style={{ backgroundColor: 'var(--bs-gray-100)' }}>
      <TableRow
        label="Applicant name:"
        value={fullName}
      />
      <TableRow
        label="Plan:"
        value={planInfo}
      />
      <TableRow
        label="Monthly premium:"
        value={premium}
      />
      <TableRow
        label="Effective date:"
        value={ed}
      />
    </div>
  );
}

export default SummaryBox;
