import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatOEC } from '../../../../utils/enrollment-utils';
import { submitEnrollment, updateEnrollment } from '../../../../actions/enrollment';
import { LoaderButton } from '../../../../common/components';

function SubmitButton ({ disabled = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    evalues: fields,
    agent,
    theme,
    enrollment = {},
  } = useSelector(store => store);
  const {
    quote,
    confirmationNumber,
    plan,
    baseRoute,
    submitType,
  } = enrollment;
  const { carrier } = quote;

  const handleSubmit = async () => {
    setLoading(true);
    // clear any previous errors
    dispatch({ type: 'SET_ENROLLMENT_FIELD', payload: { errors: [] } });
    const { quoteID } = quote;
    const formatData = {
      agent,
      quote,
      fields,
      plan: plan.plan,
      theme,
      confirmationNumber,
    };

    const { formattedData, agentID } = formatOEC(carrier, formatData);

    try {
      // make sure the final enrollment fields get saved to enrollment.fields
      await updateEnrollment(enrollment, { ...fields, carrier_agent_id: agentID });
      const submittedEnrollment = await submitEnrollment(quoteID, submitType, formattedData);

      dispatch({
        type: 'SET_ENROLLMENT_FIELD',
        payload: {
          submittedFlag: true,
          submitDate: submittedEnrollment.submitDate,
        },
      });
      // set a submitted flag in the evalues object so that we can display
      // scripting based on this field
      dispatch({ type: 'SET_EVALUES_FIELD', name: 'submitted', value: true });
      // Notify quotes list window of enrollment submission to trigger refresh of quotes.
      // This message is listened for in components/EnrollmentLoaderButton
      try {
        window.opener.postMessage({ enrollmentSubmitted: true }, window.location.origin);
      } catch {
        console.warn('no window.opener, postMessage failed');
      }
      setLoading(false);
      navigate(`/enroll${baseRoute}/confirmation`);
    } catch (errors) {
      // { errors = [] }
      console.error('errors: ', errors);
      dispatch({ type: 'SET_ENROLLMENT_FIELD', payload: { errors } });
      setLoading(false);
    }
  };

  return (
    <LoaderButton
      text="Submit application"
      loadingText="Submitting..."
      loading={loading}
      className="btn btn-primary custom-nav-button mt-3"
      onClick={handleSubmit}
      disabled={disabled}
    />
  );
}

export default SubmitButton;
