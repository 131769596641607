import axios from 'axios';
import moment from 'moment-timezone';

import { fmtEnrollment } from '../utils/enroll';

// place in mmb-common-js along with a fmtObject/enrollment if we need this in any other project

// PlanDetails -> components/EnrollmentLoaderButton
export const createEnrollment = (quoteID, shopper, options = {}) => {
  options = {
    apiBaseUrl: process.env.REACT_APP_MMB_API_URL,
    raw: false,
    all: false,
    debug: false,
    ...options,
  };
  let apiEndpoint = '/api/shopper/enrollment/';
  const token = localStorage.getItem('SessionToken');

  // Prefill out known information for new enrollment
  const { state, zipcode, countyName } = shopper;
  return new Promise((resolve, reject) => {
    axios({
      url: `${options.apiBaseUrl}${apiEndpoint}`,
      method: 'post',
      headers: { Authorization: `Token ${token}` },
      data: {
        quote_id: quoteID,
        fields: {
          state,
          zipcode,
          county_name: countyName,
        },
      }
    }).then(res => {
      if (options.debug) {
        console.log('mmb-common-js/actions/enrollment/createEnrollment.then(res)');
        console.log(res);
      }
      if (options.raw) {
        resolve(res);
      } else {
        const enrollment = res.data ? fmtEnrollment(res.data, [], options) : null;
        resolve(enrollment);
      }
    }).catch(err => {
      if (options.debug) {
        console.log('mmb-common-js/actions/enrollment/createEnrollment.catch(err)');
        console.log(err);
      }
      reject(err);
    });
  });
}

export const updateEnrollment = (enrollment, evalues, options = {}) => {
  options = {
    apiBaseUrl: process.env.REACT_APP_MMB_API_URL,
    debug: false,
    ...options,
  };
  let apiEndpoint = '/api/shopper/enrollment/';
  const quoteID = enrollment.quoteID;
  const data = fmtEnrollment(enrollment, evalues, { post: true });
  if (options.submitted) {
    data.submitted_flag = true;
    data.submit_date = moment.tz(moment(), moment.tz.guess());
  }
  const token = localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    axios({
      url: `${options.apiBaseUrl}${apiEndpoint}${quoteID}/`,
      method: 'put',
      headers: { Authorization: `Token ${token}` },
      data,
    }).then(res => {
      if (options.debug) {
        console.log('mmb-common-js/actions/enrollment/updateEnrollment.then(res)');
        console.log(res);
      }
      resolve(res);
    }).catch(err => {
      if (options.debug) {
        console.log('mmb-common-js/actions/enrollment/updateEnrollment.catch(err)');
        console.log(err);
      }
      reject(err);
    });
  });
}



export const getEnrollment = (quoteID, options = {}) => {
  options = {
    apiBaseUrl: process.env.REACT_APP_MMB_API_URL,
    raw: false,
    all: false,
    debug: false,
    ...options,
  };
  let apiEndpoint = '/api/shopper/enrollment/';

  const token = localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    axios({
      url: `${options.apiBaseUrl}${apiEndpoint}${quoteID}/`,
      method: 'get',
      headers: { Authorization: `Token ${token}` },
    }).then(res => {
      if (options.debug) {
        console.log('mmb-common-js/actions/enrollment/getEnrollment.then(res)');
        console.log(res);
      }
      if (options.raw) {
        resolve(res);
      } else {
        const enrollment = res.data ? fmtEnrollment(res.data, [], options) : null;
        resolve(enrollment);
      }
    }).catch(err => {
      if (options.debug) {
        console.log('mmb-common-js/actions/enrollment/getEnrollment.catch(err)');
        console.log(err);
      }
      reject(err);
    });
  });
}

export function getEnrollmentConfig(carrier, year) {
  const token = localStorage.getItem('SessionToken');
  const formattedCarrierName = carrier.toLowerCase().replaceAll(' ', '-');
  const configName = `enrollment_form_consumer_${formattedCarrierName}_${year}_json`;
  return axios({
    method: 'get',
    headers: { Authorization: `Token ${token}` },
    url: `${process.env.REACT_APP_MMB_API_URL}/api/config/name/`,
    params: { name: configName, access: 'public', site: 'ALL_SITES_SITE' },
  }).then((res) => res.data);
}

export function getEnrollmentOptions(type, year) {
  const token = localStorage.getItem('SessionToken');
  let configName = '';
  if (year) {
    configName = `enrollment_form_${type}_${year}_json`;
  } else {
    configName = `enrollment_form_${type}_json`;
  }
  return axios({
    method: 'get',
    headers: { Authorization: `Token ${token}` },
    url: `${process.env.REACT_APP_MMB_API_URL}/api/config/name/`,
    params: { name: configName, access: 'public', site: 'ALL_SITES_SITE' },
  }).then((res) => res.data);
}

function SubmitException(errors, status) {
  this.message = 'There was an error submitting the enrollment';
  this.errors = errors;
  this.status = status;
}

export async function submitEnrollment(quoteID, submitType, submitData) {
  const token = localStorage.getItem('SessionToken');

  let errors = []; 

  // oec carriers
  if (submitType.toLowerCase() === 'oec') {
    try {
      const { data } = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_MMB_API_URL}/api/shopper/enroll/oec/`, // REMOVED FOR TESTING THE C
        data: submitData,
        headers: { Authorization: `Token ${token}` },
        params: { quote_id: quoteID },
      }); 

      return fmtEnrollment(data.enrollment);
    } catch (error) {
      console.log('error: ' , error);
      let status = 418;

      if (error.response) {
        ({ status = 418 } = error.response);
        errors = [ 
          `[ERROR ${error.response.status}]: Unable to submit enrollment.`,
        ];  
      }   

      throw new SubmitException(errors, status);
    }
  }

  // api carriers
  try {
    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/shopper/enroll/${submitType}/`,
      data: submitData,
      headers: { Authorization: `Token ${token}` },
      params: { quote_id: quoteID },
    }); 

    return fmtEnrollment(data.enrollment);
  } catch (error) {
    console.log('error: ' , error);
    let status = 418;

    if (error.response) {
      ({ status = 418 } = error.response);
      ({ errors = [] } = error.response.data);
    }   
    console.log('errors: ', errors);

    throw new SubmitException(errors, status);
  }
}
