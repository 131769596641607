import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// Components
import Topbar from '../../components/Topbar';
import CheckButton from '../../components/CheckButton';
import DisclaimerFooter from '../../components/DisclaimerFooter';

function RecQuestionnaire(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [ errs, setErrs ] = useState();
  const [ validMsg, setValidMsg ] = useState();
  const msToClearErrs = 1000; // user clears errs: red -> green -> error hides

  // current UX: values are cleared (only saved in redux for plan rec)
  const [ d, setDrugCount ] = useState(null);  // count string
  const [ e, setEnrolled ] = useState(null);   // true/false
  const [ h, setHelpPaying ] = useState(null); // true/false/'idk'

  const { pageIdx } = useSelector(store => store.rec);
  const handleFlow = (dir) => {
    if (dir === 'next') {
      // shopper has not entered values
      if (pageIdx === 0 && d === null) {
        setErrs('This field is required.');
      } else if (pageIdx === 1 && e === null) {
        setErrs('Please select an option to continue.');
      } else if (pageIdx === 2 && h === null) {
        setErrs('Please select an option to continue.');
      } else {
        // shopper has entered values
        dispatch({
          type: 'SET_REC_FIELD',
          payload: {
            prescriptionDrugCount: d,
            currentlyEnrolled: e,
            receivesHelpPaying: h,
          }
        });

        // end of flow
        if (pageIdx >= flow.length-1) {
          navigate('/recommended-plans');
          dispatch({ type: 'SET_REC_FIELD', payload: { completedRec: true, pageIdx: 0 }});
        } else {
          // clear out any errors if shopper is furiously clicking through
          setValidMsg();
          setTimeout(() => {
            dispatch({ type: 'SET_REC_FIELD', payload: { pageIdx: pageIdx + 1 }});
          }, 10);
        }
      }
    } else if (dir === 'back') {
      if (pageIdx === 0) {
        navigate('/');
      } else {
        // clear out any errors if shopper is furiously clicking through
        setValidMsg();
        setErrs();
        setTimeout(() => {
          dispatch({ type: 'SET_REC_FIELD', payload: { pageIdx: pageIdx - 1 }});
        }, 10);
      }
    }
  }
  const flow = [
    // #1 - Select how many prescription drugs
    (
      <div id="questionnaire-drug-count" className="d-flex flex-column align-items-center">
        <div className="text-center mb-3">
          In order to personalize your recommendation, we need a bit of information from you.
        </div>
        <div className="text-center mb-3">
          How much prescription medications do you currently take?
        </div>
        <select
          value={d}
          defaultValue=""
          className={`form-select mb-1 border border-secondary w-100 ${d === null ? 'text-muted' : ''}`}
          style={{ maxWidth: '300px' }}
          aria-label="Prescription drug count"
          onChange={(e) => {
            setDrugCount(e.target.value);
            // turn error red->green and then hide it
            if (errs) {
              setErrs();
              setValidMsg('This field is required.');
              setTimeout(() => { setValidMsg() }, 1000);
            }
          }}
        >
          <option value="" disabled>Select...</option>
          <option value="0">0</option>
          <option value="1-10">1-10</option>
          <option value="11-20">11-20</option>
          <option value="21+">21+</option>
        </select>       
      </div>
      ),


    // #2 - Is Shopper currently enrolled in medicare?
    (
      <div id="questionnaire-currently-enrolled" className="d-flex flex-column align-items-center w-100" style={{ maxWidth: '300px' }}>
        <div className="text-center mb-3">
          Are you currently enrolled in Medicare?
        </div>
        {/* todo(joeysapp): candidate for Select component */}
        <button
          className={`btn border-secondary mb-3 w-100 h-link position-relative ${e ? 'bg-primaryFocusHover' : 'bg-gray-100'}`}
          style={{ textAlign: 'left' }}
          aria-label="enrolled-in-medicare"
          onClick={() => {
            if (!e || e === null) {
              setEnrolled(true);
            } else {
              // allow shopper to deselect
              setEnrolled(null);
            }
            // turn errs red->green and then hide
            if (errs) {
              setErrs();
              setValidMsg('Please select an option to continue.');
              setTimeout(() => { setValidMsg() }, 1000);
            }
          }}
        >
          <CheckButton value={e === true} /><span className="ms-4">Yes</span>
        </button>
        <button
          className={`btn border-secondary w-100 h-link position-relative ${(!e && e !== null) ? 'bg-primaryFocusHover' : 'bg-gray-100'}`}
          style={{ textAlign: 'left' }}
          aria-label="enrolled-in-medicare"
          onClick={() => {
            if (e || e === null) {
              setEnrolled(false);
            } else if (!e && e !== null) {
              // allow shopper to deselect
              setEnrolled(null);
            }
            // turn errs red->green and then hide
            if (errs) {
              setErrs();
              setValidMsg('Please select an option to continue.');
              setTimeout(() => { setValidMsg() }, 1000);
            }
          }}
        >
          <CheckButton value={e === false} /><span className="ms-4" aria-label="no">No</span>
        </button>
      </div>
      ),


    // #3 - is Shopper receiving financial help?
    (
      <div id="questionnaire-receiving-help" className="d-flex flex-column align-items-center w-100" style={{ maxWidth: '300px' }}>
        <div className="text-center mb-3">
          Do you get help paying costs from Medicaid or any other Medicare program?
        </div>
        <button
          className={`btn border-secondary mb-3 w-100 h-link position-relative ${h === true ? 'bg-primaryFocusHover' : 'bg-gray-100'}`}
          style={{ textAlign: 'left' }}
          aria-label="help-paying-for-medicare"
          onClick={() => {
            if (h !== true) {
              setHelpPaying(true);
            } else {
              setHelpPaying(null);
            }
            // turn error red->green and then hide it
            if (errs) {
              setErrs();
              setValidMsg('Please select an option to continue.');
              setTimeout(() => { setValidMsg() }, msToClearErrs);
            }
          }}
        >
          <CheckButton value={h === true} /><span className="ms-4" aria-label="yes">Yes</span>
        </button>
        <button
          className={`btn border-secondary w-100 mb-3 h-link position-relative ${(!h && h !== null) ? 'bg-primaryFocusHover' : 'bg-gray-100'}`}
          style={{ textAlign: 'left' }}
          aria-label="no-help-paying-for-medicare"
          onClick={() => {
            if (h !== false) {
              setHelpPaying(false);
            } else if (!h && h !== null) {
              setHelpPaying(null);
            }
            // turn error red->green and then hide it
            if (errs) {
              setErrs();
              setValidMsg('Please select an option to continue.');
              setTimeout(() => { setValidMsg() }, msToClearErrs);
            }
          }}
        >
          <CheckButton value={h === false} />         
          <span className="ms-4">No</span>
        </button>
        <button
          className={`btn border-secondary w-100 h-link position-relative ${h === 'idk' ? 'bg-primaryFocusHover' : 'bg-gray-100'}`}
          style={{ textAlign: 'left' }}
          aria-label="do-not-know-if-help-paying-for-medicare"
          onClick={() => {
            if (h !== 'idk') {
              setHelpPaying('idk');
            } else {
              setHelpPaying(null);
            }
            // turn error red->green and then hide it
            if (errs) {
              setErrs();
              setValidMsg('Please select an option to continue.');
              setTimeout(() => { setValidMsg() }, msToClearErrs);
            }
          }}
        >
          <CheckButton value={h === 'idk'} /><span className="ms-4" aria-label="i do not know">I don't know</span>
        </button>
      </div>
    ),
  ];

  return (
    <>
      <Topbar showAgent />
      <div className='mmb-container show-agent'>
        <div className='d-flex flex-column w-100 h-100 align-items-center'>
          <div className="text-serif fw-bold fs-1 m-3 mb-0 text-center">
            Your Medicare Recommendation
          </div>
          <div className="d-flex flex-column p-4 align-items-center w-100" style={{ maxWidth: '600px' }}>
            {flow[pageIdx]}
            {/* errors below shopper input */}
            <div className={`d-flex fs-6 w-100 mt-1 s-text s-text-4 ${errs || validMsg ? 'show' : ''}`} style={{ maxWidth: '300px'}}>
              <div className={`d-flex align-items-center w-auto ${true ? 'text-danger' : 'text-success'}`}>
                {
                  <div className={`d-flex align-items-center w-auto ${errs ? 'text-danger' : 'text-success'}`}>
                    {errs
                      ? <FontAwesomeIcon className='fs-5 me-1' style={{ minWidth: '18px' }} icon={solid('times')}/>
                      : <FontAwesomeIcon className='fs-5 me-1' style={{ minWidth: '18px' }} icon={solid('check')}/>
                    }
                    {errs}
                    {validMsg}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="w-100 d-flex flex-column" style={{ maxWidth: '300px' }}>
            <button
              className="btn btn-primary fw-bold mb-3"
              onClick={(e) => handleFlow('next')}
              title="Next"
            >
              Next
            </button>
            <button
              className="btn h-link text-secondary fw-bold mb-3"
              onClick={(e) => handleFlow('back')}
              title="Back"
            >
              Back
            </button>
          </div>
          <DisclaimerFooter />
        </div>
      </div>
    </>
  );
}

export default RecQuestionnaire;
