const initialState = {
  form: [],
  plan: {},
  sep: [],
  race: [],
  ethnicity: [],
  validation: {},
  labels: {},
  enrollment: {},
  errors: [],
  touchedSubmitPage: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOAD_ENROLLMENT': {
      return {
        ...state,
        ...action.payload,
        fields: undefined, // unset fields because we don't need this data
      };
    }
    case 'LOAD_ENROLLMENT_FORM': {
      return {
        ...state,
        ...action.form,
        plan: action.plan,
        quote: action.quote,
        sep: action.sep,
        race: action.race,
        ethnicity: action.ethnicity,
        validation: action.validation,
        labels: action.labels,
        errors: [],
      };
    }
    case 'SET_ENROLLMENT_FIELD':
      return { ...state, ...action.payload };
    case 'CLEAR_ENROLLMENT_FORM':
      return { ...initialState };
    default:
      return state;
  }
}
