import moment from 'moment';
import { useSelector } from 'react-redux';

import { TPMODisclaimer } from '../common/components';

function DisclaimerFooter({
  style,
  className,
  id,
  customMaterialID = '',
  customLastUpdate,
  showTPMODisclaimer = true, // Third Party Marketing Organization Disclaimer
}) {

  const { disclaimerLastUpdated } = useSelector(store => store.theme);
  const { cmsID, siteAgency } = useSelector(store => store.theme);

  const { effectiveDate, county: countyFips } = useSelector(store => store.shopper);
  const { carrierPermissions = [] } = useSelector(store => store.agent);
  const planYear = moment(effectiveDate).year();

  return (
    <div
      className={`d-flex flex-column text-muted fs-6 ${className}`}
      style={{
        alignItems: 'center',
        textAlign: 'center',
        ...style,
      }}
    >
      <div>Last update: {customLastUpdate || disclaimerLastUpdated}</div>
      <div>{customMaterialID || cmsID}</div>
      <a
        href="/disclaimers"
        target="_blank"
        tabIndex={0}
      >
        <span className="fw-bold text-decoration-underline text-link pt-1 pb-1">Medicare disclaimers</span>
      </a>
      {showTPMODisclaimer && (
        <div className="mt-4" style={{ maxWidth: '600px' }}>
          <TPMODisclaimer
            carrierPermissions={carrierPermissions}
            planYear={planYear}
            countyFips={countyFips}
            selfAttest={false}
          />
        </div>
      )}
      <div className="mt-3" style={{ maxWidth: '600px' }}>
        <span className="text-capitalize">{siteAgency} </span> 
        represents Medicare Advantage (HMO, PPO, PFFS) and Prescription Drug Plans 
        organizations that have a Medicare contract. Enrollment depends on the 
        plan's contract renewal.
      </div>
    </div>
  );
}

export default DisclaimerFooter;
