import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Actions
import { isShopperRegistered } from '../../actions/shopper';
import { checkIfLoggedIn } from '../../actions/session';

function LinkRouting({ flow }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Shopper will receive URL in SMS/email with the shape:
  // https://...../quote
  //   ?a={agent.username}
  //   &q={quote.quoteID}
  //   &s={shopper.username}
  //   &tid={traceID}
  const [ searchParams ] = useSearchParams();

  useEffect(() => {
    const shopperUsername = searchParams.get('s');
    const quoteID = searchParams.get('q');
    const agentUsername = searchParams.get('a');
    const shopperCounty = searchParams.get('c');

    dispatch({ type: 'CLEAR_SHOPPER' });
    dispatch({ type: 'CLEAR_ESIGN' });
    dispatch({ type: 'SET_SHOPPER_FIELD', payload: {
      username: shopperUsername,
      quoteID,
      county: shopperCounty,
    } });
    dispatch({ type: 'SET_AGENT_FIELD', payload: { username: agentUsername } });

    // setTimeout used so axios' site param is set in time
    setTimeout(() => {
      isShopperRegistered(shopperUsername)
        .then(({ registered }) => {
          if (registered) {
            checkIfLoggedIn()
              .then(() => {
                if (flow === 'quote') navigate('/quotes');
                if (flow === 'esign') navigate(`/esign/start?quoteID=${quoteID}`);
              }).catch(() => {
                // clear any remaining previous session info
                localStorage.removeItem('SessionToken');
                dispatch({ type: 'SET_TOKEN_LAST_UPDATE', payload: null });
                if (flow === 'quote') navigate('/login');
                if (flow === 'esign') navigate(`/login?redirect=/esign/start?quoteID=${quoteID}`);
              });
          } else {
            localStorage.removeItem('SessionToken');
            dispatch({ type: 'SET_TOKEN_LAST_UPDATE', payload: null });
            if (flow === 'quote') navigate('/verify/quote');
            if (flow === 'esign') navigate('/verify/esign');
          }
        }).catch((err) => {
          // navigate('/error');
        });
    }, 0);
  }, []);

  return null;
}

export default LinkRouting;
