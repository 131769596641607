/* eslint-disable quote-props */
import * as moment from 'moment';
import {
  yesNo,
  yn,
  yesNoBlank,
  existsOrBlank,
} from './mapping-tools';
import { stripPhone, cleanDate } from './formatting';
import getAgentID from './getAgentID';

export default ({
  agent,
  quote,
  fields: res,
  plan,
  theme,
  confirmationNumber,
}) => {
  const { useCarrierPerms } = theme;

  let agentID;
  let agentNPN;
  if (useCarrierPerms) {
    const ids = getAgentID({ agent, quote });
    agentID = ids.agentID;
    agentNPN = ids.agentNPN;
  } else {
    agentNPN = agent.npn;
  }

  const pcpName = res.pcp_name || '';
  const pcpNameSplit = pcpName.trim().replaceAll('  ', ' ').split(' ');
  const pcpFname = pcpNameSplit.shift();
  const pcpLname = pcpNameSplit.join(' ');
  const isSEP = res.enrolltype === 'SEP';
  const premiumDeducted = yesNo(['rrb', 'ssi'].includes(res.paymeth));
  const premiumDirectPay = yesNo(!['rrb', 'ssi'].includes(res.paymeth));
  const premiumWithhold = ['rrb', 'ssi'].includes(res.paymeth) ? res.paymeth.toUpperCase() : '';
  const planYear = plan.PlanYear;
  const isEFT = res.paymeth === 'eft';
  const isCouponBook = ((res.paymeth === 'direct') || !res.paymeth); // if paymeth not populated default to coupon book
  const benefitStart = moment(res.effective_date).format('MMDDYYYY');
  const ltcfAddress = res.ltcf
    ? `${res.ltcf_addr_street1}${res.ltcf_addr_street2 ? ` ${res.ltcf_addr_street2}` : ''}, ${res.ltcf_addr_city}, ${res.ltcf_addr_state} ${res.ltcf_addr_zip}`
    : '';

  let race = '';
  let ethnicity = '';

  if (Array.isArray(res.race) && res.race.length) {
    race = res.race.join(',');
  }
  if (Array.isArray(res.ethnicity) && res.ethnicity.length) {
    ethnicity = res.ethnicity.join(',');
  }

  const formattedData = [
    { col: 'ConfirmationNumber', val: confirmationNumber },
    { col: 'SubmitDate', val: moment().format('MMDDYYYY') },
    { col: 'ContractID', val: plan.ExternalContractID },
    { col: 'PlanID', val: plan.ExternalPlanID },
    { col: 'SegmentID', val: plan.ExternalSegmentID },
    { col: 'ApplicantTitle', val: '' },
    { col: 'ApplicantFirstName', val: res.first_name },
    { col: 'ApplicantMiddleInitial', val: res.middle_name },
    { col: 'ApplicantLastName', val: res.last_name },
    { col: 'ApplicantBirthDate', val: moment(res.birthdate).format('MMDDYYYY') },
    { col: 'ApplicantGender', val: (res.gender === 'male') ? 'M' : 'F' },
    { col: 'ApplicantAddress1', val: res.street1 },
    { col: 'ApplicantAddress2', val: res.street2 },
    { col: 'ApplicantAddress3', val: '' },
    { col: 'ApplicantCity', val: (res.city || '').slice(0, 30) },
    { col: 'ApplicantCounty', val: res.county_name },
    { col: 'ApplicantState', val: res.state.toUpperCase() },
    { col: 'ApplicantZip', val: res.zipcode },
    { col: 'ApplicantEmailAddress', val: res.email },
    { col: 'ApplicantMBI', val: res.medicare_id },
    { col: 'ApplicantSSN', val: '' }, // SSN of applicant for SNP DE (PA ONLY)
    { col: 'MailingAddress1', val: res.samemail ? res.street1 : res.mailstreet1 },
    { col: 'MailingAddress2', val: res.samemail ? res.street2 : res.mailstreet2 },
    { col: 'MailingAddress3', val: '' },
    { col: 'MailingCity', val: res.samemail ? res.city : res.mailcity },
    { col: 'MailingState', val: res.samemail ? res.state : res.mailstate },
    { col: 'MailingZip', val: res.samemail ? res.zipcode : res.mailzip },
    { col: 'MedicarePartA', val: cleanDate(res.part_a_effective_date) },
    { col: 'MedicarePartB', val: cleanDate(res.part_b_effective_date) },
    { col: 'EmergencyContact', val: res.hasemer ? `${res.emerfname} ${res.emerlname}` : '' },
    { col: 'EmergencyPhone', val: res.hasemer ? stripPhone(res.emertel) : '' },
    { col: 'EmergencyRelationship', val: res.hasemer ? res.emerrel : '' },
    { col: 'PremiumDeducted', val: premiumDeducted },
    { col: 'PremiumSource', val: '' },
    { col: 'OtherCoverage', val: yn(res.othercoverage) }, // applies to PDP, MAPD, SNP DE, PFFS-PD, CP-PD
    { col: 'OtherCoverageName', val: res.othercoverage ? res.othercoverage_name : '' },
    { col: 'OtherCoverageID', val: res.othercoverage ? res.othercoverage_num : '' },
    { col: 'LongTerm', val: yn(res.ltcf) },
    { col: 'LongTermName', val: res.ltcf ? res.ltcf_name : '' },
    { col: 'LongTermAddress', val: ltcfAddress },
    { col: 'LongTermPhone', val: res.ltcf ? stripPhone(res.ltcf_tel) : '' },
    { col: 'AuthorizedRepName', val: res.ispoa ? `${res.poa_fname} ${res.poa_lname}` : '' },
    { col: 'AuthorizedRepAddress', val: res.ispoa ? `${res.poa_addr_street1} ${res.poa_addr_street2 || ''}`.trim() : '' },
    { col: 'AuthorizedRepCity', val: res.ispoa ? res.poa_addr_city : '' },
    { col: 'AuthorizedRepState', val: res.ispoa ? res.poa_addr_state : '' },
    { col: 'AuthorizedRepZip', val: res.ispoa ? res.poa_addr_zip : '' },
    { col: 'AuthorizedRepPhone', val: res.ispoa ? res.poa_tel : '' },
    { col: 'AuthorizedRepRelationship', val: res.ispoa ? res.poa_rel : '' },
    { col: 'Language', val: res.preflang ? res.preflang : '' },
    { col: 'WorkStatus', val: yn(res.willwork) },
    { col: 'SpouseWorkStatus', val: yn(res.willworkspouse) },
    { col: 'PCPFirstName', val: pcpFname },
    { col: 'PCPLastName', val: pcpLname },
    { col: 'PCPProviderID', val: res.pcp_num || '' },
    { col: 'OtherCoverageGroup', val: res.othercoverage_group },
    { col: 'AgentID', val: agentID },
    { col: 'SubmitTime', val: moment().format() },
    { col: 'PartDSubAppInd', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'DeemedInd', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'SubsidyPercentage', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'DeemedReasonCode', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'LISCopayLevelID', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'DeemedCopayLevelID', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'PartDOptOutSwitch', val: '' }, // ignoring because opt (and we don't collect subsidy info)
    { col: 'SEPReasonCode', val: isSEP ? res.sep_code : '' },
    { col: 'SEPDate', val: isSEP ? res.sep_date : '' },
    { col: 'PremiumDirectPay', val: premiumDirectPay },
    { col: 'EnrollmentPlanYear', val: planYear },
    { col: 'PremiumWithhold', val: premiumWithhold },
    { col: 'Blank3', val: '' },
    { col: 'Blank4', val: '' },
    { col: 'Blank5', val: '' },
    { col: 'Blank6', val: '' },
    { col: 'Blank7', val: '' },
    { col: 'PremiumDeductEFT', val: yesNo(res.paymeth === 'eft') },
    { col: 'PremiumDeductCreditCard', val: yesNo(res.paymeth === 'cc') },
    { col: 'PremiumDeductSS', val: yesNo(res.paymeth === 'ssi') },
    { col: 'PremiumDeductBill', val: yesNo(isCouponBook) },
    { col: 'AgentFirstName', val: agent.firstName },
    { col: 'AgentLastName', val: agent.lastName },
    { col: 'AgentNationalID', val: agentNPN },
    { col: 'AgentStateLicenseNumber', val: '' }, // we don't collect/store state license info on agents
    { col: 'AgentIDHealthPlan', val: '' }, // N/A according to cigna doc
    { col: 'AgencyName', val: '' }, // we don't collect/store agency name on Amerilife or Humana agents
    { col: 'AgencyID', val: '' }, // we don't collect/store agency name on Amerilife or Humana agents
    { col: 'SourceID', val: 'mymedicarebot' },
    { col: 'OEC_InsertedWhen', val: '' }, // not applicable?
    { col: 'MedicalGroupID', val: '' }, // not applicable?
    { col: 'AccountName', val: isEFT ? res.eft_actname : '' },
    { col: 'BankRoutingNum', val: isEFT ? res.eft_routing : '' },
    { col: 'BankAccountNum', val: isEFT ? res.eft_actnum : '' },
    { col: 'AccountType', val: isEFT ? res.eft_acttype : '' },

    // NOTE: THE FOLLOWING FIELDS CONTAIN THE DATA FOR
    //   CUSTOM QUESTIONS. CUSTOM QUESTIONS WILL ALWAYS
    //   BE AT THE END OF THE ENROLLMENT APPLICATION RECORD.

    // options for EnrollmentType
    //   TEL - telephonic / agent
    //   ONL - online / unassisted
    //   VIR - virtual / agent
    //   FTF - face to face / agent
    { col: 'EnrollmentType', val: 'TEL' },
    { col: 'BenefitStart', val: benefitStart },
    { col: 'Blank', val: '' },
    { col: 'DentalCoverage', val: '' }, // opt
    { col: 'DiabetesDiagnosis', val: yesNoBlank(res.snp_diabetes) }, // opt
    { col: 'DiabetesMedication', val: existsOrBlank(res.snp_diabetes_medicines) }, // opt
    { col: 'DiabetesProviderName', val: existsOrBlank(res.snp_physician_name) }, // opt
    { col: 'DiabetesProviderAddress', val: existsOrBlank(res.snp_physician_street1) }, // opt
    { col: 'DiabetesProviderPhone', val: existsOrBlank(res.snp_physician_tel) }, // opt
    { col: 'DiabetesProviderFax', val: existsOrBlank(res.snp_physician_fax) }, // opt
    { col: 'Question14', val: '' }, // ??
    { col: 'Question15', val: '' }, // ??
    { col: 'AccessibilityFormat', val: res.altwritten },
    { col: 'EmailOptIn', val: yn(res.okemail) },
    { col: 'EmailTips', val: '' }, // don't collect this info
    { col: 'Notes', val: isSEP ? res.sep_other : '' },
    { col: 'ApplicantPhone', val: stripPhone(res.home_phone) },
    { col: 'ApplicantPhoneType', val: 'H' }, // opts are H = Home or C = Cell. Our form verbiage is for home only.
    { col: 'ApplicantAltPhoneType', val: 'H' }, // opts are H = Home or C = Cell. Our form verbiage is for home only.
    { col: 'ApplicantAltPhone', val: '' }, // don't collect this info
    { col: 'StateMedicaid', val: res.onmedicaid ? res.medicaidnum : 'No' },
    { col: 'MedicaidAccessNbr', val: '' }, // skipping because opt and only for PA
    { col: 'MedicaidCaseNumber', val: '' }, // skipping because opt and only for TX
    { col: 'ExistingPatient', val: res.pcp_name ? yn(res.pcp_established) : '' },
    { col: 'ChronicCondition', val: '' }, // don't collect
    { col: 'Race', val: race },
    { col: 'Ethnicity', val: ethnicity },
  ];

  return { formattedData, agentID };
};
