import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import axios from 'axios';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WithGA from './hocs/WithGA';
import { store } from './store';

// Actions
import { validateTheme } from './actions/theme';
import { validateClientVersion, validateSession } from './actions/session';

// Utils
import getGACode from './utils/tracking';
import configureAxios from './utils/configureAxios';

import { ContactAgentModal, SessionRefreshModal, StaleClientModal } from './components';

// Views
// Workflow 1
import LinkRouting from         './views/landing/LinkRouting';
import VerifyIdentity from      './views/landing/VerifyIdentity';
import EsignStart from          './views/landing/EsignStart';
// Workflow 2
import RecLanding from          './views/recommendation/RecLanding';
import RecQuestionnaire from    './views/recommendation/RecQuestionnaire';
import RecPlans from            './views/recommendation/RecPlans';
// Base
import Login from               './views/landing/Login';
import CreateAccount from       './views/landing/CreateAccount';
import QuoteList from           './views/quote/';
import PlanComparison from      './views/plans/compare/';
import PlanDetails from         './views/plans/details/';
import PlanList from            './views/plans/list/PlanList';
// Contact
import ContactFlow from         './views/contact/ContactFlow';
import OptOutLink from          './views/contact/OptOutLink';
import OptOut from              './views/contact/OptOut';
import DisclaimerPage from      './views/disclaimers/';

// Enrollment
import Enrollment from          './views/enrollment';

import Features from            './views/features';

import './App.scss';
function App(props) {
  const dispatch = useDispatch();

  const [ versionInt, setVersionInt ] = useState();
  const [ themeInt, setThemeInt ] = useState();

  useEffect(() => {
    getGACode().then(code => {
      ReactGA.initialize(code);
      ReactGA.send('pageview');
    });

    if (!window.location.pathname.includes('error_')) {
      // Ensure user's local instance of the app has the same build marker
      // as deployed build, if it doesn't match, ask them to refresh.
      validateClientVersion();
      const versionMs = 10000;
      setVersionInt(setInterval(() => {
        validateClientVersion();
      }, versionMs));

      // Make sure we have the newest theme
      const themeMs = 10000;
      validateTheme();
      setThemeInt(setInterval(() => {
        validateTheme();
      }, themeMs));

      // Session logic is handled in components/SessionRefreshModal
    }

    // redirect users if they are not using a valid entry point to the app
    const token = localStorage.getItem('SessionToken');
    const entryPoints = [
      '/verify',
      '/verify/quote',
      '/verify/esign',
      '/login',
      '/signup',
      '/quote',
      '/esign',
      '/features',
      '/unsubscribe',
      '/unsubscribe/confirm',
      '/disclaimers',
    ];
    const currentLocation = window.location.pathname;
    const currentQS = window.location.search;
    if (!token && entryPoints.indexOf(currentLocation) === -1) {
      // User's previous session info was cleared but they're trying to nav back in
      window.location = `/login?redirect=${currentLocation}${currentQS}`;
    } else if (token) {
      validateSession()
        .then(() => {
          // Token is OK
        }).catch(() => {
          dispatch({ type: 'CLEAR_SESSION' });
          localStorage.removeItem('SessionToken');
          // We should only redirect if the user is trying to access a non entry point page.
          // This is an edge case that is likely to be encountered by people testing the app,
          // or maybe on a shared computer (like at a library or school).
          if (entryPoints.indexOf(currentLocation) === -1) {
            setTimeout(() => {
              window.location = '/login';
            }, 0);
          }
        });
    }

    return function() {
      clearInterval(versionInt);
      clearInterval(themeInt);
    };
  }, []);

  // note(joeysapp): Think about the BrowserRouter and mobile use
  // https://blog.logrocket.com/routes-animation-transitions-in-react-router-v4-9f4788deb964/
  return (
    <BrowserRouter>
      <WithGA>
        <Routes>
          {/* Auth */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<CreateAccount />} />

          {/*
             Workflow #1 - Agent to Shopper flow via SMS/email (quotes, esign...)
          */}
          <Route path="/quote" element={<LinkRouting flow="quote" />} />
          <Route path="/esign" element={<LinkRouting flow="esign" />} />
          <Route path="/esign/start" element={<EsignStart />} />
          <Route path="/" element={<VerifyIdentity />} />
          <Route path="/verify/quote" element={<VerifyIdentity flow="quote" />} />
          <Route path="/verify/esign" element={<VerifyIdentity flow="esign" />} />
          {/* this is here to support old links, should be removed in future. Added above more granular routing on 07/19/23 */}
          <Route path="/verify" element={<VerifyIdentity flow="quote" />} />
          <Route path="/quotes" element={<QuoteList />} />

          {/*
             Workflow #2 - Shopper flow via /
             / -> /questionnaire -> /recommend-plans
          */}
          <Route path="/recommend" element={<RecLanding />} />
          <Route path="/questionnaire" element={<RecQuestionnaire />} />
          <Route path="/recommended-plans" element={<RecPlans />} />

          {/* Viewable after signup/login */}
          <Route path="/compare" element={<PlanComparison />} />
          <Route path="/plan" element={<PlanDetails />} />
          <Route path="/plans" element={<PlanList />} />

          {/* <Route path="/plans/saved" element={<PlanList saved />} /> */}
          <Route path="/contact" element={<ContactFlow />} />
          <Route path="/unsubscribe" element={<OptOutLink />} />
          <Route path="/unsubscribe/confirm" element={<OptOut />} />
          <Route path="/disclaimers" element={<DisclaimerPage />} />

          <Route path="/enroll/*" element={<Enrollment />} />

          <Route path="/features" element={<Features />} />
        </Routes>

        <SessionRefreshModal />
        <ContactAgentModal />
        <StaleClientModal />
      </WithGA>
    </BrowserRouter>
  );
}

export default App;
