import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { Checkbox, Radio, TextInput } from '../../components/inputs';
import { SummaryBox, AgreementsBox } from '../../../../common/components';

import '../../styles.scss';

function Submit ({ pageTitle }) {
  const dispatch = useDispatch();
  const { ispoa, first_name, middle_name, last_name } = useSelector(store => store.evalues);
  const {
    carrierName,
    plan = {},
    quote: { effectiveDate },
  } = useSelector(store => store.enrollment);
  const {
    planName,
    planType,
    cpsID,
    plan:{ TotalPlanPremium },
  } = plan;

  useEffect(() => {
    dispatch({ type: 'SET_ENROLLMENT_FIELD', payload: { touchedSubmitPage: true } });
  }, []);

  function unsetPOAFields() {
    dispatch({
      type: 'SET_EVALUES_FIELDS',
      fields: {
        poa_fname: undefined,
        poa_lname: undefined,
        poa_rel: undefined,
        poa_addr_street1: undefined,
        poa_addr_street2: undefined,
        poa_addr_city: undefined,
        poa_addr_state: undefined,
        poa_addr_zip: undefined,
        poa_tel: undefined,
      },
    });
    dispatch({
      type: 'SET_FIELD_VALIDATIONS',
      fields: {
        poa_fname: undefined,
        poa_lname: undefined,
        poa_rel: undefined,
        poa_addr_street1: undefined,
        poa_addr_street2: undefined,
        poa_addr_city: undefined,
        poa_addr_state: undefined,
        poa_addr_zip: undefined,
        poa_tel: undefined,
      },
    });
  }

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <FormRow>
          You’re almost finished with your application for coverage. Please review the information you provided and your responses to the questions in the application before you apply your electronic signature below.
        </FormRow>
        <FormRow>
          <SummaryBox
            planName={planName}
            contractID={cpsID}
            planType={planType}
            planPremium={TotalPlanPremium}
            effectiveDate={effectiveDate}
            firstName={first_name}
            middleName={middle_name}
            lastName={last_name}
          />
        </FormRow>

        <TitleRow>Relationship to applicant</TitleRow>
        <FormRow>
          <Radio
            label="Select the statement that best describes your relationship to the applicant listed on this enrollment application."
            name="ispoa"
            options={[
              { value: false, label: 'I am completing my enrollment form on my own.' },
              { value: true, label: 'I am the authorized representative to act on behalf of the individual listed on this enrollment application.' },
            ]}
            onChangeCallback={unsetPOAFields}
          >
            {ispoa && (
              <>
                <FormRow>
                  <TextInput
                    autoFocus
                    label="First name"
                    name="poa_fname"
                  />
                </FormRow>
                <FormRow>
                  <TextInput
                    label="Last name"
                    name="poa_lname"
                  />
                </FormRow>
                <FormRow>
                  <TextInput
                    label="Relationship to applicant"
                    name="poa_rel"
                  />
                </FormRow>
                <FormRow>
                  <TextInput
                    label="Street"
                    name="poa_addr_street1"
                  />
                </FormRow>
                <FormRow>
                  <TextInput
                    label="Street Line 2"
                    name="poa_addr_street2"
                  />
                </FormRow>
                <FormRow>
                  <TextInput
                    label="City"
                    name="poa_addr_city"
                  />
                </FormRow>
                <div className="row">
                  <FormRow className="col-md-3">
                    <TextInput
                      label="State"
                      name="poa_addr_state"
                      small
                    />
                  </FormRow>
                  <FormRow className="col-md-3">
                    <TextInput
                      label="ZIP code"
                      name="poa_addr_zip"
                      small
                    />
                  </FormRow>
                </div>
                <FormRow>
                  <TextInput
                    label="Phone number (only numbers, no spaces)"
                    name="poa_tel"
                  />
                </FormRow>
              </>
            )}
          </Radio>
        </FormRow>
        <TitleRow>Agreements</TitleRow>
        <FormRow>
          You must read the disclosures below and check the box to confirm you have done so.
        </FormRow>
        <FormRow>
          <AgreementsBox plan={plan} />
        </FormRow>
        <FormRow>
          I have read and understand the important information on the preceding pages. I have 
          reviewed and received a copy of the Summary of Benefits.
        </FormRow>
        <FormRow>
          <Checkbox
            label="I acknowledge that I have read the disclosures and confirm that all information in this enrollment application is accurate."
            name="acknowledge"
          />
          <div className="col mt-2">
            Today's date: {moment().format('MM/DD/YYYY')}
          </div>
        </FormRow>
        <FormRow>
          <TextInput
            label="E-Signature"
            name="esign_signature"
          />
        </FormRow>
      </div>
    </div>
  );  
}

export default Submit;
