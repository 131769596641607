import React from 'react';
import { useSelector } from 'react-redux';

import {
  handleChange,
  handleValidation,
  getValidClass,
  isRequired,
  getFieldKey,
  fieldExistsInConfig,
} from '../../../../utils/enroll';

import FormLabel from './FormLabel';

function DatePicker ({
  clone,
  disabled,
  label,
  name,
}) {

  const form = useSelector(store => store.enrollment.form);
  const evalues = useSelector(store => store.evalues);
  const value = evalues[name];

  const required = isRequired({ form, name, currentValues: evalues });
  const validationRegex = useSelector(store => store.enrollment.validation[name]);
  const valid = useSelector(store => store.evalid[name]);
  const validClass = getValidClass({ valid, required, value });

  const { submittedFlag } = useSelector(store => store.enrollment);
  const isDisabled = (disabled || clone || submittedFlag);

  const customLabel = getFieldKey(form, name, 'label');

  // if field doesn't exist, don't show it.
  if (!fieldExistsInConfig(form, name)) return null;

  return (
    <>
      <FormLabel name={name} label={customLabel || label} required={required} />
      <div className="col-md-6">
        <input
          className={['form-control', (!isDisabled ? validClass : '')].join(' ')}
          type="date"
          id={name}
          onChange={(e) => {
            handleChange({ value: e.target.value, name, valid, validationRegex, required });
          }}
          value={evalues[clone || name] || ''}
          disabled={isDisabled}
          onBlur={(e) => {
            if (!e.target.value) e.target.value = '000-00-00';
            handleValidation({ value, validationRegex, name, required });
          }}
        />
      </div>
    </>
  );  
}

export default DatePicker;
