import React, { useState, useEffect } from 'react';
import { getAgentCountyPlanCount } from '../actions/agent';

function getActiveCarrierIDs({ carrierPermissions = [], planYear }) {
  return carrierPermissions.filter((perm) => {
    if (perm.active && (String(perm.plan_year) === String(planYear))) return true;
    return false;
  }).map((perm) => perm.carrier_id);
}

export function TPMODisclaimerComponent({
  carrierPermissions,
  countyFips,
  planYear,
  selfAttest = false,
}) {
  const selfAttestOrgText = '[AGENT: PLEASE SELF ATTEST #]';
  const selfAttestPlanText = '[AGENT: PLEASE SELF ATTEST #]';
  const [ orgCount, setOrgCount ] = useState();
  const [ planCount, setPlanCount ] = useState();

  useEffect(() => {
    if (!selfAttest) {
      const activeCarrierIDs = getActiveCarrierIDs({ carrierPermissions, planYear });
      setOrgCount(activeCarrierIDs.length);
      getAgentCountyPlanCount({ carrierIDs: activeCarrierIDs, countyFips, planYear })
        .then((data) => setPlanCount(data));
    } else {
      setOrgCount(selfAttestOrgText);
      setPlanCount(selfAttestPlanText);
    }
  }, [selfAttest, countyFips, planYear]);

  const formattedOrgCount = selfAttest ? (<b className="text-danger">{orgCount}</b>) : orgCount;
  const formattedPlanCount = selfAttest ? (<b className="text-danger">{planCount}</b>) : planCount;

  return (
    <span>
      We do not offer every plan available in your area. Currently we represent {formattedOrgCount}&nbsp;
      organizations which offer {formattedPlanCount}  products in your area. Please contact Medicare.gov,
      1-800-MEDICARE, or your local State Health Insurance Program (SHIP) to get information
      on all of your options.
    </span>
  );
}

export function TPMOExplanationComponent({ className }) {
  return (
    <span className={className}>
      Note to Agent: The Organization and Plan counts are calculated by your available carrier
      enrollment permissions and the plans available in your client’s zip code. This amount
      will be recalucated upon updating the zip code on the page.
    </span>
  );
}

export async function getTPMODisclaimerText({
  carrierPermissions,
  countyFips,
  planYear,
  selfAttest = false,
}) {
  let orgCount = '[AGENT: PLEASE SELF ATTEST #]';
  let planCount = '[AGENT: PLEASE SELF ATTEST #]';

  if (!selfAttest) {
    const activeCarrierIDs = getActiveCarrierIDs({ carrierPermissions, planYear });
    orgCount = activeCarrierIDs.length;
    planCount = await getAgentCountyPlanCount({
      carrierIDs: activeCarrierIDs,
      countyFips,
      planYear,
    });
  }

  // eslint-disable-next-line
  return `We do not offer every plan available in your area. Currently we represent ${orgCount} organizations which offer ${planCount}  products in your area. Please contact Medicare.gov, 1-800-MEDICARE, or your local State Health Insurance Program (SHIP) to get information on all of your options.`;
}
