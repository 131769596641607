const initialState = {
  resumeEnrollmentEmail: false,
  formSpecificAlert: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_ALERT': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'CLEAR_ALERTS':
      return { ...initialState };
    default:
      return state;
  }
}
