import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import {
  DatePicker,
  Select,
  TextInput,
} from '../../components/inputs';

import '../../styles.scss';

function PersonalInfo ({ pageTitle }) {

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <FormRow>
          <TextInput
            autoFocus
            label="First name"
            name="first_name"
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="Last Name"
            name="last_name"
          />
        </FormRow>
        <FormRow>
          <DatePicker
            label="Date of birth"
            id="birthdate"
            name="birthdate"
          />
        </FormRow>
        <FormRow>
          <Select
            label="Gender"
            id="gender"
            name="gender"
          >
            <option />
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Select>
        </FormRow>
      </div>
    </div>
  );  
}

export default PersonalInfo;
