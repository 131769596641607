import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';

// actions
import { updateEnrollment } from '../../actions/enrollment';

// components
import { Header, Sidebar, Footer, EnrollmentErrors } from './components/nav';
import { ResumeAlert, CustomMessage } from './components';
import { DisclaimerFooter } from '../../components';

// utils
import { getPageFromUrl } from '../../utils/enroll';

// pages
import Applicant from './pages/applicant';
import Coverage from './pages/coverage';
import Other from './pages/other';
import Review from './pages/review';
import Confirmation from './pages/confirmation';
import EnrollmentLoader from './EnrollmentLoader';
import ContinueEnrollment from './ContinueEnrollment';

let SAVE_TIMER = null;

function NavFrame ({ children, customMessage }) {
  const dispatch = useDispatch();
  const {
    customMaterialID,
    hideTPMODisclaimer,
    customLastUpdate,
  } = useSelector(store => store.enrollment);
  const { resumeEnrollmentAlert } = useSelector(store => store.theme);

  // scroll to top of page whenever you navigate to the next page
  const currentPath = window.location.pathname;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPath]);

  // load/save logic for user input
  const enrollment = useSelector(store => store.enrollment);
  const isLoading = useSelector(store => store.enrollment.isLoading);
  const evalues = useSelector(store => store.evalues);
  const { customDisclaimerLastUpdated = '' } = enrollment;

  // if user changes evalue, set a 1s timer to save
  // if they keep typing, that timer keeps being set to 1s countdown
  useEffect(() => {
    if (!isLoading) {
      clearInterval(SAVE_TIMER);
      SAVE_TIMER = setTimeout(() => {
        dispatch({ type: 'SET_ENROLLMENT_FIELD', payload: { isSaving: true } })
            dispatch({ type: 'SET_ENROLLMENT_FIELD', payload: { saveError: false } })
        updateEnrollment(enrollment, evalues)
          .then(() => dispatch({ type: 'SET_ENROLLMENT_FIELD', payload: { isSaving: false } }))
          .catch(err => {
            dispatch({ type: 'SET_ENROLLMENT_FIELD', payload: { isSaving: false } })
            dispatch({ type: 'SET_ENROLLMENT_FIELD', payload: { saveError: true } })
            console.log(err);
          });
      }, 2000);
    }
  }, [evalues, isLoading]);


  return (
    <div style={{ overflowX: 'hidden' }} className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row ps-4 row-cols-md-2">
        <div className="col-md-3">
          <Sidebar type="browser" />
        </div>
        <div className="col-md-6 mt-4">
          {resumeEnrollmentAlert && <ResumeAlert />}
          <CustomMessage text={customMessage} />
          {children}
        </div>
        <div className="col-md-4 offset-md-3">
          <Footer />
        </div>
        <div className="col-md-6 offset-md-3 footer-margin">
          <EnrollmentErrors className="mt-3" />
          <DisclaimerFooter
            className="mt-3"
            style={{ textAlign: 'left', minWidth: '50vw' }}
            customMaterialID={customMaterialID}
            customLastUpdate={customDisclaimerLastUpdated || customLastUpdate}
            showTPMODisclaimer={!hideTPMODisclaimer}
          />
        </div>
      </div>
    </div>
  );
}


const Enrollment = () => {
  const baseRoute = useSelector(store => store.enrollment.baseRoute);

  // get page title
  const location = useLocation();
  const form = useSelector(store => store.enrollment.form);
  const { title, customMessage } = getPageFromUrl(form, location.pathname) || {};

  return (
    <>
      <Routes>
        <Route
          path="/load"
          element={<EnrollmentLoader pageTitle="Loading" />}
        />
        <Route
          path="/continue"
          element={<ContinueEnrollment pageTitle="Continue" />}
        />
        <Route
          path={`${baseRoute}/applicant/*`}
          element={
            <NavFrame customMessage={customMessage}>
              <Applicant pageTitle={title} />
            </NavFrame>
           }
        />
        <Route
          path={`${baseRoute}/coverage/*`}
          element={
            <NavFrame customMessage={customMessage}>
              <Coverage pageTitle={title} />
            </NavFrame>
          }
        />
        <Route
          path={`${baseRoute}/other/*`}
          element={
            <NavFrame customMessage={customMessage}>
              <Other pageTitle={title} />
            </NavFrame>
          }
        />
        <Route
          path={`${baseRoute}/review/*`}
          element={
            <NavFrame customMessage={customMessage}>
              <Review pageTitle={title} />
            </NavFrame>
          }
        />
        <Route
          path={`${baseRoute}/confirmation`}
          element={<Confirmation pageTitle="Confirmation" />}
        />
      </Routes>
    </>
  );
}

export default Enrollment;
