import React from 'react';
import { useSelector } from 'react-redux';

import { FormRow, TitleRow } from '../../components/layout';
import { TextInput, YesNo } from '../../components/inputs';

import '../../styles.scss';

function PCP ({ pageTitle }) {
  const rowClasses = 'input-row';

  return (
    <div>
      <div className="row">
        <TitleRow>{pageTitle}</TitleRow>
        <FormRow>
          <YesNo
            label="Are you an established patient?"
            name="pcp_established"
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="Physician name"
            name="pcp_name"
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="Provider number"
            name="pcp_num"
          />
        </FormRow>
      </div>
    </div>
  );  
}

export default PCP;
