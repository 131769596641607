import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fieldExistsInConfig, handleChange, getFieldKey } from '../../../../utils/enroll';

import FormLabel from './FormLabel';

function Checkbox ({
  disabled,
  label,
  name,
}) {

  const dispatch = useDispatch();
  const form = useSelector(store => store.enrollment.form);
  const evalues = useSelector(store => store.evalues);
  const validationRegex = useSelector(store => store.enrollment.validation[name]);
  const { submittedFlag } = useSelector(store => store.enrollment);
  const customLabel = getFieldKey(form, name, 'label');

  /*
  useEffect(() => {
    if (defaultChecked === true) {
      handleChange({ value: true, name });
    }
  }, [defaultChecked]);
  */

  // if field doesn't exist, don't show it.
  if (!fieldExistsInConfig(form, name)) return null;

  return (
    <div className="form-check">
      <input
        type="checkbox"
        defaultChecked={evalues[name]}
        className="form-check-input"
        id={name}
        onChange={(e) => handleChange({ value: e.target.checked, name, validationRegex, forceValidation: true })}
        value={evalues[name] || ''}
        disabled={disabled || submittedFlag}
      />
      <FormLabel className="form-check-label" name={name} label={customLabel || label} />
    </div>
  );  
}

export default Checkbox;
