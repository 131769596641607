import React from 'react';
import { useSelector } from 'react-redux';

// components
import { YesNo, TextInput, TextBlock } from '../../components/inputs';
import { FormRow, TitleRow } from '../../components/layout';

function CSNP({ pageTitle }) {
  const { evalues: { snp_esrd: snpESRD } } = useSelector(store => store);

  return (
    <div className="row">
      <TitleRow>{pageTitle}</TitleRow>
      {/* Anthem isPlanTransfer question */}
      <FormRow showIfFieldExists="csnp_is_plan_transfer">
        <TextInput
          label="If the enrollee is switching plans, what is the name of the plan they are coming from?"
          name="csnp_is_plan_transfer"
        />

        <hr className="mt-4" />

      </FormRow>

      {/* Anthem CSNP Prequal question */}
      <FormRow showIfFieldExists="csnp_prequal">
        <YesNo
          label={`Have you ever been diagnosed with Congestive heart failure (CHF),
            Cardiovascular disease (CVD), Chronic lung disorder and /or Diabetes?`}
          name="csnp_prequal"
        />

        <hr className="mt-4" />

      </FormRow>

      {/* ESRD */}
      <FormRow
        showIfFieldsExist={[
          'snp_esrd',
          'snp_esrd_dialysis',
          'snp_esrd_transplant',
          'snp_esrd_medicines',
          'snp_esrd_dialysis_center',
          'snp_esrd_dialysis_tel',
          'snp_esrd_dialysis_id',
          'snp_esrd_nephrologist_name',
          'snp_esrd_nephrologist_tel',
        ]}
      >
        <YesNo
          label={'Have you ever been told that you have End Stage Renal Disease (ESRD)?'}
          name="snp_esrd"
          className="mb-3"
        />
        <YesNo
          label={'Are you currently undergoing dialysis (hemodialysis or peritoneal dialysis)?'}
          name="snp_esrd_dialysis"
          className="mb-3"
        />
        <YesNo
          label={'Are you currently awaiting a kidney transplant?'}
          name="snp_esrd_transplant"
          className="mb-3"
        />
        <TextInput
          label={'What medicines do you take for ESRD?'}
          name="snp_esrd_medicines"
          className="mb-3"
        />

        {!snpESRD && <hr className="mt-4" />}

      </FormRow>

      {/* anthem specific question */}
      {snpESRD && (
        <>
          <FormRow
            indent
            showIfFieldsExist={[
              'snp_esrd_dialysis_center',
              'snp_esrd_dialysis_tel',
              'snp_esrd_dialysis_id',
              'snp_esrd_nephrologist_name',
              'snp_esrd_nephrologist_tel',
            ]}
          >
            <TextInput
              label={'Dialysis center name'}
              name="snp_esrd_dialysis_center"
              className="mb-3"
            />
            <TextInput
              label={'Dialysis center phone number'}
              name="snp_esrd_dialysis_tel"
              className="mb-3"
            />
            <TextInput
              label={'Dialysis center ID'}
              name="snp_esrd_dialysis_id"
              className="mb-3"
            />
            <TextInput
              label={'Nephrologist name'}
              name="snp_esrd_nephrologist_name"
              className="mb-3"
            />
            <TextInput
              label={'Nephrologist phone number'}
              name="snp_esrd_nephrologist_tel"
              className="mb-3"
            />
          </FormRow>
          <hr className="mt-4" />
        </>
      )}

      {/* CVD */}
      <FormRow
        showIfFieldsExist={[
          'snp_cvd',
          'snp_cvd_circulation',
          'snp_cvd_pain',
          'snp_cvd_medicines',
          'snp_cvd_rx',
          'snp_cvd_defib_or_pacemaker',
          'snp_cvd_implants',
        ]}
      >
        <YesNo
          cols="12"
          label={`Do you have a problem with your heart, had a heart attack,
            or have you been told that you had a heart attack?`}
          name="snp_cvd"
          className="mb-3"
        />
        <YesNo
          cols="12"
          label={`Do you have a problem with your circulation or have you been
            told that you have problems with your circulation?`}
          name="snp_cvd_circulation"
          className="mb-3"
        />
        <YesNo
          cols="12"
          label={'Do you have pain in your legs when you walk that gets better when you stop and rest?'}
          name="snp_cvd_pain"
          className="mb-3"
        />

        <YesNo
          cols="12"
          label={'Have you ever been prescribed medications to thin your blood, including Warfarin or Clopidogrel?'}
          name="snp_cvd_rx"
          className="mb-3"
        />
        <YesNo
          cols="12"
          label={'Do you have a pacemaker or internal defibrillator?'}
          name="snp_cvd_defib_or_pacemaker"
          className="mb-3"
        />
        <YesNo
          cols="12"
          label={'Have you had an angioplasty, stents or bypass on your heart or legs?'}
          name="snp_cvd_implants"
          className="mb-3"
        />

        <TextInput
          label={'What medicines do you take for CVD?'}
          name="snp_cvd_medicines"
          className="mb-3"
        />

        <hr className="mt-4" />

      </FormRow>

      {/* CHF */}
      <FormRow
        showIfFieldsExist={[
          'snp_chf',
          'snp_chf_fluid',
          'snp_chf_swelling',
          'snp_chf_medicines',
        ]}
      >
        <YesNo
          label={'Have you ever been told you have heart failure or congestive heart failure?'}
          name="snp_chf"
          className="mb-3"
        />
        <YesNo
          label={'Have you ever been told you have fluid in your lungs?'}
          name="snp_chf_fluid"
          className="mb-3"
        />
        <YesNo
          label={'Have you ever been told you have swelling in your legs due to your heart?'}
          name="snp_chf_swelling"
          className="mb-3"
        />
        <YesNo
          label={'During the past 12 months, have you been counseled or educated about weighing yourself daily due to a heart problem?'}
          name="snp_chf_weight"
          className="mb-3"
        />
        <TextInput
          label={'What medicines do you take for CHF?'}
          name="snp_chf_medicines"
          className="mb-3"
        />

        <hr className="mt-4" />

      </FormRow>

      {/* CLD */}
      <FormRow
        showIfFieldsExist={[
          'snp_lung',
          'snp_lung_told',
          'snp_lung_inhaler',
          'snp_lung_medicines',
        ]}
      >
        <YesNo
          label={'Do you have any chronic breathing problems?'}
          name="snp_lung"
          className="mb-3"
        />
        <YesNo
          label={`Have you ever been told you have a lung problem such as COPD, emphysema, asthma,
            chronic bronchitis, scarring in the lung, or high pressure in the lungs?`}
          name="snp_lung_told"
          className="mb-3"
        />
        <YesNo
          label={'Do you use inhalers or other medicines for your breathing more than 3 times per week?'}
          name="snp_lung_inhaler"
          className="mb-3"
        />
        <TextInput
          label={'What medicines do you take for chronic lung disorder?'}
          name="snp_lung_medicines"
          className="mb-3"
        />

        <hr className="mt-4" />

      </FormRow>

      {/* Diabetes */}
      <FormRow
        showIfFieldsExist={[
          'snp_diabetes',
          'snp_diabetes_monitor',
          'snp_diabetes_insulin',
          'snp_diabetes_medicines',
        ]}
      >
        <YesNo
          label={'Have you ever been told that you have high blood sugar or diabetes?'}
          name="snp_diabetes"
          className="mb-3"
        />
        <YesNo
          label={'Have you ever or do you currently measure/monitor your blood sugar?'}
          name="snp_diabetes_monitor"
          className="mb-3"
        />
        <YesNo
          label={`Have you been prescribed or do you take insulin or an oral
            medication that's supposed to lower your blood sugar?`}
          name="snp_diabetes_insulin"
          className="mb-3"
        />
        <TextInput
          label={'What medicines do you take for diabetes?'}
          name="snp_diabetes_medicines"
          className="mb-3"
        />

        <hr className="mt-4" />

      </FormRow>

      {/* SNP Physician specific question */}
      <FormRow
        showIfFieldsExist={[
          'snp_physician_first_name',
          'snp_physician_last_name',
          'snp_physician_middle_name',
          'snp_physician_name',
          'snp_physician_tel',
          'snp_physician_fax',
          'snp_physician_street1',
          'snp_physician_city',
          'snp_physician_state',
          'snp_physician_zip',
        ]}
      >
        <TextBlock
          name="snp_physician_desc_block"
          label="What is the information for the physician that treats your chronic illness?"
        />
        <TextInput
          label="First name"
          name="snp_physician_first_name"
          className="mb-3"
          cols="6"
        />
        <TextInput
          label="Middle initial"
          name="snp_physician_middle_name"
          className="mb-3"
          cols="6"
        />
        <TextInput
          label="Last name"
          name="snp_physician_last_name"
          className="mb-3"
          cols="6"
        />
        <TextInput
          label="Name"
          name="snp_physician_name"
          className="mb-3"
          cols="6"
        />
        <TextInput
          label="Phone number"
          name="snp_physician_tel"
          className="mb-3"
          cols="6"
        />
        <TextInput
          label="Fax number"
          name="snp_physician_fax"
          className="mb-3"
          cols="6"
        />

        {/* address */}
        <TextInput
          label="Street"
          name="snp_physician_street1"
          className="mb-3"
          cols="6"
        />
        <TextInput
          label="City"
          name="snp_physician_city"
          className="mb-3"
          cols="6"
        />
        <TextInput
          label="State"
          name="snp_physician_state"
          className="mb-3"
          cols="6"
        />
        <TextInput
          label="Zipcode"
          name="snp_physician_zip"
          className="mb-3"
          cols="6"
        />
      </FormRow>
    </div>
  );
}

export default CSNP;
