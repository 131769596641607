import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// utils
import { getShopper } from '../../actions/shopper';
import { getSnapshotPlan, getPlanByCPS } from '../../actions/plan';
import { loadConfig } from '../../actions/config';

// Components
import Topbar from '../../components/Topbar';
import DisclaimerFooter from '../../components/DisclaimerFooter';
import QuoteCard from './QuoteCard';

// Design ticket: https://mymedicarebot.atlassian.net/browse/MYR-4422
function QuoteList(props) {
  const dispatch = useDispatch();

  const shopper = useSelector(state => state.shopper);
  const { snapshotPlans = {} } = useSelector(state => state.plan);

  // useEffect(() => {
  //   dispatch({ type: 'CLEAR_PLANS' });
  // });

  useEffect(() => {
    loadConfig({
      key: 'enroll_disabled_plans_json',
      access: 'public',
      useDataSite: true,
    }).then(config => {
      dispatch({ type: 'SET_DISABLED_PLANS', payload: config.data.value });
    });

    if (shopper.username && localStorage.getItem('SessionToken')) {
      // Always re-request shopper on QuoteList mount to receive new quotes
      getShopper(shopper.username)
        .then((newShopper) => {
          // Ensure quotes are sorted w/ newest at top         
          newShopper.quotes = newShopper.quotes.sort((q1, q2) => {
            if (q1.created > q2.created) {
              return -1;
            } else if (q1.created < q2.created) {
                return 1;
            } else {
              return 0;
            }
          });
          dispatch({ type: 'SET_SHOPPER', payload: newShopper });
        });
    }
  }, []);

  /* Each QuoteCard needs to show more information than is in a single Quote itself */
  useEffect(() => {
    if (shopper && shopper.quotes) {
      let snapshotIDs = Object.keys(snapshotPlans || {});
      let snapshotsToRequest = [...shopper.quotes];
      if (shopper.currentPlan) {
        const cpsID = shopper.currentPlan.replaceAll('-', '');
        const currentYear = (new Date()).getUTCFullYear();
        getPlanByCPS(cpsID, currentYear, shopper.county)
          .then((currentPlan) => {
            dispatch({ type: 'SET_CURRENT_PLAN', payload: currentPlan });
          })
          .catch(() => {
            dispatch({ type: 'SET_CURRENT_PLAN', payload: { planName: `Unable to load data for ${shopper.currentPlan}` } });
          })
      }
      snapshotsToRequest.forEach((q, idx) => {
        if (snapshotIDs.indexOf(q.quoteID) === -1) {
          // This is the data payload for each given /api/utils/plans/summary
          let snapshotData = {
            Plans: [{ PlanID: q.planID || q.planId }],
            AdditionalPlanDataReturn: [4],
            QuoteID: q.quoteID || q.quoteId,
            CountyFIPS: q.shopperCounty || shopper.county,
            Zipcode: q.shopperZip || shopper.zipcode,
            SubsidyLevel: q.shopperSubsidyLevel || shopper.subsidyLevel,
            SubsidyPercent: q.shopperSubsidyPercent || shopper.subsidyPercent,
            Pharmacies: [],
            Dosages: [],
            EffectiveDate: shopper.effectiveDate,
          };
          getSnapshotPlan(snapshotData)
            .then((snapshotPlan) => {
              dispatch({ type: 'ADD_SNAPSHOT_PLAN', payload: snapshotPlan });
            });
        }
      });
    }
  }, [shopper]);

  return (
    <>
      <Topbar showAgent />
      <div className='mmb-container show-agent bg-gray-200'>
        <div className='text-serif text-center fw-bold fs-1 m-3 mb-0'>
          <div>You have {shopper.quotes?.length > 0 ? shopper.quotes?.length : 'no'}</div>
          <div>Medicare quote{shopper.quotes?.length !== 1 ? 's' : ''}</div>
        </div>
        <div className='text-center m-3 d-flex flex-column' style={{ maxWidth: '600px' }}>
          <span>
            Click on the plan name to learn more about its coverage and benefits,
            or click the "Enroll Now" button to begin your online enrollment application.
          </span>
        </div>
        <div className='d-flex flex-column align-items-center w-100'>
          {shopper && shopper.quotes
             ? shopper.quotes.map(quote => <QuoteCard quote={quote} snapshotPlan={snapshotPlans[quote.quoteID]} key={quote.quoteID} />)
             : (
               <div className="text-muted fst-italic p-5 border-gray-400 border-top">
                 You may be logged into an <span className="font-monospace fs-5">agent</span> account.
               </div>
             )
           }
        </div>
        <div className="pt-3 pb-5">
          <DisclaimerFooter />
        </div>
      </div>
    </>
  );
}

export default QuoteList;
