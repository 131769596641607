export default ({ agent, quote }) => {
  // NOTE(santeyio): this 'merging' of carrier permissions logic stems from
  // the Amerilife RTS process where we auto import permissions
  // from carrier 'ready to sell' files. Because of inconsistent
  // data sometimes an agent has both an individual and a corporate
  // npn with a single carrier. Below is the logic used
  // to determine which 'carrier agent id' should be used to
  // submit the enrollment. See jira ticket MYR-4170 for detailed
  // discussion of the logic used here.
  //
  // Note that in the case of having multiple individual npns or multiple
  // corporate npns, the first one in the return array from the api will
  // be selected. This should never happen from a theoretical standpoint,
  // but we all know that the reality of data does not always live up
  // to it's telos.
  //
  // Note that if agent.npn_linked == falsey then we fall back on
  // the normal agent.npn and everything should work as you would expect
  // without the linked npn logic.

  const { carrierID } = quote;
  const eState = quote.state.toUpperCase();

  let agentID;
  let agentNPN;
  let agentEncryptedFEIN;
  let agencyID;
  let agencyName;

  // we need to check to see if the agent object is being passed
  // with snake case or camel case. Sigh.
  let permissions = [];
  let npnLinked = null;

  if (agent.npn_linked !== undefined) {
    npnLinked = agent.npn_linked;
  } else if (agent.npnLinked !== undefined) {
    npnLinked = agent.npnLinked;
  }

  if (agent.carrier_permissions !== undefined) {
    permissions = agent.carrier_permissions;
  } else if (agent.carrierPermissions !== undefined) {
    permissions = agent.carrierPermissions;
  }
  // -- end check of snake vs camel case

  let iNpn; // individual
  let cNpn; // corporate

  if (agent.npn_type === 'individual') {
    iNpn = agent.npn;
    cNpn = npnLinked;
  } else if (agent.npn_type === 'corporate') {
    iNpn = npnLinked;
    cNpn = agent.npn;
  } else {
    iNpn = agent.npn;
    cNpn = npnLinked;
  }

  const iPerm = permissions.find((p) => (
    p.carrier_id === carrierID
      && p.npn === iNpn
      && String(p.plan_year) === String(quote.planYear)
  )) || {};
  const cPerm = permissions.find((p) => (
    p.carrier_id === carrierID
      && p.npn === cNpn
      && String(p.plan_year) === String(quote.planYear)
  )) || {};

  const iStates = iPerm.states || [];
  const cStates = cPerm.states || [];
  const iHasState = iStates.includes(eState);
  const cHasState = cStates.includes(eState);

  if (iHasState && cHasState) {
    agentID = iPerm.carrier_agent_id;
    agentNPN = iPerm.npn;
    agentEncryptedFEIN = iPerm.encrypted_fein;

    // Parse out agency info based on the state.
    // At time of implementation (2023-04-18) this is only used for anthem.
    const agency = iPerm.agencies[eState];
    if (agency) {
      agencyID = agency.parent_agent_id;
      agencyName = agency.parent_name;
    }
  } else if (iHasState) {
    agentID = iPerm.carrier_agent_id;
    agentNPN = iPerm.npn;
    agentEncryptedFEIN = iPerm.encrypted_fein;
    const agency = iPerm.agencies[eState];

    // Parse out agency info based on the state.
    // At time of implementation (2023-04-18) this is only used for anthem.
    if (agency) {
      agencyID = agency.parent_agent_id;
      agencyName = agency.parent_name;
    }
  } else if (cHasState) {
    agentID = cPerm.carrier_agent_id;
    agentNPN = cPerm.npn;
    agentEncryptedFEIN = cPerm.encrypted_fein;

    // Parse out agency info based on the state.
    // At time of implementation (2023-04-18) this is only used for anthem.
    const agency = cPerm.agencies[eState];
    if (agency) {
      agencyID = agency.parent_agent_id;
      agencyName = agency.parent_name;
    }
  }

  return {
    agentID,
    agentNPN,
    agentEncryptedFEIN,
    agencyID,
    agencyName,
  };
};
