import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const OptOutLink = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Shopper will receive URL in SMS/email with the shape:
  // https://...../notify/email/unsubscribe
  //   ?email={shopper.username}
  //   ?signature={shopper.signature}
  const [ searchParams ] = useSearchParams();

  useEffect(() => {
    const email = searchParams.get('email');
    const signature = searchParams.get('signature');

    dispatch({ type: 'SET_SHOPPER_FIELD', payload: { email } });
    dispatch({ type: 'SET_SHOPPER_FIELD', payload: { signature } });
    
    navigate('/unsubscribe/confirm');
  });
  return null;
}

export default OptOutLink;
