import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Actions
import { refreshSession, validateSession } from '../actions/session';

export default ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { lifespan, created, sessionRefreshModal } = useSelector(store => store.session);
  const { activeUserRefreshTokenMinutes = 10 } = useSelector(store => store.theme);

  const [ visible, setVisible ] = useState(false);
  const [ expired, setExpired ] = useState(false);
  const [ countdown, setCountdown ] = useState(0);

  useEffect(() => {
    let timeoutTmp;
    if (created && lifespan) {
      const msSinceCreated = new Date() - new Date(created);
      const msRemaining = (lifespan * 1000) - msSinceCreated;
      const showModalInMs = msRemaining - (activeUserRefreshTokenMinutes * 60 * 1000);

      const readableHours = Math.round((showModalInMs * 10) / (1000 * 60 * 60))/10.0;
      console.info(`[Session] Token expiring in ${readableHours} hours`);

      timeoutTmp = setTimeout(() => {
        setCountdown(activeUserRefreshTokenMinutes * 60);
        setVisible(true);
      }, showModalInMs);
      return () => {
        clearTimeout(timeoutTmp);
      }
    }
  }, [ created, lifespan ]);

  useEffect(() => {
    if (visible && !expired) {
      let countdownInterval;
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 0) {
            // Push user to login so they cannot escape from modal and continue app use
            navigate('login');
            dispatch({ type: 'CLEAR_SESSION' });
            localStorage.removeItem('SessionToken');
            clearInterval(countdownInterval);
            setExpired(true);
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => {
        clearInterval(countdownInterval);
      }
    }
  }, [ visible, countdown ]);

  const ActiveUserPrompt = (
    <>
      <div className="fs-3 fw-bold">Your session is about to expire.</div>
      <div>For security reasons, you will be logged out of your account at the end of the timer.</div>
      <div className="fs-3 fw-bold m-2">
        {`${Math.floor(countdown/60)}`.padStart(2, '0')}
        :{`${countdown % 60}`.padStart(2, '0')}
      </div>
      <button
        className="btn btn-outline-primary fw-bold mt-2 ps-2 pe-2"
        tabIndex={0}
        type="button"
        onClick={() => {
          refreshSession()
           .then(() => {
             setVisible(false);
           })
        }}
      >
        Continue session
      </button>
    </>    
  );

  const ExpiredSessionPrompt = (
    <>
      <div className="fs-3 fw-bold">Your session has expired.</div>
      <div>Please log in to your account to continue.</div>
      <button
        className="btn btn-primary fw-bold mt-4 ps-5 pe-5"
        tabIndex={0}
        type="button"
        onClick={() => {
          setVisible(false);
          navigate('/login');
        }}
      >
        Log in
      </button>
    </>
  );

  return visible ? (
    <div
      className="d-flex position-absolute h-100 w-100 user-select-none justify-content-center"
      style={{ top: 0, zIndex: 1000, background: 'rgba(0, 0, 0, 0.7)' }}
    >
      <div
        className="d-flex flex-column bg-white p-5 m-auto justify-content-center align-items-center text-center"
        style={{
          width: '450px',
          height: '300px'
        }}
      >
        {created ? ActiveUserPrompt : ExpiredSessionPrompt}
        </div>
    </div>
  ) : null;
}
