import { combineReducers } from 'redux';
import agent from './agent';
import theme from './theme';
import session from './session';
import shopper from './shopper';
import plan from './plan';
import contact from './contact';
import rec from './rec';
import enrollment from './enrollment';
import evalid from './evalid';
import evalues from './evalues';
import alerts from './alerts';
import modals from './modals';
import display from './display';
import esign from './esign';

export default () => combineReducers({
  agent,
  alerts,
  contact,
  display,
  enrollment,
  esign,
  evalid,
  evalues,
  plan,
  session,
  shopper,
  theme,
  rec,
  modals,
});
