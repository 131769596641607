import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// Util
import { prettifyPartialPhone } from '../common/utils/prettify';

// * https://mymedicarebot.atlassian.net/browse/MYR-4413
function TopbarAgent() {
  const navigate = useNavigate();

  const agent = useSelector((state) => state.agent);

  // note(joeysapp): To call an agent, we will use the tel: uri scheme.
  //                 We can apprend 'phone-context=' for the following effects
  // https://stackoverflow.com/questions/1608548/
  // * tel:7042;phone-context=example.com
  //   The URI describes a local phone number valid within the context "example.com".
  // * tel:863-1234;phone-context=+1-914-555
  //   The URI describes a local phone number that is valid within a particular phone prefix.

  // Render logic
  let contactUrl;
  let contactIcon = 'phone';
  if (agent.phone) {
    contactUrl = `tel:${agent.phone}`;
  } else if (agent.email) {
    contactUrl = `mailto:${agent.email}`;
    contactIcon = 'envelope';
  } else {
    // we will use navigate to remain in app
    contactUrl = '/contact';
  }

  return (
    <div
      className='d-flex border border-primaryFocus bg-primaryFocusHover shadow-sm align-items-center'
      style={{ height: 'var(--navbarAgentHeight)' }}
    >
      <a
        className='d-flex m-3 rounded bg-primaryFocus shadow-sm align-items-center justify-content-center'
        style= {{ minHeight: '50px', minWidth: '50px', cursor: 'pointer' }}
        href={contactUrl === '/contact' ? null : contactUrl}
        onClick={() => navigate('/contact')}
      >
        <FontAwesomeIcon icon={contactIcon === 'phone' ? solid('phone') : solid('envelope')} className='text-white fs-1' />
      </a>
      <div className='d-flex flex-column fs-6'>
        <div className='d-flex flex-column ps-0 fs-5' style={{ borderRadius: '4px' }}>
        {
          agent && agent.firstName
            ? (
              <>
                <span className='fw-bold text-capitalize'>
                  {agent.firstName || '--'} {agent.lastName || '--'}
                </span>
                {agent.agency ? <span>{agent.agency}</span> : null}
                {agent.phone
                      ? (
                        <a
                          href={`tel:${agent.phone}`}
                          className='text-blue'
                          >
                          {prettifyPartialPhone(agent.phone)}
                        </a>
                      ) : null
                }
                {agent.email
                      ? (
                        <a
                          href={`mailto:${agent.email}`}
                          className='text-blue'
                          >
                          {agent.email}
                        </a>
                      ) : null
                }
              </>
            )
              : (
               <div
                 className="me-2 text-black p-2"
                 style={{ textDecoration: 'none', cursor: 'pointer' }}
                 onClick={() => navigate('/contact')}
               >
                 <span className="fw-bold me-1">Need help?</span>
                 <span>Click here to talk with a licensed medicare agent.</span>
               </div>
              )
        }
        </div>
      </div>
    </div>
  );

}

export default TopbarAgent;
